/**
 *  group resource sales List Page
 *
 *  @author krishna dobariya <krishna.webappmate@gmail.com>
 * created on: 31-10-2023
 */
import { useState, useEffect } from "react";
import "../BookaSession.WebAppMate/BookaSession.css";
import { makePostRequest, imageUrl } from "../../services/api";
import { URLS } from "../../constants";
import {
    toastifyMessage,
    forceDownload,
} from "../../utilities/CustomFunctions";
import moment from "moment";
import img1 from "../../assets/Images/sr-img1.png";
import ph22 from "../../assets/Images/ph22.png";
import srimg4 from "../../assets/Images/sr-img4.png";
import srimg3 from "../../assets/Images/sr-img3.png";
import vector3 from "../../assets/Images/vector3.png";
import srimg5 from "../../assets/Images/sr-img5.png";
import ph5 from "../../assets/Images/ph5.png";
import { useNavigate } from "react-router-dom";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import DatePicker from "react-datepicker";
import { Multiselect } from "multiselect-react-dropdown";
function ResourceSale(props) {
    let uimage = imageUrl;
    let useractive = localStorage.getItem("useractive");
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [saleHistoryData, setSaleHistoryData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(0);
    //const [salesType, setSalesType] = useState([{value:"group", name:"Group"}]);
    const [salesType, setSalesType] = useState("group");
    const [endDate, setEndDate] = useState("");
    const [startDate, setStartDate] = useState("")

    //api call to get all sale resources history list
    const GroupOptions = [
        { value: "group", name: "Group" },
        { value: "individual", name: "Individual" },
        { value: "other", name: "Other" },

    ];
    const handlegroup = (selectedList) => {

        selectedList?.map((val, i) => {
            setSalesType(val.value);
        });
    };

    const getSaleHistory = () => {
        setLoading(true);
        let data = {
            "group_id": props?.id,
            "page_number": currentPage,
            "resource_type_status": salesType,
            "end_date": endDate && moment(endDate).format("YYYY-MM-DD") || "",
            "start_date": startDate && moment(startDate).format("YYYY-MM-DD") || ""
        }
        makePostRequest(URLS.groupResourcesPurchase, null, data, null)
            .then((res) => {
                console.log(res, "response of sale");
                if (res.code === 200) {
                    setLoading(false);
                    if (currentPage === 1) {
                        setSaleHistoryData(res?.data?.groupResourcePurchase
                        );
                        setHasMore(res?.data?.remaining_count);
                    } else {
                        setSaleHistoryData([...saleHistoryData, ...res?.data?.groupResourcePurchase
                        ]);
                        setHasMore(res?.data?.remaining_count);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        if (useractive === "") {
            navigate("/sign-in");
        }
    }, []);

    useEffect(() => {
        getSaleHistory();
    }, [currentPage, salesType, startDate, endDate]);

    //download invoice
    const downloadReceipt = (invoicePdf) => {
        if (invoicePdf) {
            let pdfFileName = invoicePdf.split("/");
            forceDownload(uimage + invoicePdf, pdfFileName[3]);
        } else {
            toastifyMessage("No invoice available for download", "error");
        }
    };

    useEffect(() => {
        document.body.classList.add("servepaymenthistory");
        return () => {
            document.body.classList.remove("servepaymenthistory");
        };
    }, []);

    //redirect to resource  detail page
    const redirectToDetail = (resourceid) => {
        navigate("/full-description/" + resourceid);
    };



    return (
        <>
            {loading && (
                <div className="sharepopupOuter backgroundOpacity1">
                    <FourDotsLoader />
                </div>
            )}

            <div className="col-lg-12">
                <div className="group-sale mt-4" >
                    <div className="float-start">
                        <h1>Sales History </h1>
                    </div>
                    <div className="float-start">
                        {/* <select className="select-exp-div" value={salesType} onChange={(e) => { setCurrentPage(1); setSalesType(e.target.value) }}>
                            <option value="group">Group</option>
                            <option value="individual">Individual</option>
                            <option value="other">Other</option>
                        </select> */}
                        <Multiselect className="form-select w-100 float-start saveResourceSelectss"
                            options={GroupOptions}
                            onSelect={handlegroup}
                            // singleSelect
                            showArrow
                            displayValue="name"
                            showCheckbox
                            selectedValues={salesType ? [GroupOptions.find(item => item.value === salesType)] : []}
                        />

                        <div className="select-exp-div">
                            <DatePicker
                                className="border-0 w-100 searchDate"
                                id="date"
                                selected={startDate}
                                onChange={(date) => { setCurrentPage(1); setStartDate(date) }}
                                placeholderText="Start Date"
                                dateFormat="MM-dd-yyyy"
                            />
                        </div>
                        <div className="select-exp-div">
                            <DatePicker
                                className="border-0 w-100 searchDate"
                                id="date"
                                selected={endDate}
                                onChange={(date) => { setCurrentPage(1); setEndDate(date) }}
                                placeholderText="End Date"
                                dateFormat="MM-dd-yyyy"
                            />
                        </div>
                    </div>
                </div>

                <div className="bookmoniterdivbox">
                    <ul>
                        {saleHistoryData?.length > 0 &&
                            saleHistoryData.map((val, i) => {
                                return (
                                    <li key={i + 1}>
                                        <div className="sprash-name">
                                            <div
                                                className="sprash-name-lt cursor-pointer"
                                                onClick={() => {
                                                    redirectToDetail(val?.resource_id);
                                                }}
                                            >
                                                {val?.tbl_user_resource?.resource_type === 1 && (
                                                    <div className="img-divsr1">
                                                        <img className="img-fluid" src={img1} alt="Organization image" loading="lazy" width="" height="" />
                                                    </div>
                                                )}
                                                {val?.tbl_user_resource?.resource_type === 3 && (
                                                    <div className="img-divsr2">
                                                        <img className="img-fluid" src={ph22} alt="Experiences image" loading="lazy" />
                                                    </div>
                                                )}
                                                {val?.tbl_user_resource?.resource_type === 2 && (
                                                    <div className="img-divsr3">
                                                        <img className="img-fluid" src={srimg4} alt="Curriculum Content image" loading="lazy" />
                                                    </div>
                                                )}
                                                {val?.tbl_user_resource?.resource_type === 4 && (
                                                    <div className="img-divsr4">
                                                        <img className="img-fluid" src={srimg3} alt="Student Support image" loading="lazy" />
                                                    </div>
                                                )}
                                                {val?.tbl_user_resource?.resource_type === 5 && (
                                                    <div className="img-divsr5">
                                                        <img className="img-fluid" src={vector3} alt="Teacher Parent Support" loading="lazy" />
                                                    </div>
                                                )}
                                                {val?.tbl_user_resource?.resource_type === 6 && (
                                                    <div className="img-divsr6">
                                                        <img className="img-fluid" src={srimg5} alt="funding image" loading="lazy" />
                                                    </div>
                                                )}
                                                {val?.tbl_user_resource?.resource_type === 7 && (
                                                    <div className="img-divsr7">
                                                        <img className="img-fluid" src={ph5} alt="facility image" loading="lazy" />
                                                    </div>
                                                )}
                                            </div>
                                            <div className="sprash-name-rts">
                                                <h3>{val?.tbl_user_resource?.resource_title} </h3>
                                            </div>
                                        </div>
                                        <div className="sprash-name-rt">
                                            <div className="sprash-name-box">
                                                <h4>Resource Owner</h4>
                                                <p className="text-capitalize"> {val?.tbl_user_resource?.tbl_user?.firstName + " " + val?.tbl_user_resource?.tbl_user?.lastName} </p>
                                            </div>
                                            <div className="sprash-name-box">
                                                <h4> Purchase Date</h4>
                                                <p>
                                                    {" "}
                                                    {moment(val?.created_at).format("MM-DD-YYYY")}{" "}
                                                </p>
                                            </div>

                                            <div className="sprash-name-box">
                                                <h4> Amount</h4>
                                                <p> ${Number(val?.total_amount).toFixed(2)}</p>
                                            </div>
                                            <div className="sprash-name-box">
                                                {
                                                    salesType === "group" && <button
                                                        className="invoicebtn"
                                                        onClick={() => {
                                                            downloadReceipt(
                                                                val?.tbl_payment_history?.invoice_pdf_link
                                                            );
                                                        }}
                                                    >
                                                        Invoice
                                                    </button>
                                                }

                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                        {saleHistoryData?.length === 0 && (
                            <>
                                <li>Currently no sales history.</li>
                            </>
                        )}
                    </ul>
                    {hasMore > 0 && (
                        <div className="text-center w-100">
                            <button
                                className="border-0 btn-md btn"
                                onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
                            >
                                Load More
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
export default ResourceSale;
