import React from "react";
import { Helmet } from "react-helmet";

const Head = ({ ...props }) => {
  return (
    <Helmet>
      <title>{props.title ? `${props.title} | ` : null} MatchED</title>
      <meta property="og:title"   content={props.content || ""} />
    </Helmet>
  );
};
export default Head;
