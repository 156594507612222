/**
 * group Resources List Page
 *
 *  @author krishna dobariya <krishna.webappmate@gmail.com>
 * created on: 31-10-2023
 */

import { useState, useEffect } from "react";
import "../ServeResources.WebAppMate//serve_yourresources.css";
import Cardcomponent1 from "../ServeResources.WebAppMate/Cardcomponent1";
import { makePostRequest } from "../../services/api";
import { URLS } from "../../constants";
import { useNavigate } from "react-router-dom";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";

function ResourceList(props) {

    let useractive = localStorage.getItem("useractive");
    let navigate = useNavigate();
    const [resourcesData, setResourcesData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deleteApiRes, setDeleteApiRes] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(0);
    const [itemPerPage, setItemPerPage] = useState("9");

    useEffect(() => {
        if (useractive === "") {
            navigate("/sign-in");
        }
        document.body.classList.add("serveyour-resource");
        return () => {
            document.body.classList.remove("serveyour-resource");
        };
    }, [navigate, useractive]);
    
    //api to get all serve resources data with filter and pagination
    const getAllResources = () => {
        setLoading(true);
        let data = {
            "group_id": props?.id,
            "page_number": currentPage
        };

        makePostRequest(URLS.getAllResourcegroup, null, data, null)
            .then((res) => {
                if (res.code === 200) {
                    setLoading(false);
                    const share = res?.data?.share?.share?.map(val => val.resource_id) || [];
                    const updatedData = res?.data?.data.map(item => ({
                        ...item,
                        share: share.includes(item.id) ? "yes" : "no"
                    }));
                    if (currentPage === 1) {
                        setResourcesData(updatedData);
                    } else {
                        setResourcesData(prevData => [...prevData, ...updatedData]);
                    }
                    setHasMore(res?.data?.remaining_count);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error("Error fetching resources:", error);
                setLoading(false);
            });
    };

    useEffect(() => {
        getAllResources();
        // eslint-disable-next-line
    }, [currentPage]);

    useEffect(() => {
        let count = 1;
        if (count === 1) {
            setCurrentPage(1);
            getAllResources();

            count++;
        }
        // eslint-disable-next-line
    }, [itemPerPage]);

    useEffect(() => {
        setCurrentPage(1);
        getAllResources();
        // eslint-disable-next-line
    }, [deleteApiRes]);

    //increase current page +1 every click on show more results
    const handleLoadMore = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    return (
        <>
           
                <div className="serveResourceInner">
                    <div className="main-card-container">
                        {loading ? (
                            <ul>
                                <li>
                                    <div className="sharepopupOuter backgroundOpacity1">
                                        <FourDotsLoader />
                                    </div>
                                </li>
                            </ul>
                        ) : (
                            <Cardcomponent1
                                resources={resourcesData}
                                setDeleteApiRes={setDeleteApiRes}
                                setItemPerPage={setItemPerPage}
                                setCurrentPage={setCurrentPage}
                                itemPerPage={itemPerPage}
                                group={true}
                                group_id={props?.id}
                                getresources={getAllResources}
                            />
                        )}
                        {hasMore > 0 && (
                            <div className="show-more">
                                <button
                                    className="border-0 btn-md btn"
                                    onClick={handleLoadMore}
                                >
                                    Show more results{" "}
                                    <span>
                                        <i
                                            className="fa fa-angle-down downicon"
                                            aria-hidden="true"
                                        ></i>
                                    </span>
                                </button>
                            </div>
                        )}
                    </div>
              
            </div>
        </>
    );
}
export default ResourceList;
