
import React, { useState } from "react";
import "./SeeAllReview.css";
import './Yourgroup.css';
import { URLS } from "../../constants";
import { useEffect } from "react";
import { faStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
import { makePostRequest } from "../../services/api";
import { toastifyMessage } from "../../utilities/CustomFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const imageUrl = process.env.REACT_APP_IMAGE_URL

function SeeAllReview(props) {

    const [totalReview, setTotalReview] = useState("");

    // this api used for getting all reviews
    useEffect(() => {
        let data = {
            "group_id": props.value,
            "page": 1
        };
        makePostRequest(URLS.getReview, null, data)
            .then((res) => {
                console.log("res", res);
                if (res.code === 200) {
                    console.log("RESSS", res);
                    setTotalReview(res.data.reviewDetails);
                } else if (res.code === 400) {
                    toastifyMessage(res.message, "error");
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])
    let star = [0.5, 1.5, 2.5, 3.5, 4.5];

    return (
        <>
            <div className="SeereviewOuterdiv">
                <div className="seeReviewinnerdiv">
                    <h1 className="text-center">See All Review</h1>
                    <hr></hr>
                    <span className="cls_btn" onClick={props.datar}> X </span>
                    <div className="seeReviewinnerdiv2">
                        {
                            totalReview?.length > 0 ?
                                totalReview.map((val, i) => {
                                    return (
                                        <>
                                            <div className="rating float-start maindiv py-1">
                                                <div>
                                                    <img className="img-fluidd" src={val?.tbl_user?.photo
                                                        ? imageUrl +
                                                        val?.tbl_user?.photo
                                                        : ""} alt="start" loading="lazy"></img><br />

                                                </div>
                                                <div style={{ marginLeft: "15px" }}>
                                                    <h2>
                                                        {val?.tbl_user?.firstName +
                                                            " " +
                                                            val?.tbl_user?.lastName}
                                                    </h2>
                                                    <span className="starRatingNumber">
                                                        {Number(val.rating).toFixed(1)}
                                                    </span>
                                                    {star.map((temp, indx) => (
                                                        <>
                                                            {temp == val.rating ? (

                                                                <div className="star float-start" key={indx + 1}>

                                                                    <FontAwesomeIcon icon={faStarHalfAlt} />
                                                                </div>
                                                            ) : temp < val.rating ? (
                                                                <div className="star" key={indx + 1}>
                                                                    <FontAwesomeIcon icon={faStar} />
                                                                </div>
                                                            ) : (
                                                                <div className="star unfilled" key={indx + 1}>
                                                                    <FontAwesomeIcon icon={faStar} />
                                                                </div>
                                                            )}

                                                        </>

                                                    ))}
                                                    <div><p> {val.message}</p></div>
                                                </div>
                                            </div>
                                        </>
                                    );
                                }) : <div><h3 className="d-flex justify-content-center">No Data Found</h3></div>}
                    </div>
                </div>
            </div>

        </>
    );
}
export default SeeAllReview;
