//import React from "react";
import React from "react";
import { useState} from "react";
import InviteFriendpopup from "../Common.WebAppMate/InviteFriendpopup";
import { toastifyMessage } from "../../utilities/CustomFunctions";
import { makePostRequestForAll } from "../../services/api";
import { URLS } from "../../constants";
function Childsprofilepopup(props) {
    const [RoportPopup, setReportPopup] = useState(false);

     // this function is use for invite friends
  const InviteFriend = () => {
    const data = {
      "friend_id": props?.friendId
    }
    makePostRequestForAll(URLS.friendInvite, data)
      .then((res) => {
        if (res.code === 201) {
          toastifyMessage(res.message, "success");
          props?.funCall();
        } else {
          toastifyMessage(res.message, "warn");
        }
      })
    }
return (<>
       <div className="child-profile-popupBg">
            <div className="child-profile-popup">
                 <h3> Request to be friends to view saved resources </h3>
                 {props?.pending !== "pending" ? 
                  <button onClick={InviteFriend
                    // () => setReportPopup(!RoportPopup)
                  }>Invite as a Friend</button> :  ""
}
               
                  {/* <button onClick={() => setReportPopup(!RoportPopup)}>Invite as a friend</button> */}
                </div>
                 
                  
                {/* {RoportPopup == true ? (
                  <InviteFriendpopup datar={() => setReportPopup(!RoportPopup)} />
                ) : null} */}
        </div>
    </>)
}
export default Childsprofilepopup;