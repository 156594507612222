import Strengthicon from "../../assets/Images/Strengthicon.png";
import Mindicon from "../../assets/Images/Mindicon.png";
import Hearticon from "../../assets/Images/Hearticon.png";
import Sounicon from "../../assets/Images/Sounicon.png";
import image2 from "../../assets/Images/image2.png";
import srimg5 from "../../assets/Images/sr-img5.png";
import vectorr from "../../assets/Images/vectorr.png";
import vector4 from "../../assets/Images/vector4.png"
import ph22 from "../../assets/Images/ph22.png";
import vic1 from "../../assets/Images/vic1.png";
import vector3 from "../../assets/Images/vector3.png";
import { URLS } from "../../constants";
import { decodeToken, toastifyMessage } from "../../utilities/CustomFunctions";
import { makePostRequest, makePostRequestForAll, imageUrl } from "../../services/api";
import { useEffect, useState } from "react";
import ShareRecoursePopUp from "../Common.WebAppMate/ShareRecoursePopUp";
import CommentsPopup from "./CommentsPopup";
import { openRouteInNewTab } from "../Search.WebAppMate/RenderCardInfo";
import CardMenuButton from "../Search.WebAppMate/CardButtonMenu"
import Reportpopup from "../Search.WebAppMate/Reportpopup";
import Saveresourcespopup from "../Search.WebAppMate/Saveresourcespopup";
import { renderRatingStars } from "../Search.WebAppMate/RenderCardInfo";
import "../Search.WebAppMate/rating.css";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import DeleteIcon from "@mui/icons-material/Delete";
import unpin from "../../assets/Images/unpin.png"
import moment from "moment";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import EditIcon from '@mui/icons-material/Edit';
import { renderDescription, toSeoUrl } from "../../services/common.services"
import { useNavigate } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "16px",
  boxShadow: 24,
  p: 6,
};



const ResourceCard = (props) => {
  const naviget = useNavigate()
  let user_id = decodeToken().userAuth.id;
  const [isToggled, setIsToggled] = useState(props?.value?.announcement);
  const [myComment, setMyComment] = useState("");
  const [likeFlag, setLikeFlag] = useState(false);
  const [commentsFlag, setCommentsFlag] = useState(false);
  const [comments, setComments] = useState([]);
  const [spin, setSpin] = useState(false);
  const [commentsCount, setCommentsCount] = useState(0);
  const [announcement, setAnnouncement] = useState(false);
  const [reportPopupStatus, setReportPopupStatus] = useState({
    visible: false,
    objectID: null,
  });
  const [savePopupStatus, setSavePopupStatus] = useState({
    visible: false,
    selectedResource: null,
  });

  //start for popup
  const [sharePopupStatus, setSharePopupStatus] = useState({
    visible: false,
    objectID: null,
  });
  const [CommentsPopupStatus, setCommentsPopupStatus] = useState({
    visible: false,
    objectID: null,
  });
  const handleResourceShareOpen = () => {
    setSharePopupStatus({
      visible: true,
      objectID: props?.value?.resource_id,
    });
  };
  const handleResourceCommentsOpen = () => {
    setCommentsPopupStatus({
      visible: true,
      selectedResource: { objectID: props?.value?.resource_id },
    });
  }

  // end popup function-------

  useEffect(() => {
    props?.value?.resource_id && seeMoreComment(props?.value?.resource_id)
  }, [props?.value?.resource_id]);

  // this function is use for like resource
  const likeResource = (id) => {
    setSpin(true)
    const data = {
      resource_id: id,
    }
    makePostRequestForAll(URLS.sharedResourcelike, data,).then(res => {
      if (res.code === 201) {
        setLikeFlag(true)
      }
      setSpin(false)
      toastifyMessage(res?.message, "success")

    }).catch(e => {
      console.log(e)
    })
  }

  // this function is use for craete comments
  const commentResource = (e, id) => {
    e.preventDefault()
    if (myComment) {
      setSpin(true)
      const data = {
        resource_id: id,
        comment: myComment
      }
      makePostRequestForAll(URLS.createResourceComment, data).then(res => {
        setSpin(false)
        toastifyMessage(res?.message, "success")
        setMyComment("")
        seeMoreComment(props?.value?.resource_id)
        if (res.code === 201) {
          setCommentsFlag(true)
        }

      }).catch(e => {
        console.log(e)
      })
    } else {
      toastifyMessage("Please enter comments", "warn")
    }

  }
  // this function is use for show a comments
  const seeMoreComment = (id) => {
    const data = {
      resource_id: id,
    }
    makePostRequestForAll(URLS.getSharedResourceComment, data).then(res => {
      setCommentsCount(res?.data?.length)
      setComments(res?.data?.slice(-3));
    }).catch(e => {
      console.log(e)
    })
  }

  // this function is use for delete comments
  const deleteComments = (id) => {
    makePostRequest(URLS.deleteCommets, null, { "id": id }, null)
      .then((res) => {
        toastifyMessage(res.message, "success")
        seeMoreComment(props?.value?.resource_id)
      }).catch((error) => {
        console.log("error::", error)
      })
  }


  const handleToggle = (id) => {
    setIsToggled(!isToggled)
    setSpin(true)
    const data = {
      "resource_id": id,
      "group_id": props?.groupId
    }
    makePostRequest(URLS.announcementStatus, null, data, null)
      .then((res) => {
        setSpin(false)
        props.funcall();
        setIsToggled(true)
        setAnnouncement(false);
        toastifyMessage(res.message, "success")
      }).catch((error) => {
        setSpin(false)
        setAnnouncement(false);
      })

  };


  return (
    <>


      {reportPopupStatus.objectID && reportPopupStatus.visible && (
        <Reportpopup
          data={reportPopupStatus}
          handleResourceReportOpen={(data) =>
            setReportPopupStatus({ visible: true, objectID: data })
          }
          handleResourceReportClose={() =>
            setReportPopupStatus({ visible: false, objectID: null })
          }
        />
      )}
      {savePopupStatus.selectedResource && savePopupStatus.visible && (
        <Saveresourcespopup
          data={savePopupStatus}
          handleResourceSaveOpen={(data) =>
            setSavePopupStatus({ visible: true, selectedResource: data })
          }
          handleResourceSaveClose={() =>
            setSavePopupStatus({ visible: false, selectedResource: null })
          }
        />
      )}
      {
        sharePopupStatus.objectID && sharePopupStatus.visible && (
          <ShareRecoursePopUp
            data={sharePopupStatus}
            handleResourceReportOpen={handleResourceShareOpen}
            handleResourceShareClose={() =>
              setSharePopupStatus({ visible: false, selectedResource: null })
            }
          />
        )
      }
      {
        CommentsPopupStatus.selectedResource && CommentsPopupStatus.visible && (
          <CommentsPopup
            handleCommets={props?.value?.resource_id}
            data={CommentsPopupStatus}
            handleResourceCommentsOpen={handleResourceCommentsOpen}
            handleResourceCommentsClose={() =>
              setCommentsPopupStatus({ visible: false, selectedResource: null })
            }
          />
        )
      }
      {spin && (
        <div className="sharepopupOuter backgroundOpacity1">
          <FourDotsLoader />
        </div>
      )}
      <div className="inspiration-div">
        <div className="inspiration-inner-div">
          <div className="d-flex gap-3 inspirationwid">
            <img className="img-fluid" src={props?.value?.resource_shared_by_user?.photo} alt="user" />
            <h3 className="text_capital"><strong className="friendInspirationcapital">{props?.value?.resource_shared_by_user?.name}</strong> shared
              <>{props?.value?.resource_type === 7 && ` a ${props?.value?.resource_type_name}:`}</>
              <>{props?.value?.resource_type === 6 && ` a ${props?.value?.resource_type_name}:`}</>
              <>{props?.value?.resource_type === 3 && ` an ${props?.value?.resource_type_name}:`}</>
              <>{props?.value?.resource_type === 4 && ` a ${props?.value?.resource_type_name}:`}</>
              <>{props?.value?.resource_type === 5 && ` a ${props?.value?.resource_type_name}:`}</>
              <>{props?.value?.resource_type === 2 && ` a ${props?.value?.resource_type_name}:`}</>
              <>{props?.value?.resource_type === 1 && ` an ${props?.value?.resource_type_name}:`}</>
              <br />
              {/* <span>Jinie Doe and 86 others recommended this experience</span> */}
            </h3>
            <label></label>
            {
              props?.value?.announcement && <div className="unpin-div cursor-pointer" onClick={() => setAnnouncement(true)}>
                <img src={unpin} alt="unpin" />
              </div>
            }
            {
              props?.status === "group" && <>
                {props?.admin === "Admin" && props?.value?.group_resource_id === props?.group_id && <EditIcon className="groupresourceedit cursor-pointer" onClick={() => naviget(`/serve/${props?.value?.resource_id}`, {
                  state: {
                    group_id: props?.group_id
                  }
                })} />}</>
            }
          </div>
          <div className="inspirationthreedotsOuterdiv">
            <CardMenuButton
              data={props?.value}
              handleResourceReportOpen={() =>
                setReportPopupStatus({
                  visible: true,
                  objectID: props?.value?.resource_id,
                })
              }
              handleResourceReportClose={() =>
                setReportPopupStatus({
                  visible: false,
                  objectID: null,
                })
              }
              handleResourceSaveClose={() =>
                setSavePopupStatus({
                  visible: false,
                  selectedResource: null,
                })
              }
              handleResourceSaveOpen={() =>
                setSavePopupStatus({
                  visible: true,
                  selectedResource: props?.value,
                })
              }
              handleResourceShareOpen={() =>
                setSharePopupStatus({
                  visible: true,
                  objectID: props?.value?.resource_id
                })
              }
              handleResourceShareClose={() =>
                setSharePopupStatus({
                  visible: false,
                  selectedResource: null
                })
              }
            /></div>
        </div>
        <div className="SummerCamp">
          <div className="d-flex">
            {
              props?.value?.resource_thumbnail_image ?
                <div className="resource-img" onClick={() =>
                  openRouteInNewTab(`/full-description/${props?.value?.resource_id}/${toSeoUrl(props?.value?.resource_title)}`)
                }><img src={imageUrl + props?.value?.resource_thumbnail_image} className="resource-image2" alt="MatchED resource" />
                </div> :
                <>
                  {
                    props?.value?.imageLink && !props?.value?.imageLink.includes("booksamillion.com") ?
                      <div className="resource-img" onClick={() =>
                        openRouteInNewTab(`/full-description/${props?.value?.resource_id}/${toSeoUrl(props?.value?.resource_title)}`)
                      }><img src={props?.value?.imageLink} className="resource-image2" alt="MatchED resource" />
                      </div> : <>
                        {
                          props?.value?.resource_type === 7 && <div className="img-Facilities" style={{ cursor: "pointer" }} onClick={() =>
                            openRouteInNewTab(`/full-description/${props?.value?.resource_id}/${toSeoUrl(props?.value?.resource_title)}`)}>
                            <img src={image2} className="curriculum-image2" alt="Facility" />
                          </div>
                        }
                        {
                          props?.value?.resource_type === 6 && <div className="img-funding" style={{ cursor: "pointer" }} onClick={() =>
                            openRouteInNewTab(`/full-description/${props?.value?.resource_id}/${toSeoUrl(props?.value?.resource_title)}`)}>
                            <img src={srimg5} className="curriculum-image2" alt="Funding" />
                          </div>
                        }
                        {
                          props?.value?.resource_type === 3 && <div className="img-div1" style={{ cursor: "pointer" }} onClick={() =>
                            openRouteInNewTab(`/full-description/${props?.value?.resource_id}/${toSeoUrl(props?.value?.resource_title)}`)}>
                            <img src={ph22} className="curriculum-image2" alt="Experiences" />
                          </div>
                        }
                        {
                          props?.value?.resource_type === 4 && <div className="img-div4" style={{ cursor: "pointer" }} onClick={() =>
                            openRouteInNewTab(`/full-description/${props?.value?.resource_id}/${toSeoUrl(props?.value?.resource_title)}`)}>
                            <img src={vic1} className="student-image1" alt="Student Support" />
                          </div>
                        }
                        {
                          props?.value?.resource_type === 2 && <div className="img-div2" style={{ cursor: "pointer" }} onClick={() =>
                            openRouteInNewTab(`/full-description/${props?.value?.resource_id}/${toSeoUrl(props?.value?.resource_title)}`)}>
                            <img src={vectorr} className="curriculum-image1" alt="Curriculum Content" />
                          </div>
                        }
                        {
                          props?.value?.resource_type === 1 &&
                          <div className="images-organization" style={{ cursor: "pointer" }} onClick={() =>
                            openRouteInNewTab(`/full-description/${props?.value?.resource_id}/${toSeoUrl(props?.value?.resource_title)}`)}>
                            <img src={vector4} className="student-image1" alt="Organization" />
                          </div>
                        }
                        {
                          props?.value?.resource_type === 5 &&
                          <div className="img-div3" style={{ cursor: "pointer" }} onClick={() =>
                            openRouteInNewTab(`/full-description/${props?.value?.resource_id}/${toSeoUrl(props?.value?.resource_title)}`)}>
                            <img src={vector3} className="curriculum-image3" alt="Teacher Parents Support" />
                          </div>
                        }</>
                  }

                </>
            }

            <div className="summer-text">
              <h3 className="share-title" onClick={() =>
                openRouteInNewTab(`/full-description/${props?.value?.resource_id}/${toSeoUrl(props?.value?.resource_title)}`)}>  {props?.value?.resource_title}</h3>

              {props?.value?.description?.length > 287 &&
                props?.value?.description ? <div>
                {renderDescription(props?.value?.description?.substr(0, 287))} <span className="spanrightsidecard" onClick={() =>
                  openRouteInNewTab(`/full-description/${props?.value?.resource_id}/${toSeoUrl(props?.value?.resource_title)}`)}>Read more</span>
              </div> : <>{renderDescription(props?.value?.description)}</>
              }
              <p>{props?.value?.resource_type_name}</p>
              <p className="mb-2">{props?.value?.start_date ? <><b>Date: </b>{moment(props?.value?.start_date).format("MM-DD-YYYY")}{props?.value?.end_date && <> to {moment(props?.value?.end_date).format("MM-DD-YYYY")}</>}</> : ""}</p>
              <div className="text-curriculum11 mt-2">

                {Array.isArray(props.value?.resource_learning_pillar) &&
                  props?.value?.resource_learning_pillar?.map((item, i) => {
                    switch (item.pillar_id) {
                      case 1:
                        return (
                          <span key={i} style={{ color: "#F19357" }}>
                            <img className="img-fluid" src={Mindicon} alt="mind" /> Mind
                          </span>
                        );
                      case 2:
                        return (
                          <span key={i} style={{ color: "#265472" }}>
                            <img className="img-fluid" src={Hearticon} alt="heart" />
                            Heart{" "}
                          </span>
                        );
                      case 3:
                        return (
                          <span key={i} style={{ color: "#AAC0CE" }}>
                            <img className="img-fluid" src={Strengthicon} alt="strength" />
                            Strength{" "}
                          </span>
                        );

                      case 4:

                        return (
                          <span key={i} style={{ color: "#F0B285" }}>
                            <img className="img-fluid" src={Sounicon} alt="soul" /> Soul{" "}
                          </span>
                        );
                      default:
                        return null
                    }
                  })}

              </div>
              <div>
                <span className="px-1"> {props?.value?.total_rating !== 0 ? Number(props?.value?.total_rating).toFixed(1) : "0.0"}</span>
                {renderRatingStars(props?.value?.total_rating)}
                <span>{" "}</span><span className="reviews" onClick={() => { localStorage.setItem("scrollvaluereview", true); openRouteInNewTab(`/full-description/${props?.value?.resource_id}/${toSeoUrl(props?.value?.resource_title)}?passKey=pass`) }

                }>


                  <span> ({props?.value?.total_reviews !== 0 ? props?.value?.total_reviews : "0"})&nbsp;{" "}</span>
                  See all reviews </span></div>
              <div className="summer-text-description-datas square scrollbar-dusty-grass square thin">

                {/* {props?.value?.resource_reviews?.message.length < 80 &&
                  props?.value?.resource_reviews?.message ?
                  <h5>"{props?.value?.resource_reviews?.message.substr(0,80)+ " ... "}" </h5> :""} */}
                {props?.value?.resource_reviews?.message ? <h5>"{props?.value?.resource_reviews?.message}" </h5> : ""}
              </div>
            </div>
            <div className="shareicon-img">
              {props?.value?.save === "yes" ? (
                <i className="fa fa-bookmark"></i>
              ) : (
                <i className="fa fa-bookmark-o"></i>
              )}
            </div>
          </div>

        </div>
        <div className="comments">
          <div className="like">
            <span><i className="fa fa-thumbs-up"></i></span>
            <label>{likeFlag === true ? props?.value?.total_likes + 1 : props?.value?.total_likes}</label>
          </div>
          <div className="comments1">
            <span>{commentsFlag === true ? commentsCount : commentsCount > 0 ? commentsCount : "0"} Comments</span>
            <label>{props?.value?.total_shared} Share</label>
          </div>
        </div>
        <div className="comments-div">
          <div className="like-box"
            onClick={() => { likeResource(props?.value?.resource_id) }}>
            <span><i className="fa fa-thumbs-o-up pointer"></i></span>
            <label>Like</label>
          </div>
          <div className="comments-box" onClick={() => handleResourceCommentsOpen()}>
            <span><i className="fa fa-comment-o pointer"></i></span>
            <label>Comments</label>
          </div>
          <div className="share-box" onClick={() => handleResourceShareOpen()}>
            <span><i className="fa fa-share pointer"></i></span>
            <label>Share</label>
          </div>
        </div>
        <div className="See-more-div">
          {
            comments?.length > 0 && <h4
              onClick={() => handleResourceCommentsOpen()}>See more comments</h4>
          }


          {comments && comments?.map((val, index) => {
            return (
              <>
                <div className="comment-div-box">
                  <div className="imagejohanadiv">
                    <img className="img-fluid" src={val?.photo} alt="MatchED user" />
                  </div>
                  <div className="joana-comments">
                    <h3>{val?.name}</h3>
                    <span>{val?.comment?.trim()}</span>
                    {
                      val?.user_id === user_id && <strong className="delete-massages" onClick={() => deleteComments(val?.id)}>
                        <DeleteIcon />
                      </strong>
                    }
                  </div>
                </div >
              </>
            )
          })}
          <div className="comments-time">
          </div>
          <form onSubmit={(e) => commentResource(e, props?.value?.resource_id)}>
            <div className="jane-image-div align-items-center">
              <img className="img-fluid" src={props.userImg} alt="MatchED user" />
              <input type="text" placeholder="Write a comment..." value={myComment} onChange={(e) => setMyComment(e.target.value)}
              />
              <button className="sendBtn" type="submit"
              ><i className="fa fa-send"></i></button>
            </div>
          </form>

        </div>
      </div >

      <Modal
        open={announcement}
        onClose={() => setAnnouncement(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
            style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
          >
            Are you sure you want to remove this resource from bulletin board?
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 3 }}
            className="d-flex justify-content-center gap-4">
            <button className="child-delete" onClick={() => handleToggle(props?.value.resource_id)}>
              Yes
            </button>
            <button
              className="child-cancel"
              onClick={() => setAnnouncement(false)}
            >
              No
            </button>
          </Typography>
        </Box>
      </Modal>
    </>
  )
}

export default ResourceCard