import React, { useEffect, useState, useRef,forwardRef,useImperativeHandle } from 'react'
import FourDotsLoader from '../Common.WebAppMate/FourDoted';
import { decodeToken } from "../../utilities/CustomFunctions";
import { URLS } from '../../constants';
import { makePostRequest } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

/**
 * create components is used member listing
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 * created on: 28-7-2023
 */

const PendingList = (props, ref) => {
    const userId = decodeToken().userAuth.id;
    const navigate = useNavigate();
    const [groupMemberList, setGroupMemberList] = useState([]);
    const usersrote = useSelector((store) => store.user)
    const [remainingCount, setRemainingCount] = useState("");
    const [page, setPage] = useState(1);
    const [spin, setSpin] = useState(false);
    const [empty,setEmpty]=useState(0);
     useImperativeHandle(ref,()=>({
        getCheck
    }))


    const getCheck=(p,e) =>
    {
        setPage(p)
        setEmpty(e)
    }

     // this function used for getting pending member list
    const getPendingMember = (p,e) => {
        setSpin(true)
       setEmpty(e)
        const data = {
            "group_id": props.id,
            "page_number": p?p:page,
            "searchKey": props.searchKey,
            "item_per_page": 4
        }
        console.log("Data payload pending member",data)
        makePostRequest(URLS.pendingMemberInvite, null, data, null)
            .then((res) => {
                console.log("pandin:", res)
                setSpin(false)
                const data = {
                    "group_id": props.id,
                    "page_number": p?p:page,
                    "item_per_page": 4,
                    "searchKey":props.searchKey,
                }
                console.log("Data payload pending non member",data)
                makePostRequest(URLS.nonMatchedInvite, null, data, null)
                    .then((response) => {
                        console.log("nonMatchedInvite:", response)
                        if (page == 1) {
                        //      if(props.searchKey!=""){

                        //     setGroupMemberList([...res?.data?.groupMemberList])
                        // }else {
                            setGroupMemberList([...res?.data?.groupMemberList, ...response?.data?.groupMemberList])
                        // }
                        }
                        else {
                            setGroupMemberList([...groupMemberList, ...res?.data?.groupMemberList, ...response?.data?.groupMemberList]);
                        }
                    }).catch((error) => {
                        console.log("error:", error)
                    })
                // }
                setRemainingCount(res?.data?.remaining_count)
            }).catch((error) => {
                console.log("error:::", error)
            })
    }
    useEffect(() => {
        
        getPendingMember(page)
        
    }, [page])

    useEffect(() => {
     if(empty==0)
     {
     setGroupMemberList([])
     getPendingMember(page,1)
     }
     else
     getPendingMember(page,1)
    
},[empty]);

console.log("empty",empty)

    // useEffect(() => {
     
    //   if(remainingCount == 0)
    //    setPage(1)
    //  }


    // ,[remainingCount])

    console.log("group members",groupMemberList)

    useEffect(() => {
        usersrote?.grouppandingList?.length > 0 && setGroupMemberList(usersrote?.grouppandingList)
    }, [usersrote?.grouppandingList])

    return (
        <div>
            { spin && <div className="sharepopupOuter backgroundOpacity1"><FourDotsLoader /></div>
            }
            <div className="member-outer-main row">
                {groupMemberList?.length > 0 ? groupMemberList?.map((val, index) => {
                        return <>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-12 ">
                                <div className="member-inner-div">
                                    <div className="member-inner-flex">
                                        <div className="imgcirclee my-2">
                                            <img className="img-fluid" src={val?.tbl_user?.photo} alt='group member image' loading="lazy" />
                                        </div>
                                    </div>
                                    {
                                        val?.tbl_user?.id == userId ? <h6 className="text-capitalize" onClick={() => navigate(`/parent-registration-form`)}>{val?.tbl_user?.firstName + " " + val?.tbl_user?.lastName}</h6> : <h6 className="text-capitalize" onClick={() => { val.event && navigate(`/user-profile/${val?.user_id}`) }}>{val?.tbl_user?.firstName + " " + val?.tbl_user?.lastName}</h6>
                                    }
                                    <div className="member-button text-center ">
                                        <button className="view-profile my-5" onClick={() => { val.event && navigate(`/user-profile/${val?.user_id}`) }}>View Profile  </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    }) : <h1 className="text-center my-5">Data Not Found</h1>
                }
                <div className="show-more">
                    {remainingCount == 1 && <p className="text-center" onClick={() => setPage(page + 1)}>Show more</p>}
                    
                </div>

            </div>

        </div>
    )
}

export default forwardRef(PendingList);