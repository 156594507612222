import React from 'react'
import { useNavigate } from 'react-router-dom'
import { toSeoUrl } from '../../services/common.services';
// /*
//      * Notification function is used to do view all the notification
//      *
//      * @author krishna dobariya <krishna.webappmate@gmail.com>
//      * created on: 1-08-2023
//      * /
const ThreeDotsPopupForGroup = (props) => {
    const navigate = useNavigate()
    return (
        <>
            <div className="dotspop" ref={props.forwardedref}>
                <a referrerPolicy="no-referrer" style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/groupjoin/${props?.id.toString()}/${toSeoUrl(props?.name)}`)} className="border-bottom">
                    View
                </a>
                <a referrerPolicy="no-referrer" style={{ cursor: "pointer" }} onClick={() => navigate(`/group-members/${props?.id}`)}>
                    Manage Group
                </a>
            </div>
        </>
    )
}
export default ThreeDotsPopupForGroup;