import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { makePostRequest, makePostRequestForAll } from "../../services/api";
import { URLS } from "../../constants";
import { toastifyMessage,style} from "../../utilities/CustomFunctions";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import { useDispatch } from "react-redux";
import { getGroupMemberList } from "../../store/reducers/userReducer";
/**
 * create components is used to delete child profile
 *
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 */

const DeleteMember = (props) => {

    const [spin, setSpin] = useState(false)
    const [type, setType] = useState("")
    let dispatch = useDispatch();

    // this function is used for delete child 
    const deleteMember = () => {
        setSpin(true)
        var payload = {
            "id": props.id,
            "status": type === "admin" ? "" : "removed",
            "remove_type": type
        }
        console.log("payload::", payload)
        makePostRequestForAll(URLS.removeMember, payload)
            .then((res) => {
                console.log("res::", res)
                setSpin(false)
                if (res.code === 200) {
                    toastifyMessage(res.message, "success")
                    setTimeout(() => {
                        const data = {
                            "group_id": props.funCall,
                            "page_number": 1,
                            "searchKey": "",
                            "item_per_page": 6,
                        }
                        makePostRequest(URLS.getGroupMember, null, data, null)
                            .then((res) => {
                                console.log("res:::", res)
                                dispatch(getGroupMemberList(res?.data?.groupMemberList))
                            }).catch((error) => {
                                console.log("error:::", error)
                                setSpin(false)
                            })
                    }, 500);
                }
                props.handleClose();
            })
            .catch((error) => {
                console.log("error", error);
                setSpin(false)
            });
    };

    return (
        <>
            {spin && (
                <div className="sharepopupOuter backgroundOpacity1">
                    <FourDotsLoader />
                </div>
            )}
            <Modal
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        className="text-center"
                        style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
                    >
                        {
                            props?.AdminBox ? <>
                                Remove Member
                                <div className="joinas_div">
                                    <label className="join_as">Remove As </label >{" "}<label className="select_one">  (select one)</label>
                                </div>
                                <div className="radio_btn_div">
                                    <label> <input type="radio" name="fav_language" value="" className="radio_button" defaultChecked onChange={(e) => setType(e.target.value)} />
                                        <label htmlFor="html" className="individual">Group Member</label></label>
                                    <label className="mx-3">
                                        <input type="radio" name="fav_language" value="admin" className="radio_button" onChange={(e) => setType(e.target.value)} />
                                        <label htmlFor="html" className="subgroup">Admin</label></label>
                                </div>

                            </> : <>Are you sure you want to remove this member ?</>
                        }

                    </Typography>
                    <Typography
                        id="modal-modal-description"
                        sx={{ mt: 3 }}
                        className="d-flex justify-content-center gap-4"
                    >
                        <button className="child-delete" onClick={deleteMember}>
                            Remove
                        </button>{" "}
                        <button className="child-cancel" onClick={props.handleClose}>
                            Cancel
                        </button>
                    </Typography>
                </Box>
            </Modal>
        </>
    );
};

export default DeleteMember;
