import { useState, useEffect } from "react";
import "./sharepostpopup.css";
import vectorr from "../../assets/Images/vectorr.png";
import image2 from "../../assets/Images/image2.png";
import image3 from "../../assets/Images/image3.png";
import image4 from "../../assets/Images/image4.png";
import parent from "../../assets/Images/parent.png";
import vic1 from "../../assets/Images/vic1.png";
import stargroup from "../../assets/Images/stargroup.png";
import { makeGetRequest, makePostRequest } from "../../services/api";
import { URLS } from "../../constants";
import { getSelectedResourceType } from "../../store/reducers/userReducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

/**
 * share resource  popup
 *
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 * created on: 12-08-2023
 */

function SharePostpopup(props) {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [resourceData, setResourceData] = useState([]);
  const [shareResource, setShareResource] = useState([]);
  const [shareFriend, setShareFriends] = useState([]);
  const [friendData, setFriendData] = useState([]);
  const [friendList, setFriendList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [groupId, setGroupId] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [shareResourceSelectedData, setShareResourceSelectedData] =
    useState(null);
  const [selectedResource, setSelectedResource] = useState({
    name: "",
    id: "",
  });
  const [error, setError] = useState({
    friend: "",
    group: "",
  });
  const [active, setActive] = useState(false);
  const shareResourceOptions = [
    { value: "matched", label: "All MatchED" },
    { value: "local", label: "Local MatchED Community" },
    { value: "friend", label: "Friends" },
    { value: "group", label: "Groups" },
  ];

  //api call to get resources type
  const getAllResources = () => {
    makeGetRequest(URLS.getAllResources, null, null, null)
      .then((res) => {
        if (res.code === 200) {
           setResourceData(res.data);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  //pass selected resource type nd share resource in redux action
  const handleSelectedResource = (name, id) => {
    setSelectedResource({ ...selectedResource, name: name, id: id });
  };
  //redirect to save page
  const redirectToSaveResource = () => {

    if (!shareResource?.includes("friend") && !shareResource?.includes("group")){
      navigate("/save-page")
    }

    else if (shareResource?.includes("friend") || shareResource?.includes("group")){
      if (friendData.length <= 0){
        setError({
          friend: "Please select at least one friend.",
          group: "",
        })
            }
            if (groupData.length <= 0){
              setError({
                friend: "Please select at least one friend.",
                group: "Please select at least one group.",
              })
                  }
    }
    if (friendData.length > 0 && groupData.length <=0 && shareResource?.includes("friend") && shareResource?.includes("group")){
      setError({
        friend: "",
        group: "Please select at least one group.",
      })
    }else if (friendData.length <= 0 && groupData.length > 0 && shareResource?.includes("friend") && shareResource?.includes("group")){
      setError({
        friend: "Please select at least one friend.",
        group: "",
      })
    } else if (friendData.length > 0 && groupData.length > 0 && shareResource?.includes("friend") && shareResource?.includes("group")) {
      navigate("/save-page");
    }
    else if(friendData.length > 0 && shareResource?.includes("friend") && !shareResource?.includes("group")){
      navigate("/save-page");
    }else if(groupData.length > 0 && shareResource?.includes("group")&& !shareResource?.includes("friend") ){
      navigate("/save-page");
    }
    dispatch(
      getSelectedResourceType({
        name: selectedResource.name,
        id: selectedResource.id,
        shareResource: shareResourceSelectedData,
        sharewith: shareResource,
        friendId: shareFriend,
        friendData: friendData,
        groupId: groupId,
        groupData: groupData,
      })
    );

  };

  // these functions are use for handle the input data
  const handleShareResource = (shareResourceVal) => {
    setShareResourceSelectedData(shareResourceVal);
    const shareResArr = [];
    shareResourceVal.length > 0 &&
      shareResourceVal.map((val, i) => {
        shareResArr.push(val.value);
      });
    setShareResource(shareResArr);
  };

  const handleGroupId = (shareResourceVal) => {
    let shareArr = [];
    shareResourceVal.length > 0 &&
      shareResourceVal.forEach((val, i) => {
        shareArr.push(val.value);
      });
    if ((shareArr.includes("all") && shareArr[shareArr.length - 1] === "all" && shareArr.length > 1) || (shareArr.length === 1 && shareArr.includes("all"))) {
      setGroupData([{ label: "All", value: "all" }]);
      setGroupId(["all"]);
    }
    else {
      setGroupData(shareResourceVal.filter(item => item.value !== "all"))
      setGroupId(shareArr.filter(item => item !== "all"));
    }
  };
  const handleFriendsId = (shareResourceVal) => {
    let shareArr = [];
    shareResourceVal.length > 0 &&
      shareResourceVal.forEach((val, i) => {
        shareArr.push(val.value);
      });
    if ((shareArr.includes("all") && shareArr[shareArr.length - 1] === "all" && shareArr.length > 1) || (shareArr.length === 1 && shareArr.includes("all"))) {
      setShareFriends(["all"]);
      setFriendData([{ label: "All", value: "all" }])
    } else {
      setFriendData(shareResourceVal.filter(item => item.value !== "all"))
      setShareFriends(shareArr.filter(item => item !== "all"));
    }
  };


  // these functions use for get the data on load or update state
  useEffect(() => {
    if (selectedResource.id && selectedResource.name) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [selectedResource]);

  useEffect(() => {
    if (shareResource.includes("friend")) {
      makeGetRequest(URLS.friendList, null, null, null)
        .then((res) => {

          const arr = [];
          res?.data?.length > 0 && arr.push({ label: "All", value: "all" });
          res?.data.map((val) => {
            return arr.push({ label: val.name, value: val.id });
          });
          setFriendList(arr);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    if (shareResource.includes("group")) {
      makePostRequest(URLS.getGroupList, null, {}, null)
        .then((res) => {
          var arr = [];
          res?.data?.myGroups?.data?.length > 0 && arr.push({ label: "All", value: "all" })
          res?.data?.myGroups?.data?.map((val, index) => {
            return arr.push({
              label: val?.group_name + " ( " + val?.member_type + " ) ",
              value: val?.id,
              type: val?.member_type,
            });
          });
          setGroupList(arr);
        })
        .catch((error) => {
          console.log("error:::", error);
        });
    }
  }, [shareResource]);
  useEffect(() => {
    getAllResources();
  }, []);
  return (
    <>
      <div className="sharepopupOuter">
        <div className="post-popup-container">
          <button onClick={props.data} className="close-btn">
            X
          </button>
          <h1>Create a Post</h1>

          <div className="post-scroll-div square scrollbar-dusty-grass square thin">
            <div className="post-inner-div">
              <div className="imgpopupshara">
                <img className="img-fluid" src={props.userImg} alt="MatchED user image" />
              </div>
              <div className="titlewithselectbox">
                <h2>{props.fullName}</h2>
              </div>
              <div className="publicprivatepost">
                <Select
                  options={shareResourceOptions}
                  isMulti
                  selectMultiple={true}
                  onChange={handleShareResource}
				  
                />
              </div>
              {shareResource?.includes("friend") && (
                <div className="input-popup2">
                  <label className="pop-label my-2">
                    Select Friends <span className="start-color">*</span>{" "}{" "}
                    <span className="select-one">(select at least one)</span>
                  </label>
                  <div className="text-capitalize">
                    <Select
                      value={friendData ? friendData : null}
                      options={friendList}
                      isMulti
                      selectMultiple={true}
                      onChange={handleFriendsId}
                    />

                  </div>
                  <span className="error-span">
                    {(error && friendData.length <= 0) ? error.friend : ""}
                  </span>
                </div>
              )}
              {shareResource?.includes("group") && (
                <div className="input-popup2">
                  <label className="pop-label my-2">
                    Select Group <span className="start-color">*</span>{" "}{" "}
                    <span className="select-one">(select at least one)</span>
                  </label>
                  <div className="text-capitalize">
                    <Select
                      value={groupData ? groupData : null}
                      options={groupList}
                      isMulti
                      selectMultiple={true}
                      onChange={handleGroupId}
                    />
                  </div>
                  <span className="error-span">
                    {(error && groupData.length <= 0) ? error.group : ""}
                  </span>
                </div>
              )}
              <h3>What do you want share with the community?</h3>
            </div>

            <div className="service-container">
              <div className="service-inner-container">
                {resourceData.length > 0 &&
                  resourceData.map((val, i) => {
                    return (
                      <>
                        {val.id == 2 && (
                          <div
                            className={`post-image post ${active == true && selectedResource.id == 2
                              ? "active"
                              : ""
                              }`}
                            onClick={() => {
                              handleSelectedResource(val.name, val.id);
                            }}
                            key={i} >
                            <img className="img-fluid cursor-pointer" src={vectorr} alt="Curriculum Content" />
                            <div className="tooltip">{val.name}</div>
                          </div>
                        )}
                        {val.id == 7 && (
                          <div
                            className={`post-image1 post ${active == true && selectedResource.id == 7
                              ? "active"
                              : ""
                              }`}
                            onClick={() => {
                              handleSelectedResource(val.name, val.id);
                            }}
                          >
                            <img className="img-fluid cursor-pointer" src={image2} alt="Facilities" />
                            <div className="tooltip">{val.name}</div>
                          </div>
                        )}
                        {val.id == 6 && (
                          <div
                            className={`post-image2 post ${active === true && selectedResource.id == 6
                              ? "active"
                              : ""
                              }`}
                            onClick={() => {
                              handleSelectedResource(val.name, val.id);
                            }}
                          >
                            <img className="img-fluid cursor-pointer" src={image3} alt="Funding" />
                            <div className="tooltip">{val.name}</div>
                          </div>
                        )}
                        {val.id == 1 && (
                          <div
                            className={`post-image3 post  ${active === true && selectedResource.id == 1
                              ? "active"
                              : ""
                              }`}
                            onClick={() => {
                              handleSelectedResource(val.name, val.id);
                            }}
                          >
                            <img className="img-fluid cursor-pointer" src={image4} alt="Organization" />
                            <div className="tooltip">{val.name}</div>
                          </div>
                        )}
                        {val.id == 5 && (
                          <div
                            className={`post-image4 post  ${active === true && selectedResource.id == 5
                              ? "active"
                              : ""
                              }`}
                            onClick={() => {
                              handleSelectedResource(val.name, val.id);
                            }}
                          >
                            <img className="img-fluid cursor-pointer" src={parent} alt="Teacher Parents Support" />
                            <div className="tooltip">
                            {val.name}
                            </div>
                          </div>
                        )}
                        {val.id == 4 && (
                          <div
                            className={`post-image5 post ${active === true && selectedResource.id == 4
                              ? "active"
                              : ""
                              }`}
                            onClick={() => {
                              handleSelectedResource(val.name, val.id);
                            }}
                          >
                            <img className="img-fluid cursor-pointer" src={vic1} alt="Student Support" />
                            <div className="tooltip">{val.name}</div>
                          </div>
                        )}
                        {val.id == 3 && (
                          <div
                            className={`post-image6 post ${active === true && selectedResource.id == 3
                              ? "active"
                              : ""
                              }`}
                            onClick={() => {
                              handleSelectedResource(val.name, val.id);
                            }}
                          >
                            <img className="img-fluid cursor-pointer" src={stargroup} alt="Experiences" />
                            <div className="tooltip">{val.name}</div>
                          </div>
                        )}
                      </>
                    );
                  })}
              </div>
              <h3>Select a resource, service or event from your saved items.</h3>
            </div>
          </div>
          <div className="post-button text-center">
            <button onClick={redirectToSaveResource}>Post</button>
          </div>
        </div>
      </div>
    </>
  );
}
export default SharePostpopup;
