import { toast } from "react-toastify";

export function capitalizeWords(str) {
    return str.replace(/\b\w/g, function(txt) {
      return txt.toUpperCase();
    });
  }

export const  Emailvalidation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export function toastifyMessage(message, type) {
  if (type === "success") return toast.success(message, {className : "font-14"});
  if (type === "error") return toast.error(message, {className : "font-14"});
  if (type === "warning") return toast.warn(message, {className : "font-14"});
}