/**
     * Subscription page for buy monthy and yearly basis subscription
     *
     * @author Gaurang Ghadiya <ghadiya.gaurang@webappmate.com>
     * created on: 22-05-2023
     */

import './subscription.css';
import { useEffect, useState } from 'react';
import { makeGetRequest } from '../../services/api';
import { URLS } from '../../constants';
import FourDotsLoader from '../Common.WebAppMate/FourDoted';
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
const SubscriptionMobileView = () => {
    const [community, setCommunity] = useState([])
    const [vendor, setVendor] = useState([])
    const [group, setGroup] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [descText, setDescText] = useState({})
    const [selectedType, setSelectedType] = useState({ community: "monthly", vendor: "monthly", group: "monthly" })
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    const [savedata, setsavedata] = useState({
        save:true,
        search:true,
    })
    // Get all Subscription api
    const getSubscription = async () => {
        setIsLoading(true)
        await makeGetRequest(URLS.getSubscriptionPlanList, null,null)
            .then((response) => {
                let community = response?.data?.find(v => v?.id === 1)
                setCommunity(community)
                let vendor = response?.data?.find(v => v?.id === 2)
                setVendor(vendor)
                let group = response?.data?.find(v => v?.id === 3)
                setGroup(group)
                setIsLoading(false)
            })
            await makeGetRequest(URLS.getSystemSetting, null, null)
            .then((response) => {
                let data = response?.data?.filter(v => v?.setting_key === "Group_Standard_Member" || v?.setting_key === "Group_Basic_Member" || v?.setting_key === "Group_Permium_Member")
                let obj = {
                    Group_Basic_Member: data?.find(v => v?.setting_key === "Group_Basic_Member")?.setting_value,
                    Group_Standard_Member: data?.find(v => v?.setting_key === "Group_Standard_Member")?.setting_value,
                    Group_Permium_Member: data?.find(v => v?.setting_key === "Group_Permium_Member")?.setting_value
                }
                setDescText(obj)
                setIsLoading(false)
            })
    }
    useEffect(() => {
        getSubscription()
    }, [])

	const handleClickScrolls = () => {
    const community = document.getElementById('community-member');
		if (community) {
		   // 👇 Will scroll smoothly to the top of the next section
			community.scrollIntoView({ behavior: 'smooth' });
			window.scrollTo(0, 120);
		}
	};
	const handleClickScrolla = () => {
    const serveM = document.getElementById('serve-member');
		if (serveM) {
		   // 👇 Will scroll smoothly to the top of the next section
			serveM.scrollIntoView({ behavior: 'smooth' });
			window.scrollTo(0, 2800);
		}
	};

	const handleClickScrollb = () => {
    const groupB = document.getElementById('group-member');
		if (groupB) {
		   // 👇 Will scroll smoothly to the top of the next section
			groupB.scrollIntoView({ behavior: 'smooth' });
			window.scrollTo(0, 3540);
		}
	};

    useEffect(() => {
        document.body.classList.add("subscription");
        return () => {
            document.body.classList.remove("subscription");
        };
    }, []);

    return (
        <>
            {
                isLoading ? <div className="sharepopupOuter backgroundOpacity1">
                    <FourDotsLoader />
                </div> : <>
                    <div className="mobile-subscription-Page">
                        <section>
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className='col-12 text-center'>
                                        <h2 className='chooseyourTitle'>Choose your subscription
                                            <small>Buy Now</small>
                                        </h2>
                                    </div>
                                </div>
                                <div className="row justify-content-center mt-5">
                                    <div className='col-auto'>
                                        <a className='tabbing mb-2' onClick={handleClickScrolls} >MatchED Community Membership</a>
                                    </div>
                                    <div className='col-auto'>
                                        <a className='tabbing mb-2' onClick={handleClickScrolla} >Vendor 'Serve' Membership</a>
                                    </div>
                                    <div className='col-auto'>
                                        <a className='tabbing mb-2' onClick={handleClickScrollb} >Group Membership</a>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div id="community-members" className='subscription-tab'>
                            <div className="container">
                                <div className="row">
                                    <div className='col-12 col-lg-12'>
                                        <h5 className='mb-3 mnewmobilespc text-capitalize'>MatchED Community Membership</h5>
                                    </div>
                                    <div className='col-6 col-lg-6 text-start manageLeftsideAccordion subsciptionMobilePaddingRight'>
                                        <div className='d-flex align-items-center  mobilemembership-smalltext'>
                                            <p className="mnewmobilespc">Monthly</p>
                                            <label className="toggle-switch ">
                                                <input type="checkbox" checked={selectedType?.community !== "monthly" ? true : false} onChange={(e) => setSelectedType({ ...selectedType, community: selectedType?.community === "monthly" ? "yearly" : "monthly" })} />
                                                <span className="switch" />
                                            </label>
                                            <p className='ms-3 '>Yearly</p>
                                        </div>
                                        <div className="accordion my-accordion content-block" id="subscription-accordion">
                                            {community?.tbl_subscription_plan_subheadings?.map(v => (
                                                <div className="accordion-item white-bg content-block" key={v?.title}>
                                                    <h2 className="accordion-header" id="headingOne">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${v?.title?.replaceAll(" ", "")?.replaceAll("&", "")}`} aria-expanded="false" aria-controls={v?.title?.replaceAll(" ", "")?.replaceAll("&", "")}>
                                                            {v?.title}
                                                        </button>
                                                    </h2>


                                                    <div id={v?.title?.replaceAll(" ", "")?.replaceAll("&", "")} className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#subscription-accordion">
                                                        <div className="accordion-body">


                                                            <ul className='content-list'>
                                                                {v?.tbl_subscription_checklist_masters?.map(p => (
                                                                    <li key={p?.heading}>
                                                                        <div className="row">
                                                                            <div className='col-12 text-start'>
                                                                                <p className=''>{p?.heading}
                                                                                    {p?.tooltip && <a href="javascript:void(0)" referrerPolicy="no-referrer" data-tooltip data-title={p?.tooltip}>
                                                                                        <i className='fa fa-exclamation-circle'></i>
                                                                                    </a>}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='col-6 col-lg-6 mobilecomunitymembershiprightside subsciptionMobilePaddingLeft'>
                                        <Slider {...settings}>
                                            <div>
                                                <div className='col-12 col-lg-12 text-center manageRightsideAccordion subsciptionMobilePaddingLeftRight'>
                                                    <h5 className='mb-3'>Standard</h5>
                                                    <div className='subscription-label blue-bg arrowTopspace'>Free</div>
                                                    <div className="accordion my-accordion content-block" id="subscription-accordion">
                                                        {community?.tbl_subscription_plan_subheadings?.map(v => (
                                                            <div className="accordion-item white-bg content-block" key={v?.title}>
                                                                <h2 className="accordion-header" id="headingOne">
                                                                    <button onClick={() => {

                                                        (v?.title == "Saves"  && setsavedata({...savedata,save:!savedata.save}));(v?.title == "Searches"  && setsavedata({...savedata,search:!savedata.search})) }} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${v?.title?.replaceAll(" ", "")?.replaceAll("&", "")}`} aria-expanded="false" aria-controls={v?.title?.replaceAll(" ", "")?.replaceAll("&", "")}>
                                                                    </button>
                                                                </h2>


											{(v?.title == "Saves" && savedata.save) ?
                                                    <div className="subscriptiondataInfo subscriptiondataInfo-right">
                                                        <div className="row">

                                                            <div className='col-12 text-left'>
                                                                <strong>1 GB Data </strong>
                                                            </div>


                                                        </div>
                                                    </div>

                                                    : (v?.title == "Searches" && savedata.search) ? (
                                                        <div className="subscriptiondataInfo subscriptiondataInfo-right">
                                                            <div className="row">

                                                                <div className='col-12 text-left'>
                                                                    <strong>15 Searches</strong>
                                                                </div>

                                                            </div></div>
                                                    ) :   (" ")
                                                }
                                                                <div id={v?.title?.replaceAll(" ", "")?.replaceAll("&", "")} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#subscription-accordion">
                                                                    <div className="accordion-body">
                                                                        <ul className='content-list'>
                                                                            {v?.tbl_subscription_checklist_masters?.map(p => (
                                                                                <li key={p?.heading}>
                                                                                    <div className="row">
                                                                                        <div className='col-12'>
                                                                                            {p?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 2 && t?.status === "active") ?
                                                                                                <i className="fa fa-check-circle" style={{ color: "green" }} ></i> : <i className="fa fa-times-circle-o"></i>}
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='col-12 col-lg-12 text-center manageRightsideAccordion subsciptionMobilePaddingLeftRight'>
                                                    <h5 className='mb-3'>Premium</h5>
                                                    <div className='subscription-label orange-bg arrowTopspace'>${
                                                        community?.tbl_subscription_plans?.find(v => (v?.payment_frequency_type === selectedType?.community))?.price ?? 0
                                                    }{selectedType?.community !== "monthly" ? "/yr" : "/mo"}
                                                    </div>
                                                    <div className="accordion my-accordion content-block" id="subscription-accordion">
                                                        {community?.tbl_subscription_plan_subheadings?.map(v => (
                                                            <div className="accordion-item white-bg content-block" key={v?.title}>
                                                                <h2 className="accordion-header" id="headingOne">
                                                                    <button onClick={() => {
                                                        (v?.title == "Saves"  && setsavedata({...savedata,save:!savedata.save}));(v?.title == "Searches"  && setsavedata({...savedata,search:!savedata.search})) }} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${v?.title?.replaceAll(" ", "")?.replaceAll("&", "")}`} aria-expanded="false" aria-controls={v?.title?.replaceAll(" ", "")?.replaceAll("&", "")}>
                                                                    </button>
                                                                </h2>


															{(v?.title == "Saves" && savedata.save) ?
                                                    <div className="subscriptiondataInfo subscriptiondataInfo-right">
                                                        <div className="row">

                                                            <div className='col-12 text-left'>
                                                                <strong>10 GB Data </strong>
                                                            </div>


                                                        </div>
                                                    </div>

                                                    : (v?.title == "Searches" && savedata.search) ? (
                                                        <div className="subscriptiondataInfo subscriptiondataInfo-right">
                                                            <div className="row">
                                                                <div className='col-12 text-left'>
                                                                    <strong> Unlimited Searches</strong>
                                                                </div>
                                                             </div>
															</div>
                                                    ) :   (" ")
                                                }
                                                                <div id={v?.title?.replaceAll(" ", "")?.replaceAll("&", "")} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#subscription-accordion">
                                                                    <div className="accordion-body">
                                                                        <ul className='content-list'>
                                                                            {v?.tbl_subscription_checklist_masters?.map(p => (
                                                                                <li key={p?.heading}>
                                                                                    <div className="row">
                                                                                        <div className='col-12'>
                                                                                            {p?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 1 && t?.status === "active" && selectedType?.community === "monthly") ?
                                                                                                <i className="fa fa-check-circle" style={{ color: "green" }}></i> : p?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 47 && t?.status === "active" && selectedType?.community === "yearly") ? <i className="fa fa-check-circle" style={{ color: "green" }}></i> : <i className="fa fa-times-circle-o"></i>}
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="serve-members" className='subscription-tab mt-2 mb-2'>
                            <div className="container my-accordion">

                            <div className="col-12 white-bg content-block accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Explore Vendor 'Serve' Membership
                                    </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse p-3 pt-0 collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">




                                <div className="row">
                                    <div className='col-12 col-lg-12 mb-4'>
                                        <h5 className='text-capitalize'>Vendor 'Serve' Membership</h5>
                                    </div>
                                    <div className='col-6 col-lg-6 text-start mobilemembership-smalltext subsciptionMobilePaddingRight'>
                                        <h6 className='mb-3 groupsindividuals'>for both Individuals and Groups</h6>
                                        <div className='d-flex align-items-center monthlyBox'>
                                            <p>Monthly</p>
                                            <label className="toggle-switch ">
                                                <input type="checkbox" checked={selectedType?.vendor !== "monthly" ? true : false} onChange={(e) => setSelectedType({ ...selectedType, vendor: selectedType?.vendor === "monthly" ? "yearly" : "monthly" })} />
                                                <span className="switch" />
                                            </label>
                                            <p className='ms-3 '>Yearly</p>
                                        </div>
                                        <div className='col-12 p-3 white-bg content-block'>
                                            <ul className='content-list'>
                                                {vendor?.tbl_subscription_plan_subheadings?.[0]?.tbl_subscription_checklist_masters?.map((v, i) => (
                                                    <ul className='content-list' key={i}>
                                                        <li key={v?.heading}>
                                                            <div className="row">
                                                                <div className='col-12 col-lg-12 text-start'>
                                                                    <p className=''>{v?.heading}
                                                                        {v?.tooltip && <a href="javascript:void(0)" referrerPolicy="no-referrer" data-tooltip data-title={v?.tooltip}>
                                                                            <i className='fa fa-exclamation-circle'></i>
                                                                        </a>}
                                                                    </p>
                                                                </div>

                                                            </div>
                                                        </li>
                                                    </ul>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='col-6 col-lg-6 p-0 mobilegroupmembershipRightSide subsciptionMobilePaddingLeft'>
                                        <Slider {...settings}>
                                            <div>
                                                <div className='col-12 col-lg-12 text-center subsciptionMobilePaddingLeftRight  vendormembershipbuttonadjustment'>
                                                    <h5 className='mb-3'>Basic</h5>
                                                    <div className='subscription-label blue-bg arrowTopspace'>Free</div>
                                                    <div className='col-12 p-3 white-bg content-block  vendormembershipMinHeight'>
                                                        <ul className='content-list'>
                                                            {vendor?.tbl_subscription_plan_subheadings?.[0]?.tbl_subscription_checklist_masters?.map((v, i) => (
                                                                <ul className='content-list' key={i}>
                                                                    <li key={v?.heading}>
                                                                        <div className="row">
                                                                            {console.log(v?.tbl_subscription_checklist_mappings)}
                                                                            <div className='col-12'>
                                                                                {v?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 50 && t?.status === "active") ?
                                                                                    <i className="fa fa-check-circle" style={{ color: "green" }}></i> : <i className="fa fa-times-circle-o"></i>}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            ))}
                                                        </ul>
                                                    </div>

                                                </div>
                                            </div>
                                            <div>
                                                <div className='col-12 col-lg-12 text-center subsciptionMobilePaddingLeftRight  vendormembershipbuttonadjustment'>
                                                    <h5 className='mb-3'>Premium</h5>
                                                    <div className='subscription-label orange-bg arrowTopspace'>${
                                                        vendor?.tbl_subscription_plans?.find(v => (v?.payment_frequency_type === selectedType?.vendor))?.price
                                                    }{selectedType?.vendor !== "monthly" ? "/yr" : "/mo"}</div>

                                                    <div className='col-12 p-3 white-bg content-block vendormembershipMinHeight'>
                                                        <ul className='content-list'>
                                                            {vendor?.tbl_subscription_plan_subheadings?.[0]?.tbl_subscription_checklist_masters?.map((v, i) => (
                                                                // v?.payment_frequency_type == selectedType?.vendor && JSON.parse(v?.instruction)?.map((k, i) => (

                                                                <ul className='content-list' key={i}>
                                                                    {/* {k?.subheading?.map(p => ( */}
                                                                    <li key={v?.heading}>
                                                                        <div className="row">

                                                                            <div className='col-12'>
                                                                                {v?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 49 && t?.status === "active" && selectedType?.community === "monthly") ?
                                                                                    <i className="fa fa-check-circle" style={{ color: "green" }}></i> : v?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 48 && t?.status === "active" && selectedType?.community === "yearly") ? <i className="fa fa-check-circle" style={{ color: "green" }}></i> : <i className="fa fa-times-circle-o"></i>}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            ))}
                                                        </ul>
                                                    </div>

                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>


                                </div>
                                    </div>
                                    </div>

                            </div>
                        </div>
                        <div id="group-members" className='subscription-tab mb-4'>
                            <div className="container my-accordion">

                            <div className="col-12 white-bg content-block accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Explore Group Membership
                                    </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse p-3 pt-0 collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">






                                <div className="row">
                                    <div className='col-6 col-xl-6 col-lg-6 text-start subsciptionMobilePaddingRight'>
                                        <div className='col-12 col-xl-12 col-lg-12 px-0 mobilemembership-smalltext'>
                                            <h5 className='mb-3 text-capitalize membershipGroup'>Group Membership</h5>
                                            <div className='monthlyBox d-flex align-items-center'>
                                                <p>Monthly</p>
                                                <label className="toggle-switch ">
                                                    <input type="checkbox" checked={selectedType?.group !== "monthly" ? true : false} onChange={(e) => setSelectedType({ ...selectedType, group: selectedType?.group === "monthly" ? "yearly" : "monthly" })} />
                                                    <span className="switch" />
                                                </label>
                                                <br />
                                                <p className='ms-3 '>Yearly</p>
                                            </div>
                                        </div>
                                        <div className='col-12 p-3 white-bg content-block d-flex'>
                                            <ul className='content-list'>
                                                {group?.tbl_subscription_plan_subheadings?.[0]?.tbl_subscription_checklist_masters?.map((p, i) => (
                                                    <ul className='content-list' key={i}>
                                                        <li key={p?.heading}>
                                                            <div className="row">
                                                                <div className='col-xl-12 col-lg-12 text-start'>
                                                                    <p className=''>{p?.heading}
                                                                        {p?.tooltip && <a href="javascript:void(0)" referrerPolicy="no-referrer" data-tooltip data-title={p?.tooltip}>
                                                                            <i className='fa fa-exclamation-circle'></i>
                                                                        </a>}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className='col-6 col-xl-6 p-0 col-lg-6 mobilegroupmembershipRightSide subsciptionMobilePaddingLeft'>
                                        <Slider {...settings}>
                                            <div>
                                                <div className='col-12 col-sm-12 col-md-4 col-lg-12 text-center subsciptionMobilePaddingLeftRight'>
                                                    <h5 className='mb-2'>Basic</h5>
                                                    <h6>{descText?.Group_Basic_Member}</h6>
                                                    <div className='subscription-label blue-bg arrowTopspace'>Free</div>
                                                    <div className='col-12 white-bg content-block groupmembershipMinheight'>
                                                        <ul className='content-list'>
                                                            {group?.tbl_subscription_plan_subheadings?.[0]?.tbl_subscription_checklist_masters?.map((p, i) => (
                                                                <ul className='content-list' key={i}>
                                                                    <li key={p?.heading}>
                                                                        <div className="row">

                                                                            <div className='col-12 col-lg-12'>
                                                                                {p?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 51 && t?.status === "active") ?
                                                                                    <i className="fa fa-check-circle" style={{ color: "green" }}></i> : <i className="fa fa-times-circle-o"></i>}
                                                                            </div>

                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            ))}
                                                        </ul>
                                                    </div>

                                                </div>
                                            </div>

                                            <div>
                                                <div className='col-12 col-sm-12 col-md-4 col-lg-12 text-center subsciptionMobilePaddingLeftRight'>
                                                    <h5 className='mb-2'>Standard</h5>
                                                    <h6>{descText?.Group_Standard_Member}</h6>
                                                    <div className='subscription-label gray-bg arrowTopspace'>${
                                                        group?.tbl_subscription_plans?.find(v => (v?.payment_frequency_type === selectedType?.group && v?.price_type === "standard_price"))?.price
                                                    }{selectedType?.group !== "monthly" ? "/yr" : "/mo"}</div>

                                                    <div className='col-12 white-bg content-block groupmembershipMinheight'>
                                                        <ul className='content-list'>
                                                            {group?.tbl_subscription_plan_subheadings?.[0]?.tbl_subscription_checklist_masters?.map((p, i) => (
                                                                <ul className='content-list' key={i}>
                                                                    <li key={p?.heading}>
                                                                        <div className="row">

                                                                            <div className='col-12  col-lg-12'>
                                                                                {p?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 52 && t?.status === "active" && selectedType?.community === "monthly") ?
                                                                                    <i className="fa fa-check-circle" style={{ color: "green" }}></i> : p?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 53 && t?.status === "active" && selectedType?.community === "yearly") ?
                                                                                        <i className="fa fa-check-circle" style={{ color: "green" }}></i> : <i className="fa fa-times-circle-o"></i>}
                                                                            </div>

                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            ))}
                                                        </ul>
                                                    </div>

                                                </div>
                                            </div>
                                            <div>
                                                <div className='col-sm-12 col-md-4 col-lg-12 text-center subsciptionMobilePaddingLeftRight'>
                                                    <h5 className='mb-2'>Premium</h5>
                                                    <h6>{descText?.Group_Permium_Member}</h6>
                                                    <div className='subscription-label orange-bg arrowTopspace'>${
                                                        group?.tbl_subscription_plans?.find(v => (v?.payment_frequency_type === selectedType?.group && v?.price_type === "premium_price"))?.price
                                                    }{selectedType?.group !== "monthly" ? "/yr" : "/mo"}</div>
                                                    <div className='col-12 white-bg content-block groupmembershipMinheight'>
                                                        <ul className='content-list'>
                                                            {group?.tbl_subscription_plan_subheadings?.[0]?.tbl_subscription_checklist_masters?.map((p, i) => (
                                                                <ul className='content-list' key={i}>
                                                                    <li key={p?.heading}>
                                                                        <div className="row">
                                                                            <div className='col-12  col-lg-12'>
                                                                                {p?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 58 && t?.status === "active" && selectedType?.community === "monthly") ?
                                                                                    <i className="fa fa-check-circle" style={{ color: "green" }}></i> : p?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 59 && t?.status === "active" && selectedType?.community === "yearly") ?
                                                                                        <i className="fa fa-check-circle" style={{ color: "green" }}></i> : <i className="fa fa-times-circle-o"></i>}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            ))}
                                                        </ul>
                                                    </div>

                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>

                                </div>
                                    </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default SubscriptionMobileView;