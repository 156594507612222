/**
 * Save Resources List page to show all my saved resources list
 *
 * @author Nidhi Mishra <nidhi.mishra@webappmate.com>
 * created on: 12-05-2023
 */
import { useState, useEffect } from "react";
import "./SaveResourceList.css";
import img1 from "../../assets/Images/sr-img1.png";
import ph22 from "../../assets/Images/ph22.png";
import srimg4 from "../../assets/Images/sr-img4.png";
import srimg3 from "../../assets/Images/sr-img3.png";
import vector3 from "../../assets/Images/vector3.png";
import srimg5 from "../../assets/Images/sr-img5.png";
import ph5 from "../../assets/Images/ph5.png";
import ResetResource from "../../assets/Images/reset-resource.png";
import ResetResourceWhite from "../../assets/Images/reset-resource-white.png";
import { makePostRequest, makeGetRequest, imageUrl, makePostRequestForAll, } from "../../services/api";
import { renderRatingStars } from '../Search.WebAppMate/RenderCardInfo';
import { URLS } from "../../constants";
import { decodeToken, toastifyMessage ,style} from "../../utilities/CustomFunctions";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import RatingPopup from "./RatingPopup";
import { Multiselect } from "multiselect-react-dropdown";
import { getSelectedResourceType } from "../../store/reducers/userReducer";
import { useDispatch } from "react-redux";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import savepages from "../../assets/Images/savepage.jpg";
import Head from "../Layout.WebAppMate/head/Head";
import Saveresourcespopup from "../Search.WebAppMate/Saveresourcespopup";
export const CategoryOptions = [
  { value: "Learning Artifacts", name: "Learning Artifacts" },
  { value: "Learning Resource", name: "Learning Resource" }
];
function SaveResourceList() {
  let userData = decodeToken();
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let useractive = localStorage.getItem("useractive");
  const [isMounted, setIsMounted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [childData, setChildData] = useState([]);
  const [selectedChild, setSelectedChild] = useState([]);
  const [selectedChildData, setSelectedChildData] = useState([])
  const [selectedKey, setSelectedKey] = useState("default");
  const [values, setValues] = useState(false)
  const [savedResourceData, setSavedResourceData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(0);
  const [gradeLevelData, setGradeLevelData] = useState([]);
  const [categoryType, setCategoryType] = useState("");
  const [gradeRange, setGradeRange] = useState([]);
  const [gradeData, setGradeData] = useState([]);
  const [SaveFor, setSaveFor] = useState("");
  const [Keywords, setKeywords] = useState("");
  const [resourceType, setResourceType] = useState("");
  const [open, setOpen] = useState(false);
  const [resourceid, setResourceid] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [userId, setUserId] = useState("");
  const [ratingData, showRatingData] = useState("");
  const [visible, setVisible] = useState(false);
  const [showButton, setShowButton] = useState("");
  const [spinStatus, setSpinStatus] = useState(false);
  const [savePopupStatus, setSavePopupStatus] = useState({
    visible: false,
    selectedResource: null,
  });

  //open confirmation modal to delete resource record
  const handleOpen = (resourceid, user_id) => {
    setOpen(true);
    setResourceid(resourceid);
    setUserId(user_id);
  };
  const orderByOptions = [
    { value: "DESCDATE", name: "Date (Newest to Oldest)" }, // deascending order   
    { value: "ASCDATE", name: "Date (Oldest to Newest)" },  // ascending order
    { value: "ASCTITLE", name: "Title (A to Z)" },
    { value: "DESCTITLE", name: "Title (Z to A)" }
  ];

  const saveForOption = [
    { value: "In progress", name: "In Progress" },
    { value: "Past", name: "Past" },
    { value: "For the future", name: "Future" },
  ];
  const selectKeyOptions = [
    { value: "default", name: "All" },
    { value: "my_resource", name: "My Resource" },
    { value: "other", name: "Other" }
  ];

  //close  confirmation modal
  const handleClose = () => setOpen(false);
  const handleCategory = (selectedList) => {
    selectedList?.forEach((val, i) => {
      setCategoryType(val.value);
    });
  };
  const handleSaveFor = (selectedList) => {
    selectedList?.forEach((val, i) => {
      setSaveFor(val.value);
    });
  };
  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  const resetFunction = () => {
    setSelectedChild([])
    setSelectedChildData([])
    setCategoryType("")
    setGradeRange([])
    setGradeData([])
    setSaveFor("")
    setResourceType("")
    setOrderBy("")
    setKeywords("")
    setCurrentPage(1)
    setSelectedKey("default");
    setValues(true)
  }

  useEffect(() => {
    if (isMounted) {
      if (values) {
        getSavedResourcesList();
      }
    }
    // eslint-disable-next-line
  }, [isMounted])
  //api call to get all my saved resources list
  const getSavedResourcesList = () => {
    setValues(false)
    setLoading(true);
    let data = {
      user_id: userData.userAuth.id,
      child_id: selectedChild,
      storing_type: categoryType,
      grade: gradeRange,
      resource_type: resourceType,
      sortby: orderBy,
      searchKey: Keywords,
      resource_for: SaveFor,
      forntend_type: "yes",
      page_number: currentPage,
      key: selectedKey === "my_resource" ? "" : selectedKey,
    };
    console.log("data", data)
    makePostRequest(URLS.getMySavedResources, null, data, null)
      .then((res) => {
         if(res.data.remaining_count === 1){
          setSpinStatus(true)
        }else{
          setSpinStatus(false)
        }
        console.log("res", res)
        setSpinStatus(true)
        if (res.code === 200) {
          if (selectedKey === "other") {
            setShowButton("other");
          } else {
            setShowButton("");
          }
          setLoading(false);
          let data = res.data.responseData;
          var save = []
          var dump = []
          if (currentPage === 1) {
            res?.data?.share?.share?.forEach((val) => {
              return save.push(val.resource_id)
            })
            for (var i = 0; i < data.length; i++) {
              if (save.includes(data[i].id)) {
                const updatedObject = {
                  ...data[i],
                  share: "yes"
                };
                dump.push(updatedObject)
              } else {
                const updatedObject = {
                  ...data[i],
                  share: "no"
                };
                dump.push(updatedObject)
              }
            }
            setSavedResourceData(dump);
            setHasMore(res.data.remaining_count);
            
          } else {
            res?.data?.share?.share?.forEach((val) => {
              return save.push(val.resource_id)
            })
            for (var j = 0; j < [...savedResourceData, ...data].length; j++) {
              if (save.includes([...savedResourceData, ...data][j].id)) {
                const updatedObject = {
                  ...[...savedResourceData, ...data][j],
                  share: "yes"
                };
                dump.push(updatedObject)
              } else {
                const updatedObject = {
                  ...[...savedResourceData, ...data][j],
                  share: "no"
                };
                dump.push(updatedObject)
              }
            }
            setSavedResourceData(dump);
            setHasMore(res.data.remaining_count);
            
          }
        }
      }
      
    )
      .catch((error) => {
        console.log(error);
      });
  };
  //api call to get  childs data
  const getUserChilds = () => {
    let data = {
      resource_id: "",
    };
    makePostRequest(URLS.getChildList, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          let arr = [];
          res.data.length > 0 &&
            res.data.forEach((el, i) => {
              if (el.childOwner === 1) {
                arr.push({
                  id: el.id,
                  name: el?.firstName + " " + el?.lastName,
                });
              }
            });
          setChildData(arr);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (useractive === "") {
      navigate("/sign-in");
    }
  }, [useractive, navigate]);

  useEffect(() => {
    if (isMounted) {
      getUserChilds();
      getGradeLevelData();
      window.scrollTo({ top: 0, behavior: "smooth" });
      document.body.classList.add("save-resource-list");
      return () => {
        document.body.classList.remove("save-resource-list");
      };
    }
  }, [isMounted]);
  useEffect(() => {
    if (savedResourceData?.length === 0 && spinStatus === false) {
      setLoading(true)
    } else {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [ spinStatus]);

  useEffect(() => {
    if (isMounted) {
      getSavedResourcesList();
    }
    // eslint-disable-next-line
  }, [currentPage, orderBy, isMounted]);

  // this function use for redirect to edit
  const redirectToEdit = (resourceid, user_id, mode) => {
    if (parseInt(user_id) === userData.userAuth.id) {
      if (mode === "serve") {
        navigate("/serve/" + resourceid);
      } else {
        navigate("/save-page/" + resourceid);
      }
    } else {
      toastifyMessage("You're not authorised to edit resource", "error");
    }
  };
  const redirectToAdd = () => {
    dispatch(getSelectedResourceType({ name: "", id: "", shareResource: null }));
    navigate("/save-page");
  };
  const redirectToDetail = (resourceid, status) => {
    navigate("/full-description/" + resourceid + "/yes");
    if (status) {
      localStorage.setItem("scrollvaluereview", true);
    }
    setTimeout(() => {
      localStorage.removeItem("scrollvaluereview");
    }, 8000);
  };
  //api call to delete saved resource
  const deleteSavedResource = () => {
    if (parseInt(userId) !== userData.userAuth.id) {
      toastifyMessage("You're not authorised to delete resource", "error");
    } else {
      setLoading(true);
      let data = {
        id: resourceid,
      };
      makePostRequest(URLS.deleteSavedResources, null, data, null)
        .then((res) => {
          if (res.code === 200) {
            setLoading(false);
            toastifyMessage(res.message, "success");
            setCurrentPage(1);
            getSavedResourcesList();
            setOpen(false);
          } else {
            setLoading(false);
            toastifyMessage(res.message, "error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  //get grade level data
  const getGradeLevelData = () => {
    makeGetRequest(URLS.usergetallgradelevel, null, null, null)
      .then((res) => {
        if (res.code === 200) {
          setGradeLevelData(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // filter records based on search keywords
  const searchKeywords = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    getSavedResourcesList();
  };

  // this function is use for viewable for friend
  const shareToggleHandler = async (id, status) => {
    setLoading(true);
    let payload = {
      id: id,
      viewable_by_my_friends: status,
      shared_id: [],
    };
    let response = await makePostRequestForAll(
      URLS.shareWithFriendsToggle,
      payload
    );
    setLoading(false);
    if (response.code === 200) {
      toastifyMessage(response.message, "success");
      getSavedResourcesList();
    } else {
      toastifyMessage("Try Again", "error");
    }
  };

  // Multiselect input  handle
  const handleGrade = (selectedList) => {
    let arr = [];
    selectedList?.forEach((val, i) => {
      arr.push(val.id);
    });
    setGradeRange(arr);
    setGradeData(selectedList)
  };

  const handleRemoveGrade = (selectedList) => {
    let arr = [];
    selectedList?.forEach((val, i) => {
      arr.push(val.id);
    });
    setGradeRange(arr);
  };
  const handleOrderSelection = (selectedList) => {
    selectedList?.forEach((val, i) => {
      setOrderBy(val.value);
    });
  };

  const handleRemoveOredrSelection = (selectedList) => {
    selectedList?.forEach((val, i) => {
      setOrderBy(val.value);
    });
  };
  const handleChildSelection = (selectedList) => {
    let arr = [];
    selectedList?.forEach((val, i) => {
      arr.push(val.id);
    });
    setSelectedChild(arr);
    setSelectedChildData(selectedList)
  };

  const handleRemoveChildSelection = (selectedList) => {
    let arr = [];
    selectedList?.forEach((val, i) => {
      arr.push(val.id);
    });
    setSelectedChild(arr);
  };
  const handeSelectedKey = (selectedList) => {
    selectedList?.forEach((val, i) => {
      setSelectedKey(val.value);
    });
  };
  return (
    <>
     <Head title="Save "
        content="Each child has their own portfolio sorted by grade.Store pictures, videos, work samples and memories.Record the educational resourced used for each child for sharing or reporting and Rate and Review your saved resources to share with your Friends and Groups"
        name="Save resource" ></Head>
      {savePopupStatus.selectedResource && savePopupStatus.visible && (
        <Saveresourcespopup
          data={savePopupStatus}
          handleResourceSaveClose={() =>
            setSavePopupStatus({ visible: false, selectedResource: null })
          }
        />
      )}
      <div className="col-lg-10 col-md-12 col-sm-12">
        <div className="searchresource-div11">
          <div className="search-exp-section-div1">
            <h3 className="refine-s-r">Refine Search Results</h3>
            <div className="saveResourceListgradeRange">
              <div className="form-select">
                <Multiselect
                  options={CategoryOptions}
                  onSelect={handleCategory}
                  displayValue="name"
                  placeholder="Category"
                  showArrow={true}
                  showCheckbox
                  selectedValues={categoryType ? [CategoryOptions.find(item => item.value === categoryType)] : []}
                /></div></div>
            <div className={`saveResourceListgradeRange ${(gradeLevelData && gradeData.length > 2) ? "gradeLevelDataSelect" : "gradeLevelDataMultipleSelect"}`}>
              <div className="form-select">
                <Multiselect
                  options={gradeLevelData}
                  onSelect={handleGrade}
                  onRemove={handleRemoveGrade}
                  selectedValues={gradeData}
                  showCheckbox
                  displayValue="name"
                  placeholder="Grade Range"
                  showArrow={true}
                  closeIcon="cancel"
                />
              </div>
            </div>
            <div className="saveResourceListgradeRange">
              <div className="form-select">
                <Multiselect
                  options={saveForOption}
                  onSelect={handleSaveFor}
                  displayValue="name"
                  placeholder="Saved For"
                  showArrow={true}
                  closeOnSelect={true}
                  showCheckbox
                  selectedValues={SaveFor ? [saveForOption.find(item => item.value === SaveFor)] : []}
                /></div>
            </div>
            <div className="input-btn-contain">
              <div className="input-btn-contains">
                <input
                  type="text"
                  className="input-btn-icon-icon"
                  placeholder="Title Search"
                  onChange={(e) => setKeywords(e.target.value)}
                  value={Keywords}
                  name="keywords"
                />
              </div>{" "}
            </div>
            <form
              className="savebtnsearch"
              onSubmit={(e) => {
                searchKeywords(e);
              }}
            >
              <button> <i className="fa fa-search iconsearch" /></button>
            </form>
            <button
              className="btn btn-md float-end addresource mx-1"
              onClick={() => {
                resetFunction();
              }}
            >
              {/*  Reset Resource{" "} */}
              <img src={ResetResource} alt="Reset Resource " width="20" className="resourcehoverImg" />
              <img src={ResetResourceWhite} alt="Reset Resource " width="20" className="resourceNormalImg" />
            </button>
          </div>
        </div>
        <div className="tobarbackcontee">
          <div className="gobackcontainer">
            <div className="col-6"></div>
          </div>
        </div>
        <div className="bookmoniterdiv saveResourceList">
          <div className="row">
            <div className="col-lg-6">
              <h2 className="">
                My Saved Resources{" "}
                <button
                  className="btn btn-md float-end addresource mx-1"
                  onClick={() => {
                    redirectToAdd();
                  }}
                >
                  <i className="fa fa-plus"></i>&nbsp; Add Resource{" "}
                </button>

              </h2>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <Multiselect className="form-select w-100 float-start saveResourceSelectss"
                    options={orderByOptions}
                    onSelect={handleOrderSelection}
                    onRemove={handleRemoveOredrSelection}
                    displayValue="name"
                    placeholder="Order By"
                    showArrow={true}
                    closeOnSelect={true}
                    showCheckbox
                    selectedValues={orderBy ? [orderByOptions.find(item => item.value === orderBy)] : []}
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="saveResourceChildSelect w-100">
                    <div className="form-select w-100">
                      <Multiselect
                        className="saveResourceStudent"
                        options={childData}
                        onSelect={handleChildSelection}
                        onRemove={handleRemoveChildSelection}
                        selectedValues={selectedChildData}
                        showCheckbox
                        displayValue="name"
                        placeholder="Select Student"
                        showArrow={true}
                        closeOnSelect={true}
                      />
                    </div>
                    {/* <div className="saveresource-list">
                      <i class="fa fa-caret-down" ></i>
                    </div> */}
                  </div>

                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <Multiselect
                    className="form-select w-100 float-start saveResourceSelectss"
                    options={selectKeyOptions}
                    onSelect={handeSelectedKey}
                    displayValue="name"
                    placeholder="User Type"
                    showArrow={true}
                    closeOnSelect={true}
                    showCheckbox
                    selectedValues={selectedKey ? [selectKeyOptions.find(item => item.value === selectedKey)] : []}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="clearnone"></div>
          <div className="table table-responsive border-top">
            <div className="bookmoniterdivbox">
              {loading ? (
                <ul>
                  <li>
                    <div className="sharepopupOuter backgroundOpacity1">
                      <FourDotsLoader />
                    </div>
                  </li>
                </ul>
              ) :
                <>
                  <ul>
                    {savedResourceData && savedResourceData?.length > 0 ? (
                      savedResourceData.map((val, i) => {
                        return (
                          <>
                            <li key={i + 1}>
                              <div className="sprash-name paddingRight10">
                                <div
                                  className="sprash-name-lt"
                                  onClick={() => {
                                    redirectToDetail(val.id, false);
                                  }}
                                >
                                  {
                                    val?.resource_thumbnail_image ?
                                      <div className="resource-img" onClick={() =>
                                        redirectToDetail(val.id, false)
                                      }><img src={imageUrl + val?.resource_thumbnail_image} className="resource-image3" alt="matched resource" />
                                      </div> : <>
                                        {
                                          val?.imageLink && !val?.imageLink.includes("booksamillion.com") ?
                                            <div className="resource-img" onClick={() =>
                                              redirectToDetail(val.id, false)
                                            }><img src={val?.imageLink} className="resource-image3" alt="matched resource" />
                                            </div> :
                                            <>
                                              {val?.resource_type === 1 && (
                                                <div className="img-divsr1">
                                                  <img src={img1} alt="Organization" />
                                                </div>
                                              )}
                                              {val?.resource_type === 3 && (
                                                <div className="img-divsr3">
                                                  <img src={ph22} alt="Experiences" />
                                                </div>
                                              )}
                                              {val?.resource_type === 2 && (
                                                <div className="img-divsr2">
                                                  <img src={srimg4} alt="Curriculum Content" />
                                                </div>
                                              )}
                                              {val?.resource_type === 4 && (
                                                <div className="img-divsr4">
                                                  <img src={srimg3} alt="Student Support" />
                                                </div>
                                              )}
                                              {val?.resource_type === 5 && (
                                                <div className="img-divsr5">
                                                  <img
                                                    src={vector3}
                                                    className="curriculum-image33"
                                                    alt="Teacher Parent Support"
                                                  />
                                                </div>
                                              )}
                                              {val?.resource_type === 6 && (
                                                <div className="img-divsr6">
                                                  <img src={srimg5} alt="funding" />
                                                </div>
                                              )}
                                              {val?.resource_type === 7 && (
                                                <div className="img-divsr7">
                                                  <img src={ph5} alt="Facility" />
                                                </div>
                                              )}
                                            </>
                                        }
                                      </>
                                  }
                                </div>
                                <div className="sprash-name-rts">
                                  <h3 className="text-capitalize cursor-pointer" onClick={() => redirectToDetail(val.id, false)}>
                                    {val.resource_title ? val.resource_title : ""}
                                  </h3>
                                  <div className="statusPublicUnpublish">
                                    <p>
                                      {val.status === "under_review"
                                        ? "Under Review"
                                        : val.status === "publish"
                                          ? "Publish"
                                          : val.status === "unpublish"
                                            ? "Unpublish"
                                            : ""}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="sprash-name-rt ">
                                <div className="sprash-name-box paddingRight10">
                                  <h4> Funding Available</h4>
                                  <p>
                                    {" "}
                                    {val.public_funding === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>

                                <div className="sprash-name-box myrating paddingRight10">
                                  <h4>
                                    {" "}
                                    My Ratings{" "}
                                    <small>
                                      <button
                                        style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                                        onClick={() => {
                                          redirectToDetail(val.id, true);
                                        }}
                                      >
                                        See Reviews
                                      </button>
                                    </small>
                                  </h4>
                                  <div className="rating addresourceratting">
                                    {renderRatingStars(val?.rating)}
                                  </div>
                                  <button
                                    className="viewrattinglist"
                                    style={{ background: 'none', border: 'none', cursor: 'pointer', textDecoration: 'underline' }}
                                    onClick={() => {
                                      showRatingData(val);
                                      setVisible(true);
                                      if (document.body.classList.contains("save-resource-list")) {
                                        document.body.classList.add("removescroll");
                                      } else {
                                        document.body.classList.remove("removescroll");
                                      }
                                    }}
                                  >
                                    Add/Revise
                                  </button>
                                </div>
                                <div className="sprash-name-box">
                                  <h4> Grade</h4>
                                  <div className="minGradeHeight">
                                    {val.tbl_grade_resource_mappings &&
                                      val.tbl_grade_resource_mappings.length >
                                      0 &&
                                      val.tbl_grade_resource_mappings.map(
                                        (temp, counter) => {
                                          return (
                                            <p key={counter}>{temp?.tbl_grade_level?.name}</p>
                                          );
                                        }
                                      )}
                                  </div>
                                </div>
                                <div className="sprash-name-box sharingAction">
                                  <h4> Viewable </h4>
                                  <div className="sharingActionBox">
                                    {val.viewable_by_my_friends === "yes" ? (
                                      <div className="viewableFriend pt-1 float-start">
                                        <label
                                          className="switch"
                                          htmlFor={`viewable-friend${i + 1}`}
                                        >
                                          <input
                                            type="checkbox"
                                            id={`viewable-friend${i + 1}`}
                                            onClick={() => {
                                              shareToggleHandler(val.id, "no");
                                            }}
                                            defaultChecked
                                          />
                                          <span className="slider round"></span>
                                        </label>
                                      </div>
                                    ) : (
                                      <div className="viewableFriend pt-2 float-start">
                                        <label
                                          className="switch"
                                          htmlFor={`viewable-friend${i + 1}`}
                                        >
                                          <input
                                            type="checkbox"
                                            id={`viewable-friend${i + 1}`}
                                            onClick={() => {
                                              shareToggleHandler(val.id, "yes");
                                            }}
                                          />
                                          <span className="slider round"></span>
                                        </label>
                                      </div>
                                    )}
                                    {(showButton !== "other" && val?.is_edit) && (
                                      <>
                                        <button
                                          className="btn btn-md p-0"
                                          onClick={() => {
                                            redirectToEdit(val.id, val.user_id, val?.resource_mode);
                                          }}
                                        >
                                          {" "}
                                          <i className="fa fa-edit"></i>{" "}
                                        </button>
                                        <button
                                          className="btn btn-md p-0"
                                          onClick={() => {
                                            handleOpen(val.id, val.user_id);
                                          }}
                                        >
                                          <i className="fa fa-trash-o"></i>{" "}
                                        </button>
                                        <button
                                          className="btn btn-md p-0"
                                          onClick={() => setSavePopupStatus({
                                            visible: true,
                                            selectedResource: val,
                                          })}
                                        >
                                          <i class="fa fa-save"></i>
                                        </button>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </li>
                          </>
                        );
                      })
                    ) : (
                      <>
                        <li className="nodataFoundinfo">
                          <strong>To add a resource please select add resource above.</strong>
                          <img src={savepages} className="save img-fluid" style={{ width: "100%" }} alt="resource" />
                        </li>
                      </>
                    )}
                  </ul>
                  { hasMore !== 0 && spinStatus && (
                    <div className="text-center w-100">
                      <button
                        className="border-0 btn-md btn loadmorebatn"
                        onClick={() => {setCurrentPage((prevPage) => prevPage + 1);
                          if(spinStatus){
                          setLoading(true)
                          }else{
                            setLoading(false)
                          }
                        }
                        }
                      >
                        Load More
                      </button>
                    </div>
                  )}
                </>
              }
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
            style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
          >
            Are you sure you want to delete this resource?
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 3 }}
            className="d-flex justify-content-center gap-4"
          >
            <button className="child-delete" onClick={deleteSavedResource}>
              Yes
            </button>{" "}
            <button className="child-cancel" onClick={handleClose}>
              No
            </button>
          </Typography>
        </Box>
      </Modal>
      {visible && (
        <RatingPopup
          rating={ratingData}
          setVisible={setVisible}
          userData={userData.userAuth}
          funCall={getSavedResourcesList}
        />
      )}
    </>
  );
}
export default SaveResourceList;
