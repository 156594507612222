import { useNavigate } from "react-router-dom";
import "./threedotspopup.css";
import React from "react";
// /*
//      * Notification function is used to do view all the notification
//      *
//      * @author Upendra Kumar <upendra.webappmate@gmail.com>
//      * created on: 07-07-2023
//      * /
function Threedotspopup(props) {
  const navigate = useNavigate()
 return (
    <>
      <div className="dotspop" ref={props.forwardedref}>
        <a
          href="#!"
          onClick={props.val}
          className="border-bottom"
        >
          Share permissions
        </a>
        <a href="#!" referrerPolicy="no-referrer" onClick={props.remove} className="border-bottom">
          Remove permissions
        </a>
        <a referrerPolicy="no-referrer" style={{cursor : "pointer"}} onClick={() => navigate(`/user-profile/${props?.id}`)}>
          View Profile
        </a>
      </div>
    </>
  );
}
export default Threedotspopup;
