import React, { useState } from "react";
import "./purchasepagecard.css";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { makePostRequest } from "../../services/api";
import { URLS } from "../../constants";
import { decodeToken, toastifyMessage, style } from "../../utilities/CustomFunctions";
import moment from "moment";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import { Box, Modal, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { decrementByAmount, incrementByAmount } from "../../store/reducers/cartReducer";
import EmptyCartImg from "../../assets/Images/cartisempty.png";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import Head from "../Layout.WebAppMate/head/Head";
function Purchasepagecard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [otherData, setOtherData] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [stateData, setstateData] = useState()
  const [removeData, setRemoveData] = useState({})
  const [gradeLevelData, setGradeLevelData] = useState([]);
  const [pennle, setPennle] = useState(false)
  const [signUpstate, setSignUpState] = useState([])
  const [, setSignUpData] = useState([])
  const params = useParams();
  const payLoad = decodeToken().userAuth?.timezone;
  
  // get user child child
  const getUserChilds = () => {
    let data = {
      resource_id: "",
    };
    makePostRequest(URLS.getChildList, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          let data = res?.data
          let showData = data?.filter(v => v?.childOwner === 1)
          let options = [];
          showData?.length > 0 &&
            showData?.forEach((val, i) => {
              options.push({ value: val.id, label: val.firstName + ' ' + val.lastName });
            });
          setGradeLevelData(options);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false)
      });
  };

  useEffect(() => {
    if (params.type) {
      params.type === "yes" ? setPennle(true) : setPennle(false)
    }
  }, [params.type])

  const clearCart = () => {
    if (pennle) {
      localStorage.removeItem("signupcard");
      localStorage.removeItem("signupData");
      localStorage.removeItem("signstate");
      setSignUpData([]);
      setSignUpState([])
      if (localStorage.getItem("cart")) {
        dispatch(incrementByAmount(JSON.parse(localStorage.getItem("cart"))?.scheduleData?.length));
      }
    } else {
      localStorage.removeItem("cart");
      localStorage.removeItem("otherData");
      localStorage.removeItem("state");
      setOtherData([]);
      setstateData(null)
      if (localStorage.getItem("signupcard")) {
        dispatch(incrementByAmount(JSON.parse(localStorage.getItem("signupcard"))?.scheduleData?.length));
      }
    }
    setOpen2(false)
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    if (pennle) {
      if (localStorage.getItem("signupData")) {
        setSignUpData(JSON.parse(localStorage.getItem("signupData")));
      }
      if (localStorage.getItem("signstate") !== "undefined" && localStorage.getItem("signstate")) {
        setSignUpState(JSON.parse(localStorage.getItem("signstate")));
      }
    } else {
      if (localStorage.getItem("otherData")) {
        setOtherData(JSON.parse(localStorage.getItem("otherData")));
      }
      if (localStorage.getItem("state") !== "undefined" && localStorage.getItem("state")) {
        setstateData(JSON.parse(localStorage.getItem("state")));
      }
    }

  }, [pennle]);

  const handleCardApi = () => {
    setIsLoading(true)
    let payload = {
      scheduleData: pennle ? signUpstate?.postedData?.scheduleData : stateData?.postedData?.scheduleData,
      save_type: pennle ? "signup" : "paynow",
      type: pennle ? "signup" : "paynow"
    };
    makePostRequest(URLS.saveMS, null, payload, null)
      .then((res) => {
        if (res?.data?.save_type === "free") {
          localStorage.setItem("unique_id", res?.data?.payment_intent);
          localStorage.setItem("zeroPrice", "yes");
          navigate("/success")
        }
        if (pennle) {
          localStorage.removeItem("signupcard");
          localStorage.removeItem("signupData");
          localStorage.removeItem("signstate");
          setSignUpData([]);
          setSignUpState([])
          if (localStorage.getItem("cart")) {
            dispatch(incrementByAmount(JSON.parse(localStorage.getItem("cart"))?.scheduleData?.length));
          }
          toastifyMessage(res.message, "success")
        } else {
          localStorage.setItem("unique_id", res?.data?.payment_intent);
          localStorage.setItem("zeroPrice", "no");
          window.open(res?.data?.payment_url, "_Self");
        }
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        toastifyMessage(error?.message, "error");
        console.log(error);
      });
  };

  useEffect(() => {
    getUserChilds()
    document.body.classList.add("purchasepage");
    return () => {
      document.body.classList.remove("purchasepage");
    };
  }, []);

  // this function remove data from the card
  const handleRemove = () => {
    const { scheduleId, child_ids, total, unq_key } = removeData
    if (localStorage.getItem("cart")) {
      let newData = JSON.parse(localStorage.getItem("cart"))
      let remove = newData?.scheduleData?.find(v => parseInt(v?.scheduleId) === parseInt(scheduleId) && v?.child_ids?.toString() === child_ids?.toString() && v?.unq_key === unq_key)
      let remove2 = newData?.scheduleData.filter(v => v !== remove)
      var aaa = { ...newData, scheduleData: remove2 }
      localStorage.setItem("cart", JSON.stringify(aaa))
      dispatch(decrementByAmount(1))
      setstateData({ ...stateData, postedData: { type: "paynow", save_type: "confirm", scheduleData: aaa?.scheduleData }, total_amount: stateData?.total_amount - total })
      localStorage.setItem("state", JSON.stringify({ ...stateData, postedData: { save_type: "confirm", scheduleData: aaa?.scheduleData }, total_amount: stateData?.total_amount - total }))
    }
    if (localStorage.getItem("otherData")) {
      let newData = JSON.parse(localStorage.getItem("otherData"))
      let remove = newData?.filter(v => parseInt(v?.scheduleId) === parseInt(scheduleId) && v?.child_ids?.toString() === child_ids?.toString())
      var remove2 = newData.filter(v => v !== remove)
      localStorage.setItem("otherData", JSON.stringify(remove2))
      setOtherData(remove2);
    }
    if (remove2?.length > 1) {
      setIsLoading(true)
      const body = {
        scheduleData: aaa?.scheduleData,
        save_type: "confirm",
        // type: "paynow"
      }
      makePostRequest(URLS.saveMS, null, body, null)
        .then((res) => {
          console.log("res:::", res)
          if (res.code === 200) {
            setstateData(res?.data)
            localStorage.setItem("cart", JSON.stringify(res?.data?.postedData))
            localStorage.setItem("state", JSON.stringify(res?.data))
          } else {
            setstateData({})
            localStorage.setItem("state", "")
          }
          toastifyMessage("Session removed successfully!", "success")
          if (localStorage.getItem("cart") && localStorage.getItem("signupcard")) {
            dispatch(incrementByAmount(JSON.parse(localStorage.getItem("cart"))?.scheduleData?.length + JSON.parse(localStorage.getItem("signupcard"))?.scheduleData?.length));
          } else if (localStorage.getItem("cart")) {
            dispatch(incrementByAmount(JSON.parse(localStorage.getItem("cart"))?.scheduleData?.length));
          } else if (localStorage.getItem("signupcard")) {
            dispatch(incrementByAmount(JSON.parse(localStorage.getItem("signupcard"))?.scheduleData?.length));
          }
          setIsLoading(false)
          setOpen(false)
        }).then(e => {
          setIsLoading(false)
          setOpen(false)
        })
    } else {
      toastifyMessage("Session removed successfully!", "success")
      setOpen(false)
    }
  }

  // this function remove data from the card
  const handleSingleDelete = (s_id, index) => {
    let dummy = stateData?.postedData?.scheduleData
    let dummy1 = dummy.map(v => parseInt(v?.scheduleId) === parseInt(s_id) ? { ...v, session_date_time: v?.session_date_time?.filter((k, i) => i !== index) } : v)
    let dummy3 = dummy1.some(l => l.session_date_time?.length === 0)
    if (dummy3) {
      dispatch(decrementByAmount(1))
    }
    let dummy2 = dummy1.filter(l => l.session_date_time?.length > 0)
    stateData.postedData.scheduleData = dummy2
    setstateData({ ...stateData, postedData: { ...stateData.postedData, scheduleData: dummy2 } })
    localStorage.setItem("cart", JSON.stringify({ ...stateData.postedData, scheduleData: dummy2 }))
    localStorage.setItem("state", JSON.stringify({ ...stateData, postedData: { ...stateData.postedData, scheduleData: dummy2 } }))
    if (dummy2?.length > 0) {
      setIsLoading(true)
      const body = {
        scheduleData: dummy2,
        save_type: "confirm",
      }
      makePostRequest(URLS.saveMS, null, body, null)
        .then((res) => {
          setstateData(res?.data)
          localStorage.setItem("state", JSON.stringify(res?.data))
          toastifyMessage("Session removed successfully!", "success")
          setIsLoading(false)
          // setOpen(false)
        }).then(e => {
          setIsLoading(false)
          // setOpen(false)
          console.log("e", e)
        })
    } else {
      toastifyMessage("Session removed successfully!", "success")
    }
  }

  // this function remove data from the card
  const handleSingleDelete2 = (s_id, index) => {
    let dummy = signUpstate?.postedData?.scheduleData
    let dummy1 = dummy.map(v => parseInt(v?.scheduleId) === parseInt(s_id) ? { ...v, session_date_time: v?.session_date_time?.filter((k, i) => i !== index) } : v)
    let dummy3 = dummy1.some(l => l.session_date_time?.length === 0)
    if (dummy3) {
      dispatch(decrementByAmount(1))
    }
    let dummy2 = dummy1.filter(l => l.session_date_time?.length > 0)
    signUpstate.postedData.scheduleData = dummy2
    setSignUpState({ ...signUpstate, postedData: { ...signUpstate.postedData, scheduleData: dummy2 } })
    localStorage.setItem("signupcard", JSON.stringify({ ...signUpstate.postedData, scheduleData: dummy2 }))
    localStorage.setItem("signstate", JSON.stringify({ ...signUpstate, postedData: { ...signUpstate.postedData, scheduleData: dummy2 } }))
    if (dummy2?.length > 0) {
      setIsLoading(true)
      const body = {
        scheduleData: dummy2,
        save_type: "paynow",
      }
      makePostRequest(URLS.saveMS, null, body, null)
        .then((res) => {
          setstateData(res?.data)
          localStorage.setItem("state", JSON.stringify(res?.data))
          toastifyMessage("Session removed successfully!", "success")
          setIsLoading(false)
          // setOpen(false)
        }).then(e => {
          setIsLoading(false)
          // setOpen(false)
          console.log("e", e)
        })
    } else {
      toastifyMessage("Session removed successfully!", "success")
    }
  }
  return (
    <>
      <Head title="Selected Session"
        content="MatchED Selected Session: Empowering Parents and Educators with a Faith-Embracing Matching Platform. Harness cutting-edge technology to simplify education choices and create meaningful connections."
        name="Selected Session" >
          </Head>        {isLoading ? <ul>
        <li>
          <div className="sharepopupOuter backgroundOpacity1"> <FourDotsLoader /></div>
        </li>
      </ul> : <div className="col-lg-10">
        {stateData?.postedData?.scheduleData?.length ? (
          <div className="row purchase-card-content">
            <div className="col-lg-8">
              <div className="table-div">
                <table>
                  <tr className="cartbgcolor">

                    <th colSpan="2">
                      <div className="firstcol" > </div>
                      <div className="pati">
                        <h1>Details</h1>
                      </div>
                    </th>
                    <th>Price</th>
                    <th>Total</th>
                    <th>Delete</th>
                  </tr>
                  {stateData?.postedData?.scheduleData?.map((v, i) => (
                    <>
                      <tr className="border-0 vertalign">
                        <td colSpan="2" className="marge-td">
                          <div className="tray">
                            <div style={{ width: "50px" }}>
                              <img
                                src={
                                  otherData?.find(
                                    (p) => p?.scheduleId === v?.scheduleId
                                  )?.image
                                }
                                alt="user"
                              ></img>
                              <br></br>
                            </div>
                            <div className="all-set">
                              <div className="row">
                                <div className="col-sm-6 col-md-6 col-lg-6 ">
                                  <div style={{ textAlign: "left" }}>
                                    <h3>
                                      {
                                        otherData?.find(
                                          (p) => p?.scheduleId === v?.scheduleId
                                        )?.mentor
                                      }
                                    </h3>
                                    <h3>
                                      {
                                        otherData?.find(
                                          (p) => p?.scheduleId === v?.scheduleId
                                        )?.resource
                                      }
                                    </h3>
                                  </div>
                                </div>
                                <div className=" col-sm-6 col-md-6 col-lg-6">
                                  <div style={{ textAlign: "left" }}>
                                    <h3>
                                      {
                                        otherData?.find(
                                          (p) => p?.scheduleId === v?.scheduleId
                                        )?.duration
                                      }{" "}
                                      mins
                                    </h3>
                                    <h3>
                                      {gradeLevelData?.filter(t => v?.child_ids?.includes(t.value)).map(k => k.label)?.join(", ")}
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>

                        <td>
                          $
                          {parseInt(
                            otherData?.find((p) => p?.scheduleId === v?.scheduleId)
                              ?.price
                          )?.toFixed(2)}
                        </td>

                        <td>
                          $
                          {(
                            parseInt(
                              otherData?.find(
                                (p) => p?.scheduleId === v?.scheduleId
                              )?.price
                            ) * v?.session_date_time?.length * v?.child_ids?.length
                          )?.toFixed(2)}
                          <div className="icons0">

                          </div>
                        </td>
                        <td className="delete-th-butt">
                          <span className="removetimes c-pointer" onClick={() => {
                            setRemoveData({
                              scheduleId: v?.scheduleId, child_ids: v?.child_ids, unq_key: v?.unq_key, total: parseInt(
                                otherData?.find(
                                  (p) => p?.scheduleId === v?.scheduleId
                                )?.price
                              ) * v?.session_date_time?.length?.toFixed(2)
                            }); setOpen(true);
                          }}>
                            <i className="fa fa-trash"></i>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4" style={{ height: "10px", paddingLeft: "0px 15px" }}>
                          <div className="selected-time">
                            <Accordion>
                              <AccordionItem >
                                {({ open }) => (
                                  <>

                                    <AccordionHeader className="accord_title"  >
                                      <button
                                        className="conti-btn2"
                                      >View Selected Date(s)/Time(s)</button>
                                    </AccordionHeader>
                                    <AccordionBody>
                                      {v?.session_date_time?.map((k, i) => (
                                        <div className="date-time my-3">
                                          <h2>{moment(k?.session_date).format("MM-DD-YYYY") ?? moment(k?.schedule_date).format("MM-DD-YYYY")}</h2>
                                          <span>{moment(
                                            k?.session_time,
                                            "h:mm:ss A"
                                          ).format("hh:mm A") ?? moment(
                                            k?.start_time,
                                            "h:mm:ss A"
                                          ).format("hh:mm A")} </span>&nbsp;{"-"}&nbsp;
                                          <span className="ms-0 mr-10">{moment(
                                            k?.session_end_time,
                                            "h:mm:ss A"
                                          ).format("hh:mm A") ?? moment(
                                            k?.end_time,
                                            "h:mm:ss A"
                                          ).format("hh:mm A")} </span>&nbsp; ({payLoad}) &nbsp;<i className="fa fa-trash c-pointer" onClick={() => { pennle ? handleSingleDelete2(v?.scheduleId, i) : handleSingleDelete(v?.scheduleId, i) }}></i>
                                        </div>
                                      ))}

                                    </AccordionBody>
                                  </>
                                )}
                              </AccordionItem>
                            </Accordion>
                          </div>
                        </td>
                      </tr>

                    </>
                  ))}
                </table>
              </div>
              <button className="conti-btn" onClick={() => navigate(`/booka-session-manualpicking/${localStorage.getItem("sessionId")}/no`)}>
                Book Another Session
              </button>
              <button
                className="clear-shopping"
                onClick={() => { setOpen2(true) }}
              >Clear Cart</button>
            </div>
            <div className="col-lg-4">
              <div className="card-right-container">
                <h3 className="registered">
                  {" "}
                  <span>
                    <i className="fa fa-credit-card"></i>
                  </span>
                  Payment Summary
                </h3>
                <div className="subtitle-main">
                  <div className="titlesub">
                    <label>Sub Total</label>
                    <span>${stateData?.total_amount?.toFixed(2)}</span>
                  </div>
                  <div className="tax">
                    <label>Tax</label>
                    <span>${stateData?.tax_amount?.toFixed(2)}</span>
                  </div>
                </div>
              </div>
              <div className="totaal">
                <h3>
                  <span className="totle">Grand Total </span> $
                  {(stateData?.total_amount + stateData?.tax_amount)?.toFixed(2)}
                </h3>
              </div>
              {console.log("payment data", stateData)}
              {
                stateData?.total_amount <= 0 ? <div className="confirm-order-bttns" onClick={handleCardApi}>
                  <button className="paynows">Continue</button>
                </div> : <div className="confirm-order-bttns" onClick={handleCardApi}>
                  <button className="paynows">Pay Now</button>
                </div>
              }
            </div>
          </div>
        ) : (
          <div
            // style={{ height: "80vh", display: "grid", placeItems: "center" }}
            className="empty-div-cart"
          >
            <div className="emptyCart">
              <img className="img-fluid" src={EmptyCartImg} alt="data not found" />
              <h1 >No Items Found in Cart!</h1>
              <button className="conti-btn" onClick={() => navigate(`/booka-session-manualpicking/${localStorage.getItem("sessionId")}/no`)}>
                Book More Sessions
              </button>
            </div>
          </div>
        )}
      </div>}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
            style={{ color: "#2D3134", fontSize: "20px", lineHeight: "36px" }}
          >
            Are you sure you want to remove this schedule?
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 3 }}
            className="d-flex justify-content-center gap-4"
          >
            <button className="child-save" type="button" onClick={() => { handleRemove() }}>
              Yes
            </button>{" "}
            <button className="child-delete" onClick={() => setOpen(false)}>
              No
            </button>{" "}

          </Typography>
        </Box>
      </Modal>
      <Modal
        open={open2}
        onClose={() => setOpen2(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
            style={{ color: "#2D3134", fontSize: "20px", lineHeight: "36px" }}
          >
            Are you sure you want to clear your cart?
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 3 }}
            className="d-flex justify-content-center gap-4"
          >
            <button className="child-save" type="button" onClick={() => clearCart()}>
              Yes
            </button>{" "}
            <button className="child-delete" onClick={() => setOpen2(false)}>
              No
            </button>{" "}

          </Typography>
        </Box>
      </Modal>
    </>
  );
}
export default Purchasepagecard;
