import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import './SignIn.css';
import './Signup.css';
import { makePostRequest } from "../../services/api";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { URLS } from "../../constants";
import { toastifyMessage,Emailvalidation } from "../../services/utilities";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import Head from "../Layout.WebAppMate/head/Head";
// /*
//      * Forgetpassword  function is used to send email to user to change password
//      * @author Naresh Kumar <naresh.gulati@webappmate.com>
//      * created on: 17-04-2023
//      * /
function Forgetpassword() {
	const navigate = useNavigate();
	let [email, setemail] = useState("")
	const [erroremail, setErrorsEmail] = useState('');
	const [data, setdata] = useState([]);
	const [spin, setspin] = useState(false)

	//== function for handling error and submit ==
	const handleApi = () => {
		if (email === "") {
			setErrorsEmail('Please enter email address.');
		}
		else if (!Emailvalidation.test(email)) {
			setErrorsEmail("Please enter valid email address.")
		} else {
			//=== api for forget password ===
			let data = {
				"email": email
			}
			makePostRequest(URLS.userforgetPassword, null, data, null)
				.then((res) => {
					if (res.code === 200) {
						setspin(true)
						toastifyMessage("An email has been sent to your registered email address.", "success")
						setTimeout(() => {
							navigate("/sign-in")
						}, 2000)
					} else if (res.code === 400) {
						setspin(false)
						toastifyMessage(res.message, "error")
					} else {
						setdata(res.data)
					}
					// setData(res.data)
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}
	return (<>
	   <Head title="Forget Password"
        content="Instructions for resetting your password on MatchED member account. Follow these steps to regain access to your account if you have forgotten your password."></Head>
		<ToastContainer />
		<form className="frm">
			<div className="login">
				<div className="loginouter">
					<span className="successfulltext"> { } </span>
					{spin &&
						<div className="sharepopupOuter backgroundOpacity1">
							<FourDotsLoader />
						</div>
					}
					<h1>Forgot Password</h1>
					<div className="form-group">
						<input type="email" className="form-control" id="inputEmail" placeholder="Email" onChange={(e) => { setemail(e.target.value); setErrorsEmail('') }} />
						<span className="error"> {erroremail} </span>
					</div>
					<h5 style={{ color: "green" }}>{data.message}</h5>
					<button type="button" className="btn11 forgetbtn" onClick={handleApi}><Link to="" style={{ textDecoration: "none", color: "white" }}>Send</Link></button>
				</div>

			</div>
		</form>
	</>)
}
export default Forgetpassword;