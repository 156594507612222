import React, { useEffect } from "react";
import "../Search.WebAppMate/reportpopup.css";
import { useState } from "react";
import { decodeToken } from "../../utilities/CustomFunctions";
import { URLS } from "../../constants";
import { toastifyMessage,Emailvalidation } from "../../services/utilities";
import { makePostRequest } from "../../services/api";
// /*
//      * Profilesharepopup function to display the share popup and
//      *  share the profile with other user
//      * @author Naresh Kumar <naresh.gulati@webappmate.com>
//      * created on: 06-5-2023
//      * /
function ProfileSharepopup(props) {
  let user_id = decodeToken().userAuth.id;
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [data, setData] = useState({
    emailList: [],

  });
  const [shareuserid, setshareuserid] = useState("");

  // this function use for handle chnage
  const handleChnage = (event) => {
    const { name, value } = event.target;
    setEmail(event.target.value);
    setEmailError('');
    if (name === "emailList") {
      const recipients = event.target.value
        .split(/[,\s;]/)
        .map((email) => email.trim());

      setData({ ...data, [name]: recipients });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const handleSubmit = () => {
    if (email.emailList === "") {
      setEmailError("Please enter your email id");
    }
    else if (!Emailvalidation.test(email.emailList[0])) {
      setEmailError("Please enter valid email address.")
    }
    else {
      const datapay = {
        "email": email.emailList[0]
      };
      makePostRequest(URLS.userfindemail, null, datapay, null).then((res) => {
          console.log("res email", res);
          if (res.code === 200) {
            if (res.message === "Ok")
              toastifyMessage("Email id not found", "error");
            else {
              setshareuserid(res.data.id)
            }
          }
        })
    }
  };

  useEffect(() => {
    if (shareuserid !== "") {
      const childdata = []
      childdata.push(props.childId)
      const payload = {
        shared_child_id: childdata,
        child_parent_id: user_id,
        share_with_user_id: shareuserid,
        status: "accept",
        remove_child_id:[]

       
      };
      makePostRequest(URLS.shareChildProfilePermission, null, payload, null).then((res) => {
          console.log("res:::",res)
          if (res.code === 200) {
              toastifyMessage(res?.message, "success");
              props?.datar()
          }
        })
        .catch((error) => {
          console.log("error", error);
          props?.datar()
        });
    }
  }, [shareuserid,props,user_id]);

  useEffect(() => {
    if(data){
      setEmail(data)
    }
  }, [data])

return (
    <>
    <div className="sharepopupOuter">
        <div className="pop-up-container">
          <button className="close-btn" onClick={props.datar}>X</button>
          <h3>Share Portfolio</h3>
          <div className="email-text-option">
            <label>Friends Email(s)</label>
            <input
              type="email"
              className="form-control"
              id="inputAddress"
              name="emailList"
              onChange={handleChnage}
              value={data.emailList}
            />
            <span className="error">{emailError}</span>
          </div>
          <div className="create-link-btn">
             <button className="sendinvite" onClick={handleSubmit}>
              Share Portfolio
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default ProfileSharepopup;