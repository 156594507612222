import React from "react";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

const PdfViewer = (props) => {
  let pdfurl = props.pdfurl;
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  
 return (
    <div>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.7.107/build/pdf.worker.min.js">
        <Viewer fileUrl={pdfurl} plugins={[defaultLayoutPluginInstance]}/>
      </Worker>
    </div>
  );
};

export default PdfViewer;
