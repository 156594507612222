import { useState, useEffect, useRef } from "react";
import { makePostRequest } from "../../services/api";
import { useSelector } from "react-redux";
import Rating from "@mui/material/Rating";
import { toastifyMessage } from "../../services/utilities";
import { URLS } from "../../constants";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";

function RatingPopup(props) {
  const [parentRating, setParentRating] = useState(0);
  const [childsRating, setChildsRating] = useState([]);
  const [childRatingChekbox, setChildRatingChekbox] = useState([]);
  const [isParentChecked, setIsParentChecked] = useState(false);
  const [spin, setSpin] = useState("");
  const [value, setValue] = useState(false)
  const userStore = useSelector((store) => store.user);
  const { userChildrens } = userStore;
  const childOwner1 = userChildrens?.filter(obj => obj?.childOwner === 1);
  const commentfocus = useRef();
  const [error, setError] = useState({
    parent: "",
    comment: "",
  });
  const [userReview, setUserReview] = useState({
    parentRating: null,
    parentComment: "",
    childReviews: [],
  });

  // this function are parent rating amd comments
  const handleParentCheckbox = (e) => {
    setIsParentChecked(!isParentChecked);
    if (e.target.checked === false) {
      setParentRating(0);
    }
  };
  const handleParentComment = (e) => {
    let str = e.target.value;
    let obj = { ...userReview };
    obj.parentComment = str;
    setUserReview({ ...obj });
  }
  // these functios are child rating hanle
  const handleChildRatings = (event, newValue, index, id) => {
    if (childsRating && childsRating?.length > 0) {
      childsRating.map((val, i) => {
        if (val.child_id === id) {
          const newArray = Array.from(childsRating);
          newArray[i].rating = newValue;
          setChildsRating(newArray);
        } else {
          if (i === index) {
            const newArray = Array.from(childsRating);
            newArray[i].rating = newValue;
            newArray[i].child_id = id;
            setChildsRating(newArray);
          }
        }
      });
    } else {
      let arr = [];
      for (let i = 0; i < childOwner1?.length; i++) {
        if (i === index) {
          let childObj = { child_id: id, rating: newValue };
          arr.push(childObj);
        } else {
          let childObj = { child_id: "", rating: 0 };
          arr.push(childObj);
        }
      }
      setChildsRating(arr);
    }
  };
  const handleChildRatingsCheckboxes = (e) => {
    if (e.target.checked === true) {
      setChildRatingChekbox([...childRatingChekbox, e.target.value]);
    } else if (e.target.checked === false) {
      let freshArray = childRatingChekbox.filter(
        (val) => val !== e.target.value
      );
      setChildRatingChekbox([...freshArray]);
      childsRating?.length > 0 &&
        childsRating.map((val, i) => {
          if (val.child_id == e.target.value) {
            const newArray = Array.from(childsRating);
            newArray[i].rating = 0;
            newArray[i].child_id = "";
            setChildsRating(newArray);
          }
        });
    }
  };
  useEffect(() => {
    let arr = [];
    for (var i = 0; i < childOwner1?.length; i++) {
      let childObj = { child_id: "", rating: 0 };
      arr.push(childObj);
    }
    setChildsRating(arr);
    setValue(true)
  }, [userChildrens]);

  useEffect(() => {
    setUserReview({ parentComment: props?.rating?.reviews[0]?.message });
    setParentRating(props?.rating?.rating);
    setIsParentChecked(props?.rating?.rating && true);
    if (childsRating[0]?.child_id == "") {
      let checkboxIdArr = [];
      childOwner1?.length > 0 &&
        childOwner1?.forEach((itm, index) => {
          props?.rating?.reviews[0]?.tbl_resource_child_reviews?.length > 0 &&
            props?.rating?.reviews[0]?.tbl_resource_child_reviews?.forEach((val, i) => {
              if (itm?.id === val?.child_id) {
                const newArray = Array.from(childsRating);
                newArray[index].rating = val?.rating;
                newArray[index].child_id = val?.child_id;
                setChildsRating(newArray);
                checkboxIdArr.push(val.child_id);
              }
            })
        })
      setChildRatingChekbox(checkboxIdArr);
    }
  }, [value])

  // this function use for add rating
  const addRating = (e) => {
    if (!userReview.parentComment) {
      setError({ comment: true });
      commentfocus.current.focus();
    } else {
      setSpin(true)
      let payload = {
        resource_id: props?.rating?.id,
        user_id: props?.userData?.id,
        rating: parentRating,
        message: userReview?.parentComment,
        child_review: childsRating?.filter((item) => item.child_id != ""),
      };
      makePostRequest(URLS.addResourceReview, null, payload, null).then(
        (res) => {
          if (res.code == 201) {
            setSpin(false)
            toastifyMessage(res.message, "success");
            props.setVisible(false);
            props.funCall()
          } else {
            toastifyMessage(res.message, "error");
            props.setVisible(false);
          }
        }
      );
    }
  };
  return (
    <>
      {
        spin && <div className="sharepopupOuter backgroundOpacity1">
          <FourDotsLoader />
        </div>
      }
      <div className="sharepopupOuter">
        <div className="save-pop-up-container-inner">
          <div className="pop-up-container">
            <span
              className="close-btn"
              onClick={() => {
                props.setVisible(false);
                if (document.body.classList.contains("save-resource-list")) {
                  document.body.classList.remove("removescroll");
                }
              }}
            >
              <i className="fa fa-close closecs"></i>
            </span>
            <h3>My Ratings </h3>
            <div className="checkccc">
              <ul className="unstyled centered">
                <li>
                  <input type="checkbox"
                    id="styled-checkbox-0" checked={
                      isParentChecked ? true : false
                    }
                    value={isParentChecked} onChange={(e) => { handleParentCheckbox(e) }}></input>
                  <label className="text-capitalize px-1" htmlFor="styled-checkbox-0 d-flex ">
                    {props?.userData?.firstName}
                    <span className="rankingStar px-1 ">
                      <Rating
                        defaultChecked={true}
                        name="Rating Label0"
                        value={parentRating}
                        onChange={(
                          event,
                          newValue
                        ) => {
                          setParentRating(newValue);
                        }}
                      />
                    </span>
                  </label>
                </li>
                {childOwner1 &&
                  childOwner1?.length > 0 &&
                  childOwner1.map((val, i) => {
                    return (
                      <li key={i + 1}>
                        <input
                          id={`styled-checkbox-${i + 1
                            }`}
                          type="checkbox"
                          value={val.id}
                          checked={
                            childRatingChekbox &&
                              childRatingChekbox?.length >
                              0 &&
                              (childRatingChekbox.includes(
                                val.id
                              ) ||
                                childRatingChekbox.includes(
                                  val.id.toString()
                                ))
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleChildRatingsCheckboxes(
                              e
                            )
                          }
                        />
                        <label
                          className="text-capitalize px-1"
                          htmlFor={`styled-checkbox-${i + 1
                            }`}
                        >
                          {val.firstName}
                          <span className="rankingStar px-1 ">
                            <Rating
                              value={
                                childsRating &&
                                  childsRating?.length >
                                  0
                                  ? childsRating[i]
                                    .rating
                                  : 0
                              }
                              name={`Rating Label${i + 1
                                }`}
                              onChange={(
                                event,
                                newValue
                              ) => {
                                handleChildRatings(
                                  event,
                                  newValue,
                                  i,
                                  val.id
                                );
                              }}
                            />
                          </span>
                        </label>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <h4> <strong>Comments </strong><span className="start-color">*</span></h4>
            <textarea
              className="form-control"
              rows="4"
              onChange={handleParentComment}
              value={userReview.parentComment}
              ref={commentfocus}
            ></textarea>
            <span className="error-span">
              {error &&
                error.comment &&
                "Comment is required"}
            </span>
            <div className="col-12 pb-0 mb-1 text-center">
              <button className="public-btn-save-p" onClick={addRating} >Save </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default RatingPopup;
