import React, { useEffect, useState, useRef,forwardRef,useImperativeHandle  } from 'react'
import FourDotsLoader from '../Common.WebAppMate/FourDoted';
import { decodeToken ,toastifyMessage} from "../../utilities/CustomFunctions";
import { URLS } from '../../constants';
import { makePostRequest } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import LeaveGroupPopup from './LeaveGroupPopup';
import DeleteMemberAdmin from './DeleteMemberAdmin';
import { AdminPanelSettings } from '@mui/icons-material';

const uimage = process.env.REACT_APP_IMAGE_URL
/**
 * create components is used admin listing
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 * created on: 28-7-2023
 */

const AdminList = (props, ref) => {
    const userId = decodeToken().userAuth.id;
    const navigate = useNavigate();
    const [remainingCount, setRemainingCount] = useState("");
    const [page, setPage] = useState(1);
    const [spin, setSpin] = useState(false);
    const [groupAdmins, setGroupAdmins] = useState([]);
    const [empty,setEmpty]=useState(0);
    const [groupOpen, setGroupOpen] = useState(false);
    const [lastAdmin, setLastAdmin] = useState("");
    const [open, setOpen] = React.useState(false);
    const [openLeave,setOpenLeave]=useState(false);
    const [memberId, setMemberId] = useState("");
    const [AdminBox, setAdminBox] = React.useState(false);
    const [isAdminLogin,setIsAdminlogin]=useState();
    

useImperativeHandle(ref,()=>({
    getCheck
}))

const getCheck=(p,e) =>
    {
        setPage(p)
        setEmpty(e)
    }
    // this function use for getting group admins list
    const getAdmin = (p,e) => {
        setSpin(true)
        setEmpty(e)
        // const data = {
        //     "group_id": props.id,
        //     "page_number": p?p:page,
        //     "searchKey": props.searchKey,
        //     "item_per_page": 8
        // }
        // makePostRequest(URLS.getAdminUser, null, data, null)
        //     .then((res) => {
        //         setSpin(false)
        //         console.log("erres:::", res)
        //         if (page == 1) {
        //             setGroupAdmins(res?.data?.groupMemberList)
        //         }
        //         else {
        //             setGroupAdmins([...groupAdmins, ...res?.data?.groupMemberList]);
        //         }
        //         setRemainingCount(res?.data?.remaining_count)
        //     }).catch((error) => {
        //         console.log("error:::", error)
        //     })

            const datap = {
                "group_id": props.id,
                // "page_number": p?p:page,
                // "searchKey": props.searchKey,
                // "item_per_page": 8
            }
            console.log("Data payload",datap)
            makePostRequest(URLS.getPendingAdminUser, null, datap, null)
                .then((response) => {
                    setSpin(false)
                    console.log("response:::", response)
                      setIsAdminlogin(response.data.isAdmin)
                    
                        if(props.searchKey == "") {
                        setGroupAdmins(response?.data?.groupMemberList)
                        }
                        else
                        {
                            var filterarray=response?.data?.groupMemberList.filter(val=>val.tbl_user.firstName.toUpperCase().indexOf(props.searchKey.toUpperCase(),0)>=0)
                            setGroupAdmins(filterarray)
                        }
                    
                    
                    setRemainingCount(response?.data?.remaining_count)
                }).catch((error) => {
                    console.log("error:::", error)
                })

    }
    console.log("props", props.searchKey)
    useEffect(() => {
       getAdmin(page,1)
    }, [page])

    useEffect(() => {
        if(empty==0)
        {
        setGroupAdmins([])
        getAdmin(page,1)
        }
        else
        getAdmin(page,1)
       
   },[empty]);

    const removefromadmin =(usr_id) => {
        setSpin(true)
        const data = {
            id:usr_id,
        }
        makePostRequest(URLS.removeAdmin, null, data, null)
           .then((res) => {
                console.log("erres:::", res)
                setSpin(false)
                toastifyMessage("Admin User Removed Successfully", "success");
                getAdmin(page,1)
            }).catch((error) => {
                console.log("error:::", error)
            })
    }

    return (
        <div>
            {
                spin && <div className="sharepopupOuter backgroundOpacity1"><FourDotsLoader /></div>
            }
            <DeleteMemberAdmin
                open={open}
                handleClose={() => setOpen(false)}
                id={memberId}
                funCall={props?.id}
                adminList={getAdmin}
                AdminBox={AdminBox}
            />


            {
                groupOpen && <LeaveGroupPopup
                    open={groupOpen}
                    handleClose={() => setGroupOpen(false)}
                    value={props?.id}
                    lastAdmin={lastAdmin}
                />
            }
            <div className="member-outer-main row">
                {
                    groupAdmins?.length > 0 ? groupAdmins?.map((val, index) => {
                        return <>
                           
                            <div className="col-lg-3 col-md-3 col-sm-3 col-12 ">
                                <div className="member-inner-div">
                                    <div className="member-inner-flex">

                                        <div className="imgcirclee">
                                       
                                            <img className="img-fluid" src={uimage + val?.tbl_user?.photo} alt='group admin image' loading="lazy"/>
                                        </div>
                                    </div>
                                    {
                                        val?.tbl_user?.id == userId ? <h6 className="text-capitalize" onClick={() => navigate(`/parent-registration-form`)}>{val?.tbl_user?.firstName + " " + val?.tbl_user?.lastName}</h6> : <h6 className="text-capitalize" onClick={() => navigate(`/user-profile/${val?.user_id}`)}>{val?.tbl_user?.firstName + " " + val?.tbl_user?.lastName}</h6>
                                    }
                                    <div className="member-button text-center">
                                        {
                                            val.isAdmin == "1" ? <button className="view-profile" onClick={() => navigate(`/user-profile/${val?.user_id}`)}>View Profile  </button> : <button className="view-profile">Pending  </button>
                                        }

                                        {isAdminLogin==false?""
                                        :val?.isAdmin == "1" && val?.user_id==userId && groupAdmins.filter((val)=>{return val?.isAdmin==1}).length==1 ? <button className="remove-from-group" onClick={() => { setGroupOpen(true); setLastAdmin("lastAdmin") }}>Leave Group</button>  
                                        :val?.isAdmin == "1" && groupAdmins.filter((val)=>{return val?.isAdmin==1}).length>1 && val?.user_id==userId ?<button className="remove-from-group" onClick={() => { setMemberId(val?.id); setAdminBox(true); setOpen(true) }}>Leave Group</button> 
                                        :val?.isAdmin == "1" && groupAdmins.filter((val)=>{return val?.isAdmin==1}).length>1 && val?.user_id!=userId ?<button className="remove-from-group" onClick={() => { setMemberId(val?.id); setAdminBox(false); setOpen(true) }}>Remove as Admin</button> 
                                        :<button className="remove-from-group" onClick={()=>removefromadmin(val?.id)}>Remove</button>}

                                    </div>
                                </div>
                            </div>
                            
                        </>
                    }) : <h1 className="text-center my-5">Data Not Found</h1>
                }
                <div className="show-more">
                    {remainingCount === 1 && <p className="text-center" onClick={() => setPage(page + 1)}>Show more</p>}
                </div>
            </div>
        </div>
    )
}

export default forwardRef(AdminList);
