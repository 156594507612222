/**
 * Purchase  Resources page
 * @author Nidhi Mishra <nidhi.mishra@webappmate.com>
 * created on: 19-05-2023
 */
import { useState, useEffect } from "react";
import "./purchasepage2.css";
import { useParams, } from "react-router-dom";
import { makePostRequest } from "../../services/api";
import { URLS } from "../../constants";
import { decodeToken } from "../../utilities/CustomFunctions";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import Head from "../Layout.WebAppMate/head/Head";
function PurchaseReasources() {
  let userData = decodeToken();
  const param = useParams();
  let id = param.id;
  const [loading, setLoading] = useState(false);
  const [purchaseResDtl, setPurchaseResDtl] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  //api to show resource detail
  const purcahseResourceConfirm = () => {
    setLoading(true);
    let data = {
      user_id: userData.userAuth.id,
      resource_id: id,
    };
    makePostRequest(URLS.purchaseResourceConfirm, null, data, null)
      .then((res) => {
        if (res.code === 201) {
          setLoading(false);
          setPurchaseResDtl(res.data);
        } else if (res.code === 400) {
          setErrorMsg(res.message);
          setLoading(false);
        } else {
          setErrorMsg(res.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //api to pay now for purchasing resource
  const payNow = () => {
    setLoading(true);
    let data = {
      user_id: userData.userAuth.id,
      resource_id: id,
    };
    makePostRequest(URLS.purchaseResourcePayNow, null, data, null)
      .then((res) => {
        if (res.code === 201) {
          setLoading(false);
          localStorage.setItem("unique_id", res?.data?.payment_intent);
          localStorage.setItem("zeroPrice", "no");
          // window.open(res?.data?.payment_url, "_blank");
          window.location.href = res?.data?.payment_url;
        } else if (res.code === 400) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    purcahseResourceConfirm();
  }, []);
  return (
    <>
       <Head
        title="Purchase Resource"
        content="MatchED Purchase Resource: Empowering Parents and Educators with a Faith-Embracing Matching Platform. Harness cutting-edge technology to simplify education choices and create meaningful connections."
        name="Purchase Resource" ></Head>
      <div className="col-lg-10">

        {loading ? (
          <div className="sharepopupOuter backgroundOpacity1"> <FourDotsLoader />  </div>
        ) : (
          <>
            {errorMsg ? (
              <div className="onlinepaymentactiveUser">{errorMsg}</div>
            ) : (
              <div className="purcahse-information">
                <div className="purchase-inner-information">
                  <div className="icontext">
                    <h4 className="pinformation">Purchase Information</h4>
                  </div>
                  <div className="sprice">
                    <label className="mentor-session">Title</label>
                    <span className="pricetext">Price</span>
                  </div>
                  <hr></hr>
                  <div className="sprice">
                    <label>{purchaseResDtl?.resource_title}</label>
                    <span>
                      $
                      {purchaseResDtl
                        ? Number(purchaseResDtl.payment_amount).toFixed(2)
                        : "00.00"}
                    </span>
                  </div>
                  <br></br>
                  <div className="sprice">
                    <label>TAX AMOUNT</label>
                    <span>
                      ${" "}
                      {purchaseResDtl
                        ? Number(purchaseResDtl?.tax_amount).toFixed(2)
                        : "00.00"}
                    </span>
                  </div>
                  <br></br>
                  <hr></hr>
                  <div className="totalamount">
                    <label>Total:</label>
                    <span>
                      $
                      {purchaseResDtl
                        ? Number(purchaseResDtl.total_amount).toFixed(2)
                        : 0.0}
                    </span>
                  </div>
                </div>
                <div>
                  <button className="dinvoice1" onClick={payNow}>
                    Pay Now
                  </button>
                </div>
              </div>
            )}
          </>
        )}


      </div>
    </>
  );
}
export default PurchaseReasources;
