import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { URLS } from '../../constants';
import { makeGetRequest } from '../../services/api';
import { capitalizeWords } from '../../services/utilities';
const initialState = {
  searchChatgpt: {},
  filterOptions: {
    km:"",
    resource_type: {},
    keywords: [],
    learning_pillars: [],
    friend_for: [],
    child_for: [],
    group_for: [],
  },
  error: "",
  status: "",
  searchResults: [],
  viewableSearchResults: [],
  selectedFilterOptions: {
    selectedKeyWordsOptions: [],
    selectedLearningPillarsOptions: [],
    selectedResourceType: "",
    selectedResourceTypeFilterOptions: {
      selectedGradesOptions: [],
      selectedExperienceTypeOptions: [],
      selectedSharedByOptions: [],
      selectedTimeframeTypeOptions: [],
      selectedTypeOfContentOptions: [],
      selectedInstructionStyleOptions: [],
      selectedFacilityTypeOptions: [],
      selectedFundingTypeOptions: [],
      selectedOrganizationTypeOptions: [],
      selectedLocationTypeOptions: [],
      selectedCustomerTypeOptions: [],
      selectedTypeOfSupportOptions: [],
      selecteddDualEnrollmentResource: "",
      selectedAccreditedResource: ""
    }
  },
  selectedFilters: {
    selectedKeyWords: [],
    selectedLearningPillars: [],
    selectedChildFor: [],
    selectedFriendFor: [],
    selectedGroupFor: [],
    selectedResourceType: "",
    selectedResourceTypeFilter: {
      selectedGrades: [],
      selectedDual: "",
      selectedAccredited: "",
      selectedExperienceType: [],
      selectedTimeframe: [],
      selectedTypeOfContent: [],
      selectedInstructionStyle: [],
      selectedFacilityType: [],
      selectedFundingType: [],
      selectedOrganizationType: [],
      selectedLocationType: [],
      selectedCustomerType: [],
      selectedTypeOfSupport: [],
      selectedDistance: null,
      selectedLatLong: {}
    }
  }
};
export const getFilterOptions = createAsyncThunk('search/getAllFilterOptions', async () => {
  const response = await makeGetRequest(URLS.getAllFilters, null, {}, null);
  return response.data;
});

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    saveSearchResults(state, { payload }) {

      let itemsPerPage = Number(localStorage.getItem("ITEMS_PER_PAGE") || "12");
      if (payload) {
        let pageData = []
        if (payload.length <= 12) {
          pageData = payload;
        } else {
          pageData = payload?.slice(0, itemsPerPage);
        }
        return {
          ...state,
          searchResults: payload,
          viewableSearchResults: pageData
        }
      }
    },
    clearChatgptSearch(state, payloads) {
      let { payload } = payloads;
      if (payload) {
        return {
          ...state,
          searchChatgpt: payload
        }
      }
    },
    setSelectedResourceType(state, { payload }) {
      if (payload) {
        return {
          ...state,
          selectedFilterOptions: {
            ...state.selectedFilterOptions,
            selectedResourceType: capitalizeWords(payload?.replaceAll("_", " "))
          }
        }
      }
    },
    setSelectedResourceTypeFilterOptions(state, { payload }) {
      if (payload) {
        return {
          ...state,
          selectedFilterOptions: {
            ...state.selectedFilterOptions,
            selectedResourceTypeFilterOptions: {
              ...state.selectedFilterOptions.selectedResourceTypeFilterOptions,
              selectedGradesOptions: payload.grade || null,
              selectedExperienceTypeOptions: payload.experiences_type || null,
              selectedTimeframeTypeOptions: payload.timeframe || null,
              selectedSharedByOptions: payload.shared_by || null,
              selectedTypeOfContentOptions: payload.content_type || null,
              selectedInstructionStyleOptions: payload.instruction_style || null,
              selectedFacilityTypeOptions: payload.facility_type || null,
              selectedFundingTypeOptions: payload.funding_type || null,
              selectedOrganizationTypeOptions: payload.organization_type || null,
              selectedLocationTypeOptions: payload.location_type || null,
              selectedCustomerTypeOptions: payload.customer_type || null,
              selectedTypeOfSupportOptions: payload.type_of_support || null,
              selecteddDualEnrollmentResource: payload?.dual_enrollment_resource || null,
              selectedAccreditedResource: payload?.accredited_resource || null
            }
          }
        }
      }
    },
    setSelectedGrade(state, { payload }) {
      if (payload) {
        return {
          ...state,
          selectedFilters: {
            ...state.selectedFilters,
            selectedResourceTypeFilter: {
              ...state.selectedFilters.selectedResourceTypeFilter,
              selectedGrades: payload?.map(item => item.name) || null
            }
          }
        }
      }
    },
    setSelectedTypeOfContent(state, { payload }) {

      if (payload) {
        return {
          ...state,
          selectedFilters: {
            ...state.selectedFilters,
            selectedResourceTypeFilter: {
              ...state.selectedFilters.selectedResourceTypeFilter,
              selectedTypeOfContent: payload
            }
          }
        }
      }
    },
    setSelectedFacilityType(state, { payload }) {
      if (payload) {
        return {
          ...state,
          selectedFilters: {
            ...state.selectedFilters,
            selectedResourceTypeFilter: {
              ...state.selectedFilters.selectedResourceTypeFilter,
              selectedFacilityType: payload
            }
          }
        }
      }
    },
    setSelectedOrganizationType(state, { payload }) {
      if (payload) {
        return {
          ...state,
          selectedFilters: {
            ...state.selectedFilters,
            selectedResourceTypeFilter: {
              ...state.selectedFilters.selectedResourceTypeFilter,
              selectedOrganizationType: payload
            }
          }
        }
      }
    },
    setSelectedLocationType(state, { payload }) {
      if (payload) {
        return {
          ...state,
          selectedFilters: {
            ...state.selectedFilters,
            selectedResourceTypeFilter: {
              ...state.selectedFilters.selectedResourceTypeFilter,
              selectedLocationType: payload?.map(item => item.name) || null
            }
          }
        }
      }
    },
    setSelectedCustomerType(state, { payload }) {
      if (payload) {
        return {
          ...state,
          selectedFilters: {
            ...state.selectedFilters,
            selectedResourceTypeFilter: {
              ...state.selectedFilters.selectedResourceTypeFilter,
              selectedCustomerType: payload?.map(item => item.name) || null
            }
          }
        }
      }
    },
    setSelectedDualEnroll(state, { payload }) {
      if (payload) {
         const lastItemName = payload.length > 0 ? payload[payload.length - 1].name : null;
        return {
          ...state,
          selectedFilters: {
            ...state.selectedFilters,
            selectedResourceTypeFilter: {
              ...state.selectedFilters.selectedResourceTypeFilter,
              selectedDual: lastItemName || null
            }
          }
        }
      }
    },
    // setSelectedAccredited(state, { payload }) {
    //   if (payload) {
    //     return {
    //       ...state,
    //       selectedFilters: {
    //         ...state.selectedFilters,
    //         selectedResourceTypeFilter: {
    //           ...state.selectedFilters.selectedResourceTypeFilter,
    //           selectedAccredited: payload?.map(item => item.name) || null
    //         }
    //       }
    //     }
    //   }
    // },

    setSelectedAccredited(state, { payload }) {
      if (payload) {
        const lastItemName = payload.length > 0 ? payload[payload.length - 1].name : null;
        return {
          ...state,
          selectedFilters: {
            ...state.selectedFilters,
            selectedResourceTypeFilter: {
              ...state.selectedFilters.selectedResourceTypeFilter,
              selectedAccredited: lastItemName
            }
          }
        };
      }
    },
    setSelectedTypeOfSupport(state, { payload }) {
      if (payload) {
        return {
          ...state,
          selectedFilters: {
            ...state.selectedFilters,
            selectedResourceTypeFilter: {
              ...state.selectedFilters.selectedResourceTypeFilter,
              selectedTypeOfSupport: payload
            }
          }
        }
      }
    },
    setSelectedFundingType(state, { payload }) {
      if (payload) {
        return {
          ...state,
          selectedFilters: {
            ...state.selectedFilters,
            selectedResourceTypeFilter: {
              ...state.selectedFilters.selectedResourceTypeFilter,
              selectedFundingType: payload
            }
          }
        }
      }
    },
    setSelectedSharedBy(state, { payload }) {
      if (payload) {
        return {
          ...state,
          selectedFilters: {
            ...state.selectedFilters,
            selectedResourceTypeFilter: {
              ...state.selectedFilters.selectedResourceTypeFilter,
              selectedSharedBy: payload?.map(item => item.name) || null
            }
          }
        }
      }
    },
    setSelectedTimeframe(state, { payload }) {
      if (payload) {
        return {
          ...state,
          selectedFilters: {
            ...state.selectedFilters,
            selectedResourceTypeFilter: {
              ...state.selectedFilters.selectedResourceTypeFilter,
              selectedTimeframe: payload?.map(item => item.name) || null
            }
          }
        }
      }
    },
    setSelectedExperienceType(state, { payload }) {
      if (payload) {
        return {
          ...state,
          selectedFilters: {
            ...state.selectedFilters,
            selectedResourceTypeFilter: {
              ...state.selectedFilters.selectedResourceTypeFilter,
              selectedExperienceType: payload
            }
          }
        }
      }
    },
    setSelectedInstructionStyle(state, { payload }) {
      if (payload) {
        return {
          ...state,
          selectedFilters: {
            ...state.selectedFilters,
            selectedResourceTypeFilter: {
              ...state.selectedFilters.selectedResourceTypeFilter,
              selectedInstructionStyle: payload?.map(item => item.name) || null
            }
          }
        }
      }
    },
    setSelectedKeywords(state, { payload }) {
      if (payload) {
        return {
          ...state,
          selectedFilters: {
            ...state.selectedFilters,
            selectedKeyWords: payload?.map(item => item.name) || null
          }
        }
      }
    },
    setSelectedLearningPillars(state, { payload }) {
      if (payload) {
        return {
          ...state,
          selectedFilters: {
            ...state.selectedFilters,
            selectedLearningPillars: payload?.map(item => item.name) || null
          }
        }
      }
    },
    setSelectedChildFor(state, { payload }) {
      if (payload) {
        return {
          ...state,
          selectedFilters: {
            ...state.selectedFilters,
            selectedChildFor: payload?.map(item => item.id) || null,
          }
        }
      }
    },
    setSelectedFriendFor(state, { payload }) {
      if (payload) {
        return {
          ...state,
          selectedFilters: {
            ...state.selectedFilters,
            selectedFriendFor: payload?.map(item => item.id) || null
          }
        }
      }
    },
    setSelectedGroupFor(state, { payload }) {
      if (payload) {
        return {
          ...state,
          selectedFilters: {
            ...state.selectedFilters,
            selectedGroupFor: payload?.map(item => item.id) || null
          }
        }
      }
    },
    setViewableSearchResults(state, { payload }) {
      if (payload) {
        return {
          ...state,
          viewableSearchResults: payload
        }
      }
    },
    setSelectedLatLong(state, { payload }) {
      if (payload) {
        return {
          ...state,
          selectedFilters: {
            ...state.selectedFilters,
            selectedResourceTypeFilter: {
              ...state.selectedFilters.selectedResourceTypeFilter,
              selectedLatLong: payload
            }
          }
        }
      }
    },
    setSelectedDistance(state, { payload }) {
      if (payload) {
        return {
          ...state,
          selectedFilters: {
            ...state.selectedFilters,
            selectedResourceTypeFilter: {
              ...state.selectedFilters.selectedResourceTypeFilter,
              selectedDistance: payload
            }
          }
        }
      }
    },
  }
  ,
  extraReducers: (builder) => {
    builder
      .addCase(getFilterOptions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getFilterOptions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.filterOptions = action.payload ? action.payload : [];
      })
      .addCase(getFilterOptions.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const { setSelectedKeywords, setSelectedSharedBy, saveSearchResults, setSelectedTypeOfContent, setSelectedLatLong, setSelectedGroupFor, setSelectedChildFor, setSelectedFriendFor,
  setSelectedTimeframe, setViewableSearchResults, setSelectedLearningPillars, setSelectedFacilityType, setSelectedDistance,
  setSelectedGrade, setSelectedResourceTypeFilterOptions, setSelectedOrganizationType,
  clearChatgptSearch, setSelectedResourceType, setSelectedExperienceType, setSelectedLocationType,
  setSelectedCustomerType, setSelectedTypeOfSupport, setSelectedFundingType, setSelectedInstructionStyle, setSelectedDualEnroll, setSelectedAccredited } = searchSlice.actions;

export default searchSlice.reducer; 