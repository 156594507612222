import React from "react";
import img1 from "../../assets/Images/sr-img1.png";
import ph22 from "../../assets/Images/ph22.png";
import srimg4 from "../../assets/Images/sr-img4.png";
import srimg3 from "../../assets/Images/sr-img3.png";
import vector3 from "../../assets/Images/vector3.png";
import srimg5 from "../../assets/Images/sr-img5.png";
import ph5 from "../../assets/Images/ph5.png";
import Strengthicon from "../../assets/Images/Strengthicon.png";
import Mindicon from "../../assets/Images/Mindicon.png";
import Hearticon from "../../assets/Images/Hearticon.png";
import Sounicon from "../../assets/Images/Sounicon.png";
import { useState } from "react";
import { imageUrl, makePostRequest } from "../../services/api";
import { Link } from "react-router-dom";
import { URLS } from "../../constants";
import { toastifyMessage } from "../../utilities/CustomFunctions";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { renderRatingStars } from '../Search.WebAppMate/RenderCardInfo';
import "../Search.WebAppMate/rating.css";

import { openRouteInNewTab } from "../Search.WebAppMate/RenderCardInfo";
import Saveresourcespopup from "../Search.WebAppMate/Saveresourcespopup";
import ShareRecoursePopUp from "../Common.WebAppMate/ShareRecoursePopUp";
import { DotsMenu, DotsMenuForRemove } from "../Common.WebAppMate/Threedots";
import { style } from "../../utilities/CustomFunctions";
import { toSeoUrl } from "../../services/common.services"

// card to see child's saved resources
const Cardcomponent = (props) => {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [three, setthree] = useState(false);
  const [open, setOpen] = useState(false);
  const [savePopupStatus, setSavePopupStatus] = useState({
    visible: false,
    selectedResource: null,
  });
  const [sharePopupStatus, setSharePopupStatus] = useState({
    visible: false,
    objectID: null,
  });
  // this function use for remove resource
  const removeResource = () => {
    let data = {
      id: props.res_saved_id
    }
    makePostRequest(URLS.removeResource, null, data, null)
      .then((response) => {
        if (response.code === 200) {
          toastifyMessage("Resource removed successfully", "success");
          props?.getresources();
          props?.setPageNum(1)
        } else {
          toastifyMessage("Resource not removed ", "error");
        }
      })
    handleClose();
  }
  // show more
  const show = () => {
    if (three === false)
      setthree(true)
    else
      setthree(false)
  }

  function checkURLExists(url) {
    console.log("Url::", url)
    try {
      return fetch(url)
        .then(response => {
          console.log("response", response, response.ok)
          return true;
        })
        .catch(error => {
          return false;
        });
    } catch (error) {
      return false;
    }
  }
  return (
    <>
      {savePopupStatus.selectedResource && savePopupStatus.visible && (
        <Saveresourcespopup
          data={savePopupStatus}
          handleResourceSaveOpen={(props) =>
            setSavePopupStatus({ visible: true, selectedResource: props })
          }
          handleResourceSaveClose={() =>
            setSavePopupStatus({ visible: false, selectedResource: null })
          }
        />
      )}
      {sharePopupStatus.visible && (
        <ShareRecoursePopUp
          data={sharePopupStatus}
          handleResourceShareOpen={(props) =>
            setSharePopupStatus({ visible: true, objectID: props.resource_id }
            )}
          handleResourceShareClose={() =>
            setSharePopupStatus({ visible: false, selectedResource: null })
          }
        />
      )}
      <div className="card-container-exp">
        <div className="img-icon">
          <div>
            <Link to={`/full-description/${props.resource_id}/${toSeoUrl(props.res_title)}`} target="_blank">
              {
                props?.resource_thumbnail_image ?
                  <div className="resource-img"><img src={imageUrl + props?.resource_thumbnail_image} className="resource-image2" alt="resource" />
                  </div> : <>
                    {
                      // props?.imageLink && !props?.imageLink.includes("booksamillion.com") ?
                      //   <div className="resource-img" ><img src={props?.imageLink} className="resource-image2" alt="resource"/>
                      //   </div>

                      (props?.imageLink && checkURLExists(props?.imageLink)) && !props?.imageLink.includes("booksamillion.com") ? <div className="resource-img" onClick={() =>
                        openRouteInNewTab(`/full-description/${props.resource_id}/${toSeoUrl(props.res_title)}`)
                      }><img src={props?.imageLink} className="resource-image2" alt="matched " />
                      </div>
                        : <>
                          {
                            parseInt(props.res_type) === 1 ? (
                              <div className="img-divsr1"><img src={img1} alt="Organization" /></div>
                            ) : parseInt(props.res_type) === 2 ? (
                              <div className="img-divsr3"><img src={srimg4} alt="Curriculum Content" /></div>
                            ) : parseInt(props.res_type) === 3 ? (
                              <div className="img-divsr2"><img src={ph22} alt="Experiences" /></div>
                            ) : parseInt(props.res_type) === 4 ? (
                              <div className="img-divsr4"><img src={srimg3} alt="Student Support" /></div>
                            ) : parseInt(props.res_type) === 5 ? (
                              <div className="img-divsr5"><img src={vector3} alt="Teacher Parent Support" /></div>
                            ) : parseInt(props.res_type) === 6 ? (
                              <div className="img-divsr6"><img src={srimg5} alt="Funding" /></div>
                            ) : parseInt(props.res_type) === 7 ? (
                              <div className="img-divsr7"><img src={ph5} alt="Facility" /></div>
                            ) : null}
                        </>
                    }
                  </>
              }
            </Link>
          </div>
          <div className="profilethreedots"
            onClick={show}
          >
            {props.removeflag === "true" ? <>
              <DotsMenuForRemove
                data={props}
                handleResourceShareOpen={() =>
                  setSharePopupStatus({
                    visible: true,
                    objectID: props.resource_id
                  })
                }
                handleResourceShareClose={() =>
                  setSharePopupStatus({
                    visible: false,
                    selectedResource: null
                  })
                }
                handleResourermoveOpen={() => handleOpen(true)}
              />
            </>
              : <DotsMenu
                data={props}
                handleResourceSaveClose={() =>
                  setSavePopupStatus({
                    visible: false,
                    selectedResource: null,
                  })
                }
                handleResourceSaveOpen={(props) =>
                  setSavePopupStatus({
                    visible: true,
                    selectedResource: props,
                  })
                }
                handleResourceShareOpen={() =>
                  setSharePopupStatus({
                    visible: true,
                    objectID: props.resource_id
                  })
                }
                handleResourceShareClose={() =>
                  setSharePopupStatus({
                    visible: false,
                    selectedResource: null
                  })
                }
              />}
          </div>
        </div>
        <h3 onClick={() => openRouteInNewTab(`/full-description/${props.resource_id}/${toSeoUrl(props.res_title)}`)}> {props.res_title}</h3>
        <div className="viewableFriend pb-4">
          <div className="profile-description">
            <p onClick={() => openRouteInNewTab(`/full-description/${props.resource_id}/${toSeoUrl(props.res_title)}`)} dangerouslySetInnerHTML={{ __html: props.res_desc }}></p>
          </div>
        </div>
        <div className="eligible-content">
          {props.res_fund === "yes" &&
            <> <i className="fa fa-check-circle checkc"></i>
              <span>Eligible for Public Funding</span></>
          }
        </div>
        <div className="text-curriculum11">
          {Array.isArray(props.res_piller) &&
            props.res_piller?.map((item, i) => {
              switch (item.pillar_id) {
                case 1:
                  return (
                    <span key={i} style={{ color: "#F19357" }}>
                      <img className="img-fluid" src={Mindicon} alt="mind" /> Mind
                    </span>
                  );
                case 2:
                  return (
                    <span key={i} style={{ color: "#265472" }}>
                      <img className="img-fluid" src={Hearticon} alt="heart" />
                      Heart{" "}
                    </span>
                  );
                case 3:
                  return (
                    <span key={i} style={{ color: "#AAC0CE" }}>
                      <img className="img-fluid" src={Strengthicon} alt="strength" />
                      Strength{" "}
                    </span>
                  );
                case 4:
                  return (
                    <span key={i} style={{ color: "#F0B285" }}>
                      <img className="img-fluid" src={Sounicon} alt="soul" /> Soul{" "}
                    </span>
                  );
                default:
                  return null

              }
            })}
        </div>
        <div className="handle-star">
          {" "}
          <span className="starDigitProfile">{props.res_rating !== 0  ?  Number(props.res_rating ).toFixed(1) : "0.0"}</span>{" "}
          {/* <Rating
            name="read-only"
            size="small"
            readOnly
            value={props.res_rating}
          /> */}
          {renderRatingStars(props.res_rating)}
          {" "}
          <span className="starReviewProfile">({props.res_review})</span>
          <span
            className="reviews"
            onClick={() =>
              openRouteInNewTab(`/full-description/${props.resource_id}/${toSeoUrl(props.res_title)}`)
            }
          >
            See all reviews
          </span>
        </div>
        <div className="bookmark">
          {props?.save === "yes" ? (
            <i className="fa fa-bookmark"></i>
          ) : (
            <i className="fa fa-bookmark-o"></i>
          )}
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
            style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
          >
            Are you sure you want to remove this resource from saved list?
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 3 }}
            className="d-flex justify-content-center gap-4"
          >
            <button className="child-delete" onClick={removeResource}>
              Remove
            </button>{" "}
            <button className="child-cancel" onClick={handleClose}>
              Cancel
            </button>
          </Typography>
        </Box>
      </Modal>
    </>
  );
}
export default Cardcomponent;
