/**
 * Card Component1 page to show all serve resources list via a props
 *
 * @author Nidhi Mishra <nidhi.mishra@webappmate.com>
 * created on: 16-05-2023
 */

import React, { useState, useEffect } from "react";
import img1 from "../../assets/Images/sr-img1.png";
import ph22 from "../../assets/Images/ph22.png";
import srimg4 from "../../assets/Images/sr-img4.png";
import srimg3 from "../../assets/Images/sr-img3.png";
import vector3 from "../../assets/Images/vector3.png";
import srimg5 from "../../assets/Images/sr-img5.png";
import ph5 from "../../assets/Images/ph5.png";
import "./Cardcomponent1.css";
import Hearticon from "../../assets/Images/Hearticon.png";
import Mindicon from "../../assets/Images/Mindicon.png";
import Strengthicon from "../../assets/Images/Strengthicon.png";
import Sounicon from "../../assets/Images/Sounicon.png";
import { imageUrl } from "../../services/api";
import CardMenuButton from "./CardButtonMenu";
import { useNavigate } from "react-router-dom";
import {
  makePostRequest,
  // makePostRequestForAll,
} from "../../services/api";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { URLS } from "../../constants";
import { toastifyMessage, style } from "../../utilities/CustomFunctions";
//import serveResourceImg from "../../assets/Images/yourresource.jpg";
import serveResourceImg from "../../assets/Images/yourresource.jpg";
import Multiselect from "multiselect-react-dropdown";
import { viewResult } from "../ViewProfile.WebAppMate/Child_profile_page";
import { renderDescription } from "../../services/common.services";
import { renderRatingStars } from '../Search.WebAppMate/RenderCardInfo';
import "../Search.WebAppMate/rating.css";

function Cardcomponent1(props) {
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [resourceid, setResourceid] = useState("");
  const [resources, setResources] = useState("");

  useEffect(() => {
    setResources(props.resources);
  }, [props.resources]);

  //open confirmation modal to delete resource record
  const handleOpen = (data) => {
    setOpen(true);
    setResourceid(data.id);
  };
  const redirectToEditServeResources = (data) => {
    if (props?.group) {
      navigate(`/serve/${data?.id}`, {
        state: {
          group_id: props?.group_id
        }
      })
    } else if(data?.resource_mode === "save") {
      navigate("/save-page/" + data.id );
    }else  {
      navigate("/serve/" + data.id );
    }


  };
  //delete resources
  const deleteResourceData = () => {
    let data = {
      id: resourceid,
    };
    makePostRequest(URLS.deleteServeResources, null, data, null)
      .then((res) => {

        if (res.code === 200) {
          props.getresources();
          setOpen(false);
          // setResourceid("")
          toastifyMessage(res.message, "success");
          props.setDeleteApiRes("success");
          props.setCurrentPage(1);

        } else {
          toastifyMessage(res.message, "error");
        }
      })
      .catch((error) => {
        console.log(error);
      });

  };

  const redirectToDetail = (resourceid) => {
    navigate("/full-description/" + resourceid + "/no");
  };


  return (
    <>
      <div className="viewing56">
        <div className="saveResourceListgradeRange">
          <div className="form-select">
            <Multiselect
              options={viewResult}
              onSelect={(e) => {
                e?.forEach((val, i) => {
                  props.setItemPerPage(val.value);
                });
              }}
              displayValue="name"
              showArrow={true}
              showCheckbox
              selectedValues={props?.itemPerPage ? [viewResult.find(item => item.value === props?.itemPerPage)] : []}
            /></div></div>
      </div>
      <div className="row">
        {resources &&
          resources.length > 0 &&
          resources.map((val, i) => {
            return (
              <div className="col-sm-6 col-md-4 col-lg-4" key={i + 1}>
                <div className="card-container-exp">
                  <div className="img-icon" >
                    {
                      val?.resource_thumbnail_image ?
                        <div className="resource-img" onClick={() =>
                          redirectToDetail(val.id)
                        }><img src={imageUrl + val?.resource_thumbnail_image} className="resource-image2" alt="resource" />
                        </div> :
                        <>
                          {
                            val?.imageLink ?
                              <div className="resource-img" onClick={() =>
                                redirectToDetail(val.id)
                              }><img src={val?.imageLink} className="resource-image2" alt="resource" />
                              </div> :
                              <>
                                {val.resource_type && val.resource_type === 1 && (
                                  <>
                                    <div className="img-divsr1" onClick={() => {
                                      redirectToDetail(val.id);
                                    }}>
                                      <img src={img1} alt="Organization" />
                                    </div>
                                  </>
                                )}
                                {val.resource_type && val.resource_type === 3 && (
                                  <>
                                    <div className="img-divsr2" onClick={() => {
                                      redirectToDetail(val.id);
                                    }}>
                                      <img src={ph22} alt="Experiences" />
                                    </div>
                                  </>
                                )}
                                {val.resource_type && val.resource_type === 2 && (
                                  <>
                                    <div className="img-divsr3" onClick={() => {
                                      redirectToDetail(val.id);
                                    }}>
                                      <img src={srimg4} alt="Curriculum Content" />
                                    </div>
                                  </>
                                )}
                                {val.resource_type && val.resource_type === 4 && (
                                  <>
                                    <div className="img-divsr4" onClick={() => {
                                      redirectToDetail(val.id);
                                    }}>
                                      <img src={srimg3} alt="Student Support" />
                                    </div>
                                  </>
                                )}
                                {val.resource_type && val.resource_type === 5 && (
                                  <>
                                    <div className="img-divsr5" onClick={() => {
                                      redirectToDetail(val.id);
                                    }}>
                                      <img src={vector3} className="curriculum-image33" alt="Teacher Parent Support" />
                                    </div>
                                  </>
                                )}
                                {val.resource_type && val.resource_type === 6 && (
                                  <>
                                    <div className="img-divsr6" onClick={() => {
                                      redirectToDetail(val.id);
                                    }}>
                                      <img src={srimg5} alt="funding" />
                                    </div>
                                  </>
                                )}
                                {val.resource_type && val.resource_type === 7 && (
                                  <>
                                    <div className="img-divsr7" onClick={() => {
                                      redirectToDetail(val.id);
                                    }}>
                                      <img src={ph5} alt="Facility" />
                                    </div>
                                  </>
                                )}
                              </>
                          }
                        </>
                    }
                    <span>
                      <span>

                        <CardMenuButton
                          data={val}
                          redirectToEditServeResources={

                            redirectToEditServeResources
                          }
                          deleteResources={handleOpen}
                        />
                      </span>
                    </span>
                  </div>
                  <h3 onClick={() =>
                          redirectToDetail(val.id)
                        }>{val.resource_title ? val.resource_title : ""}</h3>
                  <div className="serve-desc-article" >
                    <p className="description" onClick={() => {
                      redirectToDetail(val.id);
                    }}>
                      {renderDescription(val?.description)}
                    </p>
                  </div>
                  <div className="eligible-content">
                    {val.public_funding === "yes" ? (
                      <div>
                        <i className="fa fa-check-circle checkc"></i>
                        <span>Eligible for Public Funding</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="text-curriculum11">
                    {Array.isArray(val.tbl_pillar_resource_mappings) &&
                      val.tbl_pillar_resource_mappings?.map((item, i) => {
                        switch (item.tbl_learning_pillar_master.name) {
                          case "Heart":
                            return (
                              <span key={i} style={{ color: "#265472" }}>
                                <img className="img-fluid" src={Hearticon} alt="heart" />
                                Heart{" "}
                              </span>
                            );
                          case "Strength":
                            return (
                              <span key={i} style={{ color: "#AAC0CE" }}>
                                <img className="img-fluid" src={Strengthicon} alt="strength" />
                                Strength{" "}
                              </span>
                            );
                          case "Mind":
                            return (
                              <span key={i} style={{ color: "#F19357" }}>
                                <img className="img-fluid" src={Mindicon} alt="mind" /> Mind
                              </span>
                            );
                          case "Soul":
                            return (
                              <span key={i} style={{ color: "#F0B285" }}>
                                <img className="img-fluid" src={Sounicon} alt="soul" /> Soul{" "}
                              </span>
                            );
                          default:
                             return null
                        }
                      })}
                  </div>
                  <div className="rating float-start">
                    <span className="starRatingNumber">
                      {Number(val.rating).toFixed(1)} &nbsp;
                    </span>
                    <span className="starRatingNumber">
                    {renderRatingStars(val.rating)}
                    </span >

                    {/* <div className="servemultiStar">
                      {star.map((temp, indx) => (
                        <>
                          {temp == val.rating ? (
                            <div className="star float-start" key={indx + 1}>
                              <FontAwesomeIcon icon={faStarHalfAlt} />
                            </div>
                          ) : temp < val.rating ? (
                            <div className="star" key={indx + 1}>
                              <FontAwesomeIcon icon={faStar} />
                            </div>
                          ) : (
                            <div className="star unfilled" key={indx + 1}>
                              <FontAwesomeIcon icon={faStar} />
                            </div>
                          )}
                        </>
                      ))}
                    </div> */}
                  </div> &nbsp;
                  <span className="serveTotalReview">
                    ({val.total_reviews})
                  </span>
                  <span className="reviews">
                    <a

                      referrerPolicy="no-referrer"
                      onClick={() => {
                        redirectToDetail(val.id);
                      }}
                    >
                      See all reviews
                    </a>
                  </span>
                </div>
              </div>
            );
          })}
        {resources?.length === 0 && (
          <div className="nodataFoundinfo">
            {
              props.group ? <strong>To add a resource, please select 'Create Resource' from the main group page.</strong> : <strong>To add a resource please select add resource above.</strong>
            }
            <img src={serveResourceImg} className="save img-fluid" style={{ width: "100%" }} alt="upload " />
          </div>
        )}
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
            style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
          >
            Are you sure you want to delete this resource?
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 3 }}
            className="d-flex justify-content-center gap-4"
          >
            <button className="child-delete" onClick={deleteResourceData}>
              Yes
            </button>{" "}
            <button className="child-cancel" onClick={() => setOpen(false)}>
              No
            </button>
          </Typography>
        </Box>
      </Modal>
    </>
  );
}
export default Cardcomponent1;
