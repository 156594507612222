import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import img1 from "../assets/Images/sr-img1.png";
import ph22 from "../assets/Images/ph22.png";
import srimg4 from "../assets/Images/sr-img4.png";
import srimg3 from "../assets/Images/sr-img3.png";
import vector3 from "../assets/Images/vector3.png";
import srimg5 from "../assets/Images/sr-img5.png";
import ph5 from "../assets/Images/ph5.png";

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "16px",
  boxShadow: 24,
  p: 8,
};

// this function decode tokne
export function decodeToken() {
  let my_token = localStorage.getItem("auth-token");
  var decoded = my_token ? jwt_decode(my_token) : "";
  return decoded;
}
export function userToken() {
  let my_token = localStorage.getItem("auth-token");
  return my_token;
}

// this function use got check url validatetion
export function checkUrl(string) {
  let givenURL;
  try {
    givenURL = new URL(string);
    console.log(givenURL)

    if (givenURL.href.indexOf(".") === -1)
      return false;

    if (givenURL.protocol === "http:")
      return false;

    if (givenURL.protocol === "https:")
      return true;

  } catch (error) {
    return false;
  }

}

// create tostify message
export function toastifyMessage(message, type) {
  if (type === "success") return toast.success(message, { className: "font-14" });
  if (type === "error") return toast.error(message, { className: "font-14" });
  if (type === "warning" || type === "warn") return toast.warn(message, { className: "font-14" });

}
export function forceDownload(url, fileName) {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.onload = function () {
    var urlCreator = window.URL || window.webkitURL;
    var imageUrl = urlCreator.createObjectURL(this.response);
    var tag = document.createElement("a");
    tag.href = imageUrl;
    tag.download = fileName;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  };
  xhr.send();
}

export function isValidFileUploaded(file) {
  const validExtensions = [
    "png",
    "jpg",
    "jpeg",
    "mpeg",
    "mp4",
    "mp3",
    "avi",
    "mov",
    "MOV",
    "x-troff-msvideo",
    "msvideo",
    "x-msvideo",
    "quicktime",
    "pdf",
  ];
  console.log("file.type", file.type)
  const fileExtension = file.type.split("/")[1].toLowerCase();
  console.log("fileExtension", fileExtension)
  return validExtensions.includes(fileExtension);
}


// this function is use for time difference
export function getTimeDifference(created_at) {
  const createdDate = new Date(created_at);
  const currentDate = new Date();
  const timeDifference = currentDate - createdDate;
  const yearsAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));
  const monthsAgo = Math.floor((timeDifference / (1000 * 60 * 60 * 24)) % 365 / 30);
  const daysAgo = Math.floor((timeDifference / (1000 * 60 * 60 * 24)) % 30);
  const hoursAgo = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
  const minutesAgo = Math.floor((timeDifference / (1000 * 60)) % 60);
  if (yearsAgo >= 1) {
    return `${yearsAgo} year${yearsAgo > 1 ? 's' : ''} ago`;
  } else if (monthsAgo >= 1) {
    return `${monthsAgo} month${monthsAgo > 1 ? 's' : ''} ago`;
  } else if (daysAgo >= 1) {
    return `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`;
  } else if (hoursAgo >= 1) {
    return `${hoursAgo} hour${hoursAgo > 1 ? 's' : ''} ago`;
  } else {
    return `${minutesAgo} minute${minutesAgo > 1 ? 's' : ''} ago`;
  }
}

export const getTime = (dateTimeString, status) => {
  const dateTime = new Date(dateTimeString);
  const hours = dateTime.getHours();
  const minutes = dateTime.getMinutes();
  const date = dateTime.getDate();
  const month = dateTime.getMonth() + 1;
  const year = dateTime.getFullYear();
  const amPm = hours >= 12 ? 'pm' : 'am';
  const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  if (status === "accounce") {
    return `${month}-${date}-${year},${formattedHours}:${formattedMinutes} ${amPm}`;
  }
  else {
    return `${formattedHours}:${formattedMinutes} ${amPm}`;
  }
};

export function groupMessagesByDay(messages) {
  const groupedMessages = {};

  messages.forEach((message) => {
    const messageDate = new Date(message.message_date);
    const formattedDate = `${(messageDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${messageDate.getDate().toString().padStart(2, '0')}-${messageDate.getFullYear()}`;

    if (!groupedMessages[formattedDate]) {
      groupedMessages[formattedDate] = [];
    }

    groupedMessages[formattedDate].push(message);
  });

  return groupedMessages;
}

// handle download all type file
export const handleFileDownload = async (url, filename, setspin) => {
  try {
    setspin && setspin(true);
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',           // Ensures CORS is handled
      cache: 'no-cache',      // Prevents caching issues
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    });
    const blob = await response.blob();
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setspin && setspin(false);
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
};

// text editor congfigration
export const editorConfig = {
  buttons: [
    "bold", // Bold
    "italic", // Italic
    "underline", // Underline
    "strikethrough", // Strikethrough
    "superscript", // Superscript
    "subscript", // Subscript
    "ul", // Unordered List
    "ol", // Ordered List
    "outdent", // Decrease Indentation
    "indent", // Increase Indentation
    "align", // Align (left, center, right, justify)
    "|", // Separator
    "link", // Insert Link
    "table", // Insert Table
    "undo", // Undo
    "redo", // Redo
  ],
};

// first letter capitalize
export function capitalizeWords(str) {
  return str.replace(/\b\w/g, function (txt) {
    return txt.toUpperCase();
  });
}

export const Emailvalidation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// this function use for handle images
export const getResourceImage = (resourceType) => {
  switch (resourceType) {
    case 1:
      return <img className="img-fluid" src={img1} alt="Organization" />;
    case 2:
      return <img className="img-fluid" src={srimg4} alt="Curriculum Content" />;
    case 3:
      return <img className="img-fluid" src={ph22} alt="Experiences" />;
    case 4:
      return <img className="img-fluid" src={srimg3} alt="Student Support" />;
    case 5:
      return <img className="img-fluid" src={vector3} alt="Teacher Parent Support" />;
    case 6:
      return <img className="img-fluid" src={srimg5} alt="funding" />;
    case 7:
      return <img className="img-fluid" src={ph5} alt="Facility" />;
    default:
      return null;
  }
};
