import React, { useState, useRef } from "react";
import { URLS } from "../../constants";
import { makePostRequest } from "../../services/api";
import "./reportpopup.css";
import { toastifyMessage } from "../../services/utilities";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";

/**
 * create components is used for report resource
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 */

function Reportpopup(props) {
  let my_token = localStorage.getItem("auth-token");
  const tokenParts = my_token.split(".");
  const tokenPayload = JSON.parse(atob(tokenParts[1]));
  const [text, setText] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [reason, setReason] = useState("Broken Link");
  const [spin, setSpin] = useState(false);
  const [error, setError] = useState({});
  const textFocus = useRef();
  const reasonFocus = useRef();

  //   this function report resource
  const handleSaveReport = () => {
    const errors = {};
    if (text === "") {
      errors.comments = "Comment is required";
      textFocus.current.focus();
    }
    if (reason === "Other") {
      if (otherReason === "") {
        errors.reason = "Reason is required";
        reasonFocus.current.focus();
      }
    }
    setError(errors);
    setSpin(true);
    let data = {
      resource_id: props?.data?.objectID
        ? props?.data?.objectID
        : props?.data?.id,
      reported_by: tokenPayload.userAuth.id,
      comment: text,
      reason: reason !== "Other" ? reason : otherReason,
    };
    if (data.reason && data.comment) {
      makePostRequest(URLS.reportResource, null, data, null).then(() => {
        toastifyMessage("Content Reported", "success");
        props.handleResourceReportClose();
        setSpin(false);
      });
    } else {
      setSpin(false);
    }
  };
  return (
    <>
      <div className="sharepopupOuter">
        {spin && (
          <div className="sharepopupOuter backgroundOpacity1">
            <FourDotsLoader />
          </div>
        )}
        <div className="pop-up-container reportPopupContainer">
          <button
            className="close-btn"
            onClick={() => props.handleResourceReportClose()}
          >
            <i className="fa fa-close closecs"></i>
          </button>
          <h3>Report/Suggest Changes</h3>
          <div className="reportPopupContent">
            <div className="input-popup1">
              <label className="pop-label">
                Report Reason?<span className="start-color">*</span>{" "}
                <span className="select-one">(select one)</span>
              </label>
              <select
                className="form-select select-form"
                onChange={(e) => setReason(e.target.value)}
                placeholder={"Select Reason"}
              >
                <option value="Broken Link">Broken Link</option>
                <option value="Duplicate User">Duplicate User</option>
                <option value="Report Content">Report Content</option>
                <option value="Report User">Report User</option>
                <option value="Suggest adding a URL">Suggest adding a URL</option>
                <option value="Suggest adding a Grade Level">Suggest adding a Grade Level</option>
                <option value="Suggest adding other information to this resource">Suggest adding other information to this resource</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="input-popup2">
              <div className="about-div">
                {reason === "Other" && (
                  <>
                    <h2>
                      Other Reason <span className="start-color mt-0">*</span>
                    </h2>
                    <div className="text-inner">
                      <textarea
                        onChange={(e) => setOtherReason(e.target.value)}
                        value={otherReason}
                        ref={reasonFocus}
                      ></textarea>
                      <span className="error-span" >{error.reason}</span>
                    </div>
                  </>
                )}
                <h2>
                  Comments<span className="start-color">*</span>
                </h2>
                <div className="text-inner">
                  <textarea
                    onChange={(e) => setText(e.target.value)}
                    value={text}
                    ref={textFocus}
                  ></textarea>
                  <span className="error-span" >{error.comments}</span>
                </div>

              </div>
              <div>
                <div className="save-cancel-btn">
                  <div className="cancel-butn">
                    <button onClick={() => props.handleResourceReportClose()}>
                      Cancel
                    </button>
                  </div>
                  <div className="savee">
                    <button onClick={handleSaveReport}>Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Reportpopup;
