import '../Common.WebAppMate/notificationmodal.css';
import {  useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from "react";
import { makePostRequestForAll } from '../../services/api';
import { URLS } from '../../constants';
import { toSeoUrl } from '../../services/common.services';
const MyGroup = (props) => {
    const naviget = useNavigate();
    const listInnerRef = useRef();
     const [myGroups, setMyGroups] = useState([]);
    const [page, setPage] = useState(1);
    const getGroup = () => {
        const payload = {
            "page_number": page,
            "item_per_page_group": 10,
        }
        makePostRequestForAll(URLS.getMyGroupSidebar, payload)
            .then((res) => {
                if (page == 1) {
                    setMyGroups(res.data.myGroups)
                } else {
                    setMyGroups([...myGroups, ...res?.data?.myGroups]);
                }
            }).catch((error) => {
                console.log("error:::", error)
            })
    }
    useEffect(() => {
        getGroup()
    }, [page]);
    
const handleScroll = () => {
        const { scrollTop } = listInnerRef.current;
        if (scrollTop === 0) {
            setPage(page + 1);
        }
 };
 return (<>
        <div className="modal-container2" ref={props.forwardedref}>
            <h1 className=''>My Groups<span className='all' onClick={props.datar}></span></h1>
            <div className='modal-div-inner'
                onScroll={handleScroll}
                ref={listInnerRef}
                >
                {
                    myGroups.length > 0 ? myGroups?.map((val) => {
                        return <>
                            <div className='modal-chat' >
                                <div className='text-container-noti d-flex gap-3'>
                                    <div onClick={() => naviget(`/groupjoin/${val.id}/${toSeoUrl(val.group_name)}`)} style={{ cursor: "pointer" }}>
                                        <img src={val.image} alt="group-img" />
                                    </div>
                                    <h2 className="text-capitalize my-2" onClick={() => naviget(`/groupjoin/${val.id}/${toSeoUrl(val.group_name)}`)} style={{ cursor: "pointer" }}>{val.group_name}</h2>
                                </div>

                            </div>
                        </>

                    }) : " "
                }
            </div>
        </div>
 </>
    )
}
export default MyGroup;