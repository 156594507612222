import React, { useEffect, useState } from "react";
import './BookaSession.css'
import { useNavigate, useParams } from "react-router-dom";
import { makePostRequest } from "../../services/api";
import { URLS } from "../../constants";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import Multiselect from "multiselect-react-dropdown";

function BookaSession() {
   const [listData, setListData] = useState([])
   const [childData, setChildData] = useState([]);
   const [id, setId] = useState("")
   const [loader, setLoader] = useState(false)
   const params = useParams()
   const naviget = useNavigate("")


   // get the childlist
   const getUserChilds = () => {
      let data = {
         resource_id: "",
      };
      makePostRequest(URLS.getChildList, null, data, null)
         .then((res) => {
            if (res.code === 200) {
               let arr = []
               res?.data?.length > 0 && res?.data.map((val) => {
                  if (val.childOwner === 1) {
                     return arr.push({ name: val.firstName + " " + val.lastName, value: val.id })
                  }
               })
               setChildData(arr);
               if (params?.id) {
                  const result = res?.data?.find(v => v.id == params?.id);
                  if (result) {
                     const { id} = result;
                     setId(id)
                  }
               }
            }
         })
         .catch((error) => {
            console.log(error);
         });
   };

   // get the book session data
   const getData = (id) => {
      setLoader(true)
      makePostRequest(URLS.getMentoringTutor, null, {
         child_id: id
      }, null)
         .then((res) => {
            if (res.code === 200) {
               setListData(res?.data)
            }
            setLoader(false)
         })
         .catch((error) => {
            setLoader(false)
            console.log(error);
         });
   }

   useEffect(() => {
      localStorage.setItem("backId", params?.id)
      getUserChilds();
   }, []);
   useEffect(() => {
      if (id) {
         getData(id)
      }
      else if (params?.id) {
         getData(params?.id)
      }
   }, [params?.id, id])

   return (<>
      <div className="col-lg-10">
         <div className="tobarbackcontee">
            <div className="gobackcontainer">
               <h5 className="col-6"><span onClick={() => naviget(-1)}><i className="fa fa-angle-left" aria-hidden="true"></i></span> Go Back</h5>
               <div className="tobarbackcontee allchildSelect">
                  <div className="gobackcontainer">
                     <div className="form-select">
                        <Multiselect
                           options={childData}
                           onSelect={(e) => {
                              e?.map((val, i) => {
                                 setId(val.value)
                                 localStorage.setItem("child", val.value)
                              });

                           }}
                           showCheckbox
                           displayValue="name"
                           placeholder="Select Child"
                           // singleSelect
                           showArrow={true}
                           selectedValues={id ? [childData.find(item => item.value == id)] : []}
                        /></div>
                  </div>
               </div>
            </div>
         </div>
         {loader ? <ul>
            <li>
               <div className="sharepopupOuter backgroundOpacity1">
                  <FourDotsLoader />
               </div>
            </li>
         </ul> : listData?.length ? <div className="bookmoniterdiv">
            <h2>Book a Schedule  </h2>
            <div className="bookmoniterdivbox">
               <ul>
                  {listData?.length > 0 ? listData?.map(v => (
                     v?.tbl_user_resource?.tbl_user_mentoring_session_schedules?.map(k => (

                        <li>
                           <div className="sprash-name">
                              <div className="sprash-name-lt">
                                 <img className="img-fluid" src={v?.tbl_user_resource?.tbl_user?.photo} alt="user image" loading="lazy" />
                              </div>
                              <div className="sprash-name-rts">
                                 <h3> {v?.tbl_user_resource?.resource_title} </h3>
                                 <p style={{ fontSize: "14px" }}>{(v?.tbl_user_resource?.tbl_user?.firstName ?? "") + " " + (v?.tbl_user_resourcetbl_user_resource?.tbl_user?.lastName ?? "")}</p>
                              </div>
                           </div>
                           <div className="sprash-name-rt">
                              <div className="sprash-name-box">
                                 <h4> Schedule Type
                                 </h4>
                                 <p> {k?.schedule_type == "1" ? "Group Session" : "Individual Session"} </p>
                              </div>
                              <div className="sprash-name-box">
                                 <h4> Time Slot
                                 </h4>
                                 <p> {k?.duration} minutes </p>
                              </div>
                              <div className="sprash-name-box">
                                 <h4> Amount
                                 </h4>
                                 <p> ${k?.price?.toFixed(2)} </p>
                              </div>
                              <div className="sprash-name-box">
                                 <button className="invoicebtn" onClick={() => naviget(`/booka-session-manualpicking/${k?.id}/no`, { state: { id: [params?.id] } })}>Availability</button>
                              </div>
                           </div>
                        </li>
                     ))
                  )
                  ) :
                     <p>No Data Found</p>
                  }

               </ul>
            </div>
         </div> : <h1 style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>This student has no bookings currently.</h1>}
      </div>
   </>)
}
export default BookaSession;