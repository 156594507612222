import React from "react";
import Hearticon from "../../assets/Images/Hearticon.png";
import Mindicon from "../../assets/Images/Mindicon.png";
import Sounicon from "../../assets/Images/Sounicon.png";
import Strengthicon from "../../assets/Images/Strengthicon.png";
import moment from "moment";
import "./rating.css";
import { renderDescription, toSeoUrl } from "../../services/common.services"
/**
 * create components is used for render the card
 *
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 */

// this function is use for open card in new tab
export const openRouteInNewTab = (routePath) => {
  const url = window.location.origin + routePath;
  window.open(url, "_blank");
  setTimeout(() => {
    localStorage.removeItem("scrollvaluereview")
  }, 8000);
};
export const renderRatingStars = (num) => {
const maxStars = 5;
// Get the entire value
const starPercentage = (num / maxStars) * 100;
// Round the percentage
const starPercentageRounded = Math.round(starPercentage);

const StarStyles = () => {
  return {
    width: starPercentageRounded + "%"
  };
};
return (
  <div className="stars-gray">
    <div className="stars-yellow" style={StarStyles()}></div>
  </div>
);

}
export const renderRatingStarsjjj = (num) => {
  switch (num) {
    case 0:
      return (
        <span>
          <span className="fa fa-star"></span>
          <span className="fa fa-star"></span>
          <span className="fa fa-star"></span>
          <span className="fa fa-star"></span>
          <span className="fa fa-star"></span>
        </span>

      );
    case 0.5:
      return (
        <span>
          <span>
            <i className="fa fa-star-half-o" style={{ color: "#F19357" }}></i>
          </span>
          <span className="fa fa-star"></span>
          <span className="fa fa-star"></span>
          <span className="fa fa-star"></span>
          <span className="fa fa-star"></span>

        </span>
      );
    case 1:
      return (
        <span>
          <span>
            <i className="fa fa-star" style={{ color: "#F19357" }}></i>
          </span>
          <span className="fa fa-star"></span>
          <span className="fa fa-star"></span>
          <span className="fa fa-star"></span>
          <span className="fa fa-star"></span>
        </span>
      );
    case 1.5:
      return (
        <span>
          <span>
            <i className="fa fa-star" style={{ color: "#F19357" }}></i>
          </span>
          <span>
            <i className="fa fa-star-half-o" style={{ color: "#F19357" }}></i>
          </span>
          <span className="fa fa-star"></span>
          <span className="fa fa-star"></span>
          <span className="fa fa-star"></span>

        </span>
      );
    case 2:
      return (
        <span>
          <span>
            <i className="fa fa-star" style={{ color: "#F19357" }}></i>
          </span>
          <span>
            <i className="fa fa-star" style={{ color: "#F19357" }}></i>
          </span>
          <span className="fa fa-star"></span>
          <span className="fa fa-star"></span>
          <span className="fa fa-star"></span>
        </span>
      );
    case 2.5:
      return (
        <span>
          <span>
            <i className="fa fa-star" style={{ color: "#F19357" }}></i>
          </span>
          <span>
            <i className="fa fa-star" style={{ color: "#F19357" }}></i>
          </span>
          <span>
            <i className="fa fa-star-half-o" style={{ color: "#F19357" }}></i>
          </span>
          <span className="fa fa-star"></span>
          <span className="fa fa-star"></span>
        </span>
      );
    case 3.0:
      return (
        <span>
          <span>
            <i className="fa fa-star" style={{ color: "#F19357" }}></i>
          </span>
          <span>
            <i className="fa fa-star" style={{ color: "#F19357" }}></i>
          </span>
          <span>
            <i className="fa fa-star" style={{ color: "#F19357" }}></i>
          </span>
          <span className="fa fa-star"></span>
          <span className="fa fa-star"></span>
        </span>
      );
    case 3.5:
      return (
        <span>
          <span>
            <i className="fa fa-star" style={{ color: "#F19357" }}></i>
          </span>
          <span>
            <i className="fa fa-star" style={{ color: "#F19357" }}></i>
          </span>
          <span>
            <i className="fa fa-star" style={{ color: "#F19357" }}></i>
          </span>
          <span>
            <i className="fa fa-star-half-o" style={{ color: "#F19357" }}></i>
          </span>
          <span className="fa fa-star"></span>
        </span>
      );
    case 4:
      return (
        <span>
          <span>
            <i className="fa fa-star" style={{ color: "#F19357" }}></i>
          </span>
          <span>
            <i className="fa fa-star" style={{ color: "#F19357" }}></i>
          </span>
          <span>
            <i className="fa fa-star" style={{ color: "#F19357" }}></i>
          </span>
          <span>
            <i className="fa fa-star" style={{ color: "#F19357" }}></i>
          </span>
          <span className="fa fa-star"></span>
        </span>
      );
    case 4.5:
      return (
        <span>
          <span>
            <i className="fa fa-star" style={{ color: "#F19357" }}></i>
          </span>
          <span>
            <i className="fa fa-star" style={{ color: "#F19357" }}></i>
          </span>
          <span>
            <i className="fa fa-star" style={{ color: "#F19357" }}></i>
          </span>
          <span>
            <i className="fa fa-star" style={{ color: "#F19357" }}></i>
          </span>
          <span>
            <i className="fa fa-star-half-o" style={{ color: "#F19357" }}></i>
          </span>
        </span>
      );
    case 5:
      return (
        <span>
          <i className="fa fa-star" style={{ color: "#F19357" }}></i>
          <i className="fa fa-star" style={{ color: "#F19357" }}></i>
          <i className="fa fa-star" style={{ color: "#F19357" }}></i>
          <i className="fa fa-star" style={{ color: "#F19357" }}></i>
          <i className="fa fa-star" style={{ color: "#F19357" }}></i>
        </span>
      );
    default:
      return (
        <span>
          <span className="fa fa-star"></span>
          <span className="fa fa-star"></span>
          <span className="fa fa-star"></span>
          <span className="fa fa-star"></span>
          <span className="fa fa-star"></span>
        </span>
      );
  }
};


const RenderCardInfo = ({ data, type }) => {
  return (
    <>
      <div className="">
        <h3
          className="text_capital"
          onClick={() =>
            openRouteInNewTab(`/full-description/${data.objectID}/${toSeoUrl(data?.resource_title)}`)
          }
        >
          {data?.resource_title ? data.resource_title : data.title}
        </h3>
        <p className="mb-1 publish-name">{(data?.author_name && data?.is_chat_gpt === "yes") && <>Publisher: {data?.author_name}</>}</p>
        <div className="searchdata-withaddressDate">
          <p
            onClick={() =>
              openRouteInNewTab(`/full-description/${data.objectID}/${toSeoUrl(data?.resource_title)}`)
            }
            className="description text_capital"
          > {renderDescription(data?.description)}
          </p>
          <p className="mb-1 descriptionaddress">{data?.define_address ? <><b>Address:</b> {data.define_address}</> : ""}</p>
          <p className="mb-2">{data?.start_date ? <><b>Date: </b>{moment(data.start_date).format("MM-DD-YYYY")}{data.end_date && <> to {moment(data.end_date).format("MM-DD-YYYY")}</>}</> : ""}</p>
          <div className="eligible-public-funding">
            {data?.public_funding === "yes" && (
              <h5>
                <span>
                  <i className="fa fa-check-circle checkc"></i>
                </span>
                Eligible for Public Funding
              </h5>
            )}
          </div>
        </div>
        <div
          className="text-curriculum11"
          onClick={() =>
            openRouteInNewTab(`/full-description/${data.objectID}/${toSeoUrl(data?.resource_title)}`)
          }
        >
          {Array.isArray(data.learning_pillars) &&
            data?.learning_pillars?.map((item, i) => {
              switch (item) {
                case "Heart":
                  return (
                    <span key={i} style={{ color: "#265472" }}>
                      <img className="img-fluid" src={Hearticon} alt="heart" />
                      Heart{" "}
                    </span>
                  );
                case "Strength":
                  return (
                    <span key={i} style={{ color: "#AAC0CE" }}>
                      <img className="img-fluid" src={Strengthicon} alt="strength" />
                      Strength{" "}
                    </span>
                  );
                case "Mind":
                  return (
                    <span key={i} style={{ color: "#F19357" }}>
                      <img className="img-fluid" src={Mindicon} alt="mind" /> Mind
                    </span>
                  );
                case "Soul":
                  return (
                    <span key={i} style={{ color: "#F0B285" }}>
                      <img className="img-fluid" src={Sounicon} alt="soul" /> Soul{" "}
                    </span>
                  );
              }
            })}
        </div>
      </div>
      <div onClick={() => openRouteInNewTab(`/full-description/${data.objectID}/${toSeoUrl(data?.resource_title)}`)}>
        <span>{data.rating !== 0  ?  Number(data.rating).toFixed(1) : "0.0"} {" "}</span> {renderRatingStars(data.rating)} {" "}
        <span>
           ({ data.reviews !== 0 ?  data.reviews : "0" })
        </span>
        <span
          className="reviews"
          onClick={() => {
            localStorage.setItem("scrollvaluereview", true); openRouteInNewTab(`/full-description/${data.objectID}${toSeoUrl(data?.resource_title)}`)
          }
          }
        >
          See all reviews
        </span>
      </div>
      <div className="bookmark">
        <span>
          {data?.save === "yes" ? (
            <i className="fa fa-bookmark"></i>
          ) : (
            <i className="fa fa-bookmark-o"></i>
          )}
        </span>
      </div>
    </>
  );
};

export default RenderCardInfo;
