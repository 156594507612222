import React from "react";
import "./Banner.css";
import homeBottom from "../../assets/Images/homebottom.jpg";
import mom from "../../assets/Images/searchright.png";
import homeTop from "../../assets/Images/hometop.jpg";
import { useNavigate } from "react-router-dom";
/** create components is used to home page
 * * @author krishna dobariya <krishna.webappmate@gmail.com>*/
const Banner = () => {
  let activeuser = localStorage.getItem('useractive');
  const naviget = useNavigate();
return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="banner">
              <h1>Foster your<br />student's personalized education.</h1>
              <div className="para mb-0"><p className="sub-text">Simplify the decision making. </p>
                <p>Join for free to explore educational resources filtered by your values and student’s learning styles, share these resources, and organize events with your friends and communities.</p>
              </div>
              <div className="my-3"><button className="get-start" onClick={() => { !activeuser ? naviget("/sign-up") : naviget("search-page") }}>Get Started</button></div>
            </div></div>
          <div className="col-lg-6"> <div className="homerightimgbox"><div className="images-Box">
              <img src={homeTop} className="girl img-fluid" alt="matched banner" loading="eager"/>
              <img src={mom} className="mom img-fluid" alt="matched banner" loading="eager"/>
              <img src={homeBottom} className="dad img-fluid" alt="matched banner" loading="eager"/>
            </div> </div></div></div>
      </div>
    </>
  );
};
export default Banner;
