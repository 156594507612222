import React from 'react'
import { makePostRequest } from '../../services/api';
import { URLS } from '../../constants';
import { userToken } from '../../utilities/CustomFunctions';
import { useState } from 'react';
import { useEffect } from 'react';
import FourDotsLoader from '../Common.WebAppMate/FourDoted';
import "./repayment.css"
import Head from '../Layout.WebAppMate/head/Head';
const RePayment = () => {
  const headers = { Authorization: "Bearer " + userToken() };
  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  // re payments Url
  useEffect(() => {
    setIsLoading(true)
    let payload = JSON.parse(localStorage.getItem("mailUrl"))
    makePostRequest(URLS.paymentUrl, null, payload, null, { headers })
      .then((res) => {
        setData(res?.data)
        console.log("rePayment", res)
        localStorage.setItem("unique_id", res?.data?.payment_intent);
        localStorage.setItem("zeroPrice", "no");
        window.open(res?.data?.payment_url, "_Self");
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error);
      });
    return (() => {
      setData({})
    })
  }, [])

  return (
    <>
     <Head title="Re-Payment"
        content="MatchED Repayment: Empowering Parents and Educators with a Faith-Embracing Matching Platform. Utilize cutting-edge technology to simplify education choices and create meaningful connections with MatchED.ˀ"
        name="Re-Payment" ></Head>
    <div className="col-lg-10">
      {isLoading ? (<>
        <div className="sharepopupOuter backgroundOpacity1">
          <FourDotsLoader />
        </div>
        <h2 className='failed' style={{ color: "green" }}>We are processing your request</h2>
      </>
      ) : (
        <>
          <h2 className='failed'>{data?.payment_intent ? "" : "We are unable to proceed your request"}</h2>
        </>)
      }
    </div>
    </>
  )
}
export default RePayment