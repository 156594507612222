import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./SignIn.css";
import "./Signup.css";
import { makePostRequest } from "../../services/api";
import { URLS } from "../../constants";
import { useDispatch } from "react-redux";
import { saveUserData } from "../../store/reducers/userReducer";
import "react-toastify/dist/ReactToastify.css";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import { toastifyMessage, Emailvalidation } from "../../utilities/CustomFunctions";
import jwt_decode from "jwt-decode";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import CryptoJS from "crypto-js";
import Head from "../Layout.WebAppMate/head/Head";
// /*
//      * SignIn  function is used to  Sign in  and Login into the application
//      * @author Naresh Kumar <naresh.gulati@webappmate.com>
//      * created on: 12-4-2023
//      * /
const secretKey = process.env.REACT_APP_DATA_ENCRYPT_DESCRYP_KEY;
function SignIn() {
  const location = useLocation()
  const [passicon, setpassicon] = useState(false);
  const [email, setemail] = useState("");
  const [passwords, setpasswords] = useState("");
  const [errorphone, setErrorsPhone] = useState("");
  const [errorpass, setErrorsPass] = useState("");
  const [spin, setspin] = useState(false);
  const [remember, setRemember] = useState(false);
  const emailFocus = useRef();
  const passFocus = useRef();

  //== Functions start for handling capturing email and password for login==
  const hanldeEmail = (e) => {
    setemail(e.target.value);
    setErrorsPhone("");
    if (localStorage.getItem("rememberMe")) {
      let data = JSON.parse(CryptoJS.AES.decrypt(
        localStorage.getItem("rememberMe"),
        secretKey
      ).toString(CryptoJS.enc.Utf8))
      let isInclude = data?.find(v => v?.email === e.target.value)
      if (isInclude) {
        setpasswords(isInclude?.passwords);
        setRemember(true)
      }
    }
  };

  const handlpasswords = (e) => {
    setpasswords(e.target.value);
    setErrorsPass("");
  };
  //==  Functions end for handling capturing email and password for login==
  const dispatch = useDispatch();

  //== Function for error handling and submit login data==
  const SignIn = (e) => {
    e.preventDefault();
    if (email === "") {
      emailFocus.current.focus()
      setErrorsPhone("Please enter your email address.");
    } else if (!Emailvalidation.test(email)) {
      emailFocus.current.focus()
      setErrorsPhone("Please enter valid email address.");
    } else if (passwords === "") {
      passFocus.current.focus();
      setErrorsPass("Please enter your password.");
    } else if (passwords.length < 8 || passwords.length > 20) {
      passFocus.current.focus();
      setErrorsPass(
        "Please enter a password between 8 and 20 characters long."
      );
    } else {
      //localStorage.setItem("useractive", email);
      //api call for login
      let data = {
        userName: email,
        password: passwords,
      };
      if (remember) {
        if (localStorage.getItem("rememberMe")) {
          let data = JSON.parse(CryptoJS.AES.decrypt(
            localStorage.getItem("rememberMe"),
            secretKey
          ).toString(CryptoJS.enc.Utf8))
          let isInclude = data?.find(v => v?.email === email)
          if (!isInclude) {
            localStorage.setItem("rememberMe", CryptoJS.AES.encrypt(JSON.stringify([...data, { email, passwords }]), secretKey).toString())
          }
        } else {
          localStorage.setItem("rememberMe", CryptoJS.AES.encrypt(JSON.stringify([{ email, passwords }]), secretKey).toString())
        }
      } else {
        // debugger
        if (localStorage.getItem("rememberMe")) {
          let data = JSON.parse(CryptoJS.AES.decrypt(
            localStorage.getItem("rememberMe"),
            secretKey
          ).toString(CryptoJS.enc.Utf8))
          let isInclude = data?.find(v => v?.email === email)
          if (isInclude) {
            let dummy = data.filter(v => v.email !== email)
            localStorage.setItem("rememberMe", CryptoJS.AES.encrypt(JSON.stringify(dummy), secretKey).toString())
          }
        }
      }
      setspin(true);
      makePostRequest(URLS.userLogin, null, data, null)
        .then((res) => {
          if (res.code === 200) {
            localStorage.setItem("auth-token", res.data.authorization);
            localStorage.setItem("useractive", email);
            localStorage.setItem("scrollvalue", true);
            localStorage.setItem("values", true);
            dispatch(saveUserData(res.data));
            setTimeout(() => {
              if (
                jwt_decode(res.data.authorization)?.userAuth
                  ?.is_subscription_community === "yes" ||
                jwt_decode(res.data.authorization)?.userAuth
                  ?.is_subscription_group === "yes" ||
                jwt_decode(res.data.authorization)?.userAuth
                  ?.is_subscription_vander === "yes"
              ) {
                if (location?.state?.from) {
                  window.location.href = location?.state?.from
                } else {
                  window.location.href = "/search-page";
                }
              } else {
                window.location.href = "/subscription";
              }
            }, 3000);
            toastifyMessage("You have logged in successfully.", "success");
          } else {
            setspin(false);
            toastifyMessage("Please enter your valid password.", "error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const SignInActive = () => {

    if (document.body.classList.contains('signin')) {
      console.log('The body has the class "signin".');
    } else {
      console.log('The body does not have the class "signin123".');
    }
  }
  useEffect(() => {
    SignInActive()
    document.body.classList.add("signin");
    return () => {
      document.body.classList.remove("signin");
    };
  }, []);
  //== UI for sign IN
  return (
    <>
         <Head title="Sign In"
      content="MatchED Home: Empowering Parents and Educators with a Faith-Embracing Matching Platform. Harnessing Cutting-Edge Technology to Simplify Education Choices and Create Meaningful Connections."></Head>

      <form className="frm" onSubmit={SignIn}>
        <div className="login">
          <div className="loginouter">
            <span className="successfulltext"> { } </span>
            {spin && (
              <div className="sharepopupOuter backgroundOpacity1">
                <FourDotsLoader />
              </div>
            )}
            <h1>Sign In</h1>
            <div className="form-group">
              <input
                className="form-control"
                id="inputPassword"
                placeholder="Email"
                value={email}
                onChange={hanldeEmail}
                ref={emailFocus}
              />
            
            </div>
            <span className="error"> {errorphone} </span>
            <div className="form-group">
              <input
                type={passicon === false ? "password" : "text"}
                className="form-control"
                id="inputEmail"
                placeholder="Password"
                value={passwords}
                onChange={handlpasswords}
                ref={passFocus}
              />
            
              <span onClick={() => setpassicon(!passicon)} className="hideshow">
                {passicon === true ? <AiFillEye /> : <AiFillEyeInvisible />}
              </span>
            </div>
            <span className="error"> {errorpass} </span>
            <div className="rember">
              <div className="form-checks">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="invalidCheck"
                  onChange={(e) => setRemember(e.target.checked)}
                  checked={remember}
                />
                <label
                  className="form-check-label remember"
                  htmlFor="invalidCheck">
                  Remember me
                </label>
              </div>
              <Link to="/forget-password" className="forgotpass">
                Forgot Password
              </Link>
            </div>
            <div className="col-lg-12 text-center" tyle={{ textDecoration: "none", color: "white" }}>
              <button type="submit" className="btn11"> Sign In
              </button>
            </div>
            <div className="bottomText">
              Not a member?{" "}
              <span>
                <Link to="/sign-up">Create an Account</Link>
              </span>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
export default SignIn;
