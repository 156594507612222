import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { toastifyMessage, style } from "../../utilities/CustomFunctions";
import { URLS } from "../../constants";
import { makeFormDateRequset, makePostRequestForAll } from "../../services/api";
import AddGroupMember from "./AddGroupMember";
import Trigonometry from "../../assets/Images/groupdefult.jpeg";
/**
 * create components is used to add user in login user chat list
 *
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 */

const GroupSetting = (props) => {
    const [groupName, setGroupName] = useState("")
    const [file, setFile] = useState("");
    const [openGruop, setOpenGroup] = useState(false);
    const [groupId, setGroupId] = useState("");
    const [image, setImage] = useState(null);
    const [error, setError] = useState("");
    const [admin, setAdmin]= useState("");
    const [grpData, setGrpData] = useState({
        image: "",
        name: "",
        admin:""
    });
    //  this function is use for add user in login user chat list
    const SaveData = () => {
        if (groupName === "") {
            setError(true)
        } else {
            if (props.userMapId) {
                const data = {
                    "id": props.userMapId,
                    "userList": [],
                    "group_name": groupName,
                    "name_status": "yes"
                }
                makePostRequestForAll(URLS.updateMultiplechatUser, data)
                    .then((res) => {
                        console.log("res::", res)
                        if (res.code === 200) {
                            toastifyMessage(res.message, "success");
                            // props?.getAllUser()
                            setOpenGroup(true);
                             props?.onClose()
                              if (props.pageStatus && props?.admin === true){
                                  props?.callback({ name: groupName, image: file, admin:true })
                                }else {
                                     props?.callback({ name: groupName, image: file })
                                     }

                        } else if (res.code === 400) {
                            toastifyMessage(res.message, "warn");
                        }
                    })
                    .catch((e) => {
                    });
                // }
            } else {
                const data = {
                    "userList": [],
                    "group_name": groupName
                }
                makePostRequestForAll(URLS.addMultiplechatUser, data)
                    .then((res) => {
                        toastifyMessage(res.message, "success");
                        console.log("res", res)
                        setGroupId(res?.data.id)
                        setAdmin(true)
                        props?.funCall()
                        if (image) {
                            const formData = new FormData();
                            formData.append("id", res?.data?.id);
                            formData.append("image", image);
                            makeFormDateRequset(URLS.UploadMultiChatImage, formData) // this api calling here for uploading group image first time
                                .then(res => {
                                    // props?.callback(groupName, URL.createObjectURL(image) , c)
                                    toastifyMessage(res.message, "success");
                                    setGrpData({ name: groupName, image: URL.createObjectURL(image) , admin: true })
                                    setFile("")
                                });
                        }


                    })
                    .catch((e) => {
                    });

            }
        }
    };
    useEffect(() => {
        props?.groupName && setGroupName(props?.groupName)
        props?.detail?.name && setGroupName(props?.detail?.name)
        props?.detail?.image && setFile(props?.detail?.image)
    }, [props?.detail, props?.groupName])

    const handleInput = (e, id) => {
        const checkfile = e.target.files;
        if (checkfile[0]) {
            const fileSize = checkfile.size / (1024 * 1024);
            if (fileSize > 2) {
                toastifyMessage("File size exceeded, Please upload file up to 2 mb", "error")
            } else if (
                checkfile[0].name.includes("png") ||
                checkfile[0].name.includes("jpg") ||
                checkfile[0].name.includes("jpeg")
            ) {
                setFile(URL.createObjectURL(e.target.files[0]))
                const formData = new FormData();
                if (props?.userMapId) {
                    formData.append("id", props?.userMapId);
                    formData.append("image", checkfile[0]);
                    makeFormDateRequset(URLS.UploadMultiChatImage, formData)
                        .then(res => {
                            if (res.code === 200) {
                                toastifyMessage(res.message, "success");
                                if(props?.pageStatus && props.admin === true){
                                props?.callback({ name: groupName, image: URL.createObjectURL(e.target.files[0]), admin: true })
                                }else {
                                    props?.callback({ name: groupName, image: URL.createObjectURL(e.target.files[0])})
                                }
                                // props.getAllUser()
                            }

                        });
                } else {
                    setImage(checkfile[0])
                }
            } else {
                toastifyMessage("Please upload only png,jpg,jpeg file", "error")

            }

        }
    };
    return (
        <>
            <div>
                <div className="viewMemberchatpopup sharepopupOuter">
        <div  className="chatboxview chatboxviewNew">
                        <button className="close-btn" onClick={props.onClose}>
                            <i className="fa fa-close closecs"></i>
                        </button>
                        <h1 className="add-title">Chat Setting</h1>

                        <div>
                            <div className="chat-content-setting">
                                <div className="group_image">
                                    {file ?
                                        <img src={file} alt="groupImage" loading="lazy" /> : <img src={Trigonometry} alt="groupImage" loading="lazy" />
                                    }
                                </div>
                                <div className="r-title-div chatsettingfront">
                                    { /* <h3>Upload Image</h3> */}
                                    <div className="inner-chat">
                                        <span className="u-content">
                                            {" "}
                                            <i className="fa fa-cloud-upload uploadicon"></i>
                                            <span>
                                                {"Upload Image"}
                                            </span>
                                        </span>
                                        <input
                                            type="file"
                                            placeholder=""
                                            className="input-file-upload"
                                            onChange={handleInput}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-group my-3">
                            <label className="form-label"> Group Name <span className="start-color">*</span> </label>
                            <input
                                type=""
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Name"
                                onChange={(e) => setGroupName(e.target.value.trimStart())}
                                value={groupName}
                            />
                            {error && groupName === "" ? <span className="error-span"> Please add group name
                            </span> : ""}
                        </div>

                        <div className="grouplist text-center mt-5 pb-4">
                            <button className="add-group"
                               onClick={() => {
                                SaveData();
                        if( props?.pageStatus){ // add member pop up will not open if we are opening group setting from siglechat
                            setOpenGroup(false)

                        }else if(groupName!==""){
                            setOpenGroup(true);
                        }else{
                            setOpenGroup(false)
                        }
                    }}  >Save</button>
                        </div>
                    </div>
                </div>

                {openGruop && <AddGroupMember open={openGruop} id={groupId}
                    onClose={() => {
                            setOpenGroup(false);
                            props?.onClose();
                            props?.callback(groupId,{ name: groupName ,admin:true, image: image ? URL.createObjectURL(image):Trigonometry}, 1)
                            //this call back function used for open chat screen after create group instantly

                    }} funCall={()=>{props?.funCall(); }} groupName={groupName} grpData={grpData} admin={admin}
                    />
				}
            </div>
        </>
    );
};

export default GroupSetting;
