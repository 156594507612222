import { useEffect, useState, useRef } from "react";
import { URLS } from "../../constants";
import { makePostRequest } from "../../services/api";
import "./joingrouppopup.css";
import Select from "react-select";
import { toastifyMessage } from "../../utilities/CustomFunctions";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
/**
 * create components is used join group popup
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 * created on: 24-7-2023
 */

function Joingroup2(props) {
  const [groupList, setGroupList] = useState([]);
  const [groupId, setGroupId] = useState([]);
  const [groupData, setGroupData] = useState("");
  const [joinType, setJoinType] = useState("Individual");
  const [reason, setReason] = useState("");
  const [error, setEror] = useState(false);
  const [spin, setSpin] = useState(false)
  const joinFocus = useRef();
  const nameFocus = useRef();

  useEffect(()=>{
    if (props?.pageStatus === "subgroupList"){
      setJoinType("Subgroup")
    }
  },[props])
  // join group Api calling
  const JoinGroup = () => {
    if ((joinType === "Subgroup" || joinType === "Maingroup") && groupData.length < 1) {
      setEror(true)
      if (groupId === "") {
        nameFocus.current.focus();
      }
    }
    else if (reason === "") {
      joinFocus.current.focus();
      setEror(true)
    }
    else {
      setSpin(true)
      const payload = {
        // if clicking on from joined tab join group button then this id opposite with each other 
        // in main_group = subgroup id and in subgroup id key = main group id is passing
        "main_group_id": props?.pageStatus === "subgroupList" ? joinType === "Individual" ? [] : groupData : props.value,
        "sub_group_id": props?.pageStatus === "subgroupList" ? props.value : joinType === "Individual" ? [] : groupData,
        "reason_to_join": reason,
        "group_type": joinType
      }
      makePostRequest(URLS.JoinGroup, null, payload, null)
        .then((res) => {
          setSpin(false)
          if (res.code === 200) {
            toastifyMessage(res.message, "success")
            if (props?.joinfrom === "searchApi"){
             props?.getSearch();
            //  props.setSearchPage(1);
            }else{
             props?.getSuggestion()
            }
           props.datar()
          }
          else if (res.code === 400) {
            toastifyMessage(res.message, "warn")
          }
        }).catch((error) => {
          setSpin(false)
          props.datar()
          console.log("error:::", error)
        })
    }
  }
  useEffect(() => {
    if (props?.pageStatus === "subgroupList"){
      makePostRequest(URLS.getAllGroupList, null, {}, null)
      .then((res) => {
        console.log("res::", res)
        const arr = []
        res?.data?.map((val, index) => {
          // if (val?.member_type === "Admin") {
            return arr.push({ label: val?.group_name , value: val?.id })
          // }

        })
        // setGetAllGroupList(arr);
        setGroupList(arr);
      }).catch((error) => {
        console.log("error:::", error)
      })
    }else{
    makePostRequest(URLS.getGroupList, null, {}, null)
      .then((res) => {
        const arr = []
        res?.data?.myGroups?.data?.forEach((val) => {
          if (val?.member_type === "Admin") {
            arr.push({ 
              label: val?.group_name + " (" + val?.member_type + ") ", 
              value: val?.id 
            });
          }
        });
        setGroupList(arr);
      }).catch((error) => {
        console.log("error:::", error)
      })
    }
     
  }, [props?.pageStatus])

  // handle input funcation
  const handleGroupId = (shareResourceVal) => {
    setGroupData(shareResourceVal.value);
    setGroupId(shareResourceVal)
  };
  // // handle input funcation
  // const handleAllGrouplist = (shareResourceVal) => {
  //   // setGroupData
  //   setGroupAllId(shareResourceVal.value);
  //   // setGroupAllId(shareResourceVal)
  // };
  return (
    <>   <div className="Joingroup2_div">
      {spin && (
        <div className="sharepopupOuter backgroundOpacity1">
          <FourDotsLoader />
        </div>
      )}
      <div className="Joingroup2_mid_div">
        <h1 className="join_grp">Connect Group</h1>
        <button className="cross_btn" onClick={props.datar}>
          X
        </button>
        {props?.pageStatus === "subgroupList" &&
        <>
              <div className="group_name_div">
              <label className="group_name">Select Group Name <span className="star-color">*</span></label > <label className="select_one1"> &nbsp;(select one)</label>
            </div>
           
              <div className="mul-sel-div mb-0"> 
                <div>
                  <Select
                  value={groupId ? groupId : null}
                  options={groupList}
                  selectMultiple={true}
                  onChange={handleGroupId}
                    ref={nameFocus}
                  />
                </div>
				<span className="error-span">{error && groupData.length < 1 && "Please enter a group name."}</span>
              </div>
              
              </>
}
        <div className="joinas_div">
          <>
      
          </>
          <label className="join_as">Connect As <span className="star-color">*</span></label >{" "}<label className="select_one">  (select one)</label>
        </div>
        <div className="radio_btn_div">
          {props?.pageStatus !== "subgroupList" ? 
          <label> <input type="radio" name="fav_language" value="Individual" className="form-check-input" defaultChecked onChange={(e) => setJoinType(e.target.value)} />
            <label htmlFor="html" className="individual">An Individual</label></label> : ""
}

            <label className="">
              <input type="radio" name="fav_language" value="Subgroup" className="form-check-input" onChange={(e) => setJoinType(e.target.value)} 
               defaultChecked = {props?.pageStatus === "subgroupList" ? true : false}/>
              <label htmlFor="html" className="subgroup" >Sub Group</label></label>
            <label className="">
              <input type="radio" name="fav_language" value="Maingroup" className="form-check-input" onChange={(e) => setJoinType(e.target.value)} />
              <label htmlFor="html" className="subgroup" >Main Group</label></label>

        </div>
  
        {
          (joinType === "Subgroup" || joinType === "Maingroup") && <>
            
            {props?.pageStatus !== "subgroupList" ? <>
            <div className="group_name_div">
              <label className="group_name">My Group Name<span className="star-color">*</span></label > <label className="select_one1"> &nbsp;(select one)</label>
            </div>
            {
              groupList && <div className="mul-sel-div mb-0">
                <div>
                  <Select
                    value={groupId ? groupId : null}
                    options={groupList}
                    selectMultiple={true}
                    onChange={handleGroupId}
                    ref={nameFocus}
                  />
                </div>
              </div>
              
            } 
                        <span className="error-span">{error && groupData.length < 1 && "Please enter a group name."}</span>
            </>:""
            // <>
            //   <div className="group_name_div">
            //   <label className="group_name">Select Group Name <span className="star-color">*</span></label > <label className="select_one1"> &nbsp;(select one)</label>
            // </div>
           
            //   <div className="mul-sel-div mb-0"> 
            //     <div>
            //       <Select
            //       value={groupId ? groupId : null}
            //       options={groupList}
            //       selectMultiple={true}
            //       onChange={handleGroupId}
            //         ref={nameFocus}
            //       />
            //     </div>
            //   </div>
            //   </>
}

          </>
        }
        <h2 className="heading1">Why I want to connect?<span className="star-color">*</span></h2>
        <textarea className="Text_box" onChange={(e) => setReason(e.target.value)} ref={joinFocus}></textarea>
        <span className="error-span">{error && reason === "" && "Please enter a reason."}</span>
        <div className="bton_div">
          <button className="bton2" onClick={JoinGroup}>
            <span className="bton2_texts" >Connect</span>
          </button>
        </div>
      </div>
    </div>
    </>
  );
}
export default Joingroup2;
