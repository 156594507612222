import React from "react";
import './yourgroups4.css';
import { useState, useEffect, useRef,forwardRef,useImperativeHandle } from "react";
import { useNavigate } from "react-router-dom";
import { URLS } from "../../constants";
import { makePostRequest } from "../../services/api";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import Threedotspopup from "../Common.WebAppMate/Threedotspopup";
import PermissionSharepopup from "../ShareInspiration.WebAppMate/Permissonsharepopup";
import PermissionRemovepopup from "../ShareInspiration.WebAppMate/RemovePermisson";
import { toastifyMessage } from "../../utilities/CustomFunctions";
/**
 * create components is used member request listing
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 * created on: 29-7-2023
 */
const MemberRequest = (props, ref) => {

    let useractive = localStorage.getItem("useractive");
    const navigate = useNavigate();
    const [spin, setSpin] = useState(false);
    const [show, setshow] = useState(false);
    const [matchId, setMatchId] = useState("");
    const [sharpermision, setSharepermision] = useState(false);
    const [removePermissions, setRemovePermissions] = useState(false);
    const [memberRequest, setMemberRequest] = useState([])
    const [page, setPage] = useState(1);
    const [status, setStatus] = useState(true);
    const menuRef = useRef(null);
    const menuBtnsRef = useRef(null);
    const [empty,setEmpty]=useState(0);

    const permision = () => {
        setSharepermision(true);
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    };
    const removePermission = () => {
        setRemovePermissions(true);
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    }
    useImperativeHandle(ref,()=>({
        getCheck
    }))

    const getCheck=(p,e) =>
    {
        setPage(p)
        setEmpty(e)
    }

// this function used for getting requsted member list
    const getMemberRequest = (p,e) => {
        setSpin(true)
        setEmpty(e)
        const data = {
            "group_id": props.id,
            "page_number": p?p:page,
            "item_per_page": 4,
            "searchKey": props.searchKey
        }
        console.log("data",data)
        makePostRequest(URLS.getMemberRequest, null, data, null)
            .then((res) => {
                console.log("request", res)
                setSpin(false)
                if (page == 1) {
                    setMemberRequest(res?.data?.groupMemberList)
                } else {
                    setMemberRequest([...memberRequest, ...res?.data?.groupMemberList]);
                }
                if (res.data.remaining_count == 0) {
                    setStatus(false)
                } else {
                    setStatus(true)
                }

            }).catch((error) => {
                setSpin(false)
                console.log("getMemberRequest:::", error)
            })
    }
    useEffect(() => {
        getMemberRequest(props.id);
    }, [page]);

   

    useEffect(() => {
     if(empty==0)
     {
     setMemberRequest([])
     getMemberRequest(page,1)
     }
     else
     getMemberRequest(page,1)
    
},[empty]);
// -----------------end
    const updateStatus = (id, status) => {
        const payload = {
            "request_id": id,
            "status": status
        }
        makePostRequest(URLS.updateJoinStatus, null, payload, null)
            .then((res) => {

                toastifyMessage(res.message, "success");
                getMemberRequest(props.id)

            }).catch((error) => {
                console.log("error::", error)
            })
    }

    useEffect(() => {
        if (useractive === "") {
            navigate("/sign-in");
        }
        getMemberRequest(props.id)
        document.body.classList.add("yourgroup");
        return () => {
            document.body.classList.remove("yourgroup");
        };
    }, []);

    const handleClickOutsides = (e) => {
        if (
            menuRef.current &&
            !menuRef.current.contains(e.target) &&
            !menuBtnsRef.current.contains(e.target)
        ) {
            setshow(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutsides, true);
        return () => {
            document.removeEventListener("mousedown", handleClickOutsides, true);
        };
    }, []);

    return (<>

        {
            spin && <div className="sharepopupOuter backgroundOpacity1"><FourDotsLoader /></div>
        }
        <div className="member-outer-main row">
            {
                memberRequest.length > 0 ? memberRequest?.map((val, index) => {
                    return <>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-12 ">
                            <div className="member-inner-div">
                                <div className="member-inner-flex">
                                    <div className="imgcirclee">
                                        <img className="img-fluid" src={val?.tbl_user?.photo} alt="member image" loading="lazy"/>
                                    </div>
                                    <div className="dots-three" ref={menuBtnsRef} onClick={() => {
                                        setshow(!show);
                                        setMatchId(index);

                                    }}>
                                        <i className="fa fa-ellipsis-v"></i>
                                        {show == true && matchId == index ? (
                                            <Threedotspopup forwardedref={menuRef} val={permision} id={val?.user_id} remove={removePermission} />
                                        ) : null}
                                        {sharpermision == true && matchId == index ? (
                                            <div className="friendRequestpermission">
                                                <PermissionSharepopup
                                                    data={() => setSharepermision(false)}
                                                    userId={val.user_id}
                                                    userName={val?.tbl_user?.firstName}
                                                />
                                            </div>
                                        ) : null}
                                        {removePermissions && matchId == index ?
                                            <PermissionRemovepopup
                                                data={() => setRemovePermissions(false)}
                                                userId={val.user_id}
                                                userName={val?.tbl_user?.firstName}
                                            /> : ""
                                        }
                                    </div>

                                </div>
                                <h6 className="text-capitalize" onClick={() => navigate(`/user-profile/${val?.user_id}`)}>{val?.tbl_user?.firstName + " " + val?.tbl_user?.lastName}</h6>
                                {/* <p className="text-center">Joined {getTimeDifference(val?.created_at)}</p> */}
                                <div className="member-button text-center">
                                    <button className="view-profile" onClick={() => updateStatus(val.id, "Accepted")}>Accept</button>
                                    <button className="remove-from-group" onClick={() => updateStatus(val.id, "Declined")}>Decline</button>

                                </div>
                            </div>
                        </div>
                    </>
                }) : <h1 className="text-center my-5">Data Not Found</h1>
            }
            <div className="show-more">
                {status && <p className="text-center" onClick={() => setPage(page + 1)}>Show more</p>}
            </div>

        </div>
    </>);

}

export default forwardRef(MemberRequest);