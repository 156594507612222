
import {
  InsertDriveFile,
  PictureAsPdf,
  Description,
  InsertChart,
  Slideshow,
} from "@mui/icons-material";
import GifBoxIcon from "@mui/icons-material/GifBox";

export const handleFileDownload = async (url, filename) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    const currentDate = new Date();

    // Get the date components
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    // Get the time components
    let hours = currentDate.getHours();
    const amOrPm = hours >= 12 ? "PM" : "AM";
    hours = String(hours % 12 || 12).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    link.download = `${filename} ${year}-${month}-${day} at ${hours}_${minutes}_${seconds} ${amOrPm}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
};

// export const handleFileDownload = async (url, filename) => {
//   try {
//     const response = await fetch(url);
//     const blob = await response.blob();
    
//     const link = document.createElement('a');
//     link.href = URL.createObjectURL(blob);
//     link.download = filename;
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   } catch (error) {
//     console.error("Error downloading the file:", error);
//   }
// };


export const getFileIcon = (extension) => {
  switch (extension) {
    case "txt":
      return <Description className="extension" />;
    case "pdf":
      return <PictureAsPdf className="extension" />;
    case "doc":
    case "docx":
      return <Description className="extension" />;
    case "xls":
    case "xlsx":
      return <InsertChart className="extension" />;
    case "ppt":
    case "pptx":
      return <Slideshow className="extension" />;
    case "gif":
      return <GifBoxIcon className="extension" />;
    default:
      return <InsertDriveFile className="extension" />;
  }
};

