import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {  makePostRequestForAll } from "../../services/api";
import { URLS } from "../../constants";
import { useNavigate } from "react-router-dom";
import { decodeToken, toastifyMessage ,style} from "../../utilities/CustomFunctions";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import CryptoJS from 'crypto-js';
const secretKey = process.env.REACT_APP_DATA_ENCRYPT_DESCRYP_KEY;


/**
 * create components is used to delete child profile
 *
 * @author krishna dobariya <krishna.webappmate@gmail.com>
  * created on: 1-08-2023
 */

const LeaveGroupPopup = (props) => {
    const navigate = useNavigate()
    const [spin, setSpin] = useState(false)
    const userid = decodeToken()?.userAuth?.id;
    const [type, setType] = useState("")


    // this function is used for delete child
    const LeaveGroup = () => {
        setSpin(true)
        const payload = {
            "user_id": userid,
            "group_id": props.value,
            "status": "leave", // block,
            "remove_type": type
        };

        makePostRequestForAll(URLS.leaveBloackChatGroup, payload)
            .then((res) => {
                setSpin(false)
                if (res.code === 200) {
                    toastifyMessage(res.message, "success");
                    props?.handleClose()
                    props?.funcall()
                } else {
                    toastifyMessage(res.message, "warn");
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    };


    return (
        <>
            {spin && (
                <div className="sharepopupOuter backgroundOpacity1">
                    <FourDotsLoader />
                </div>
            )}
            <Modal
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style}>
                    {
                        props?.lastAdmin === "lastAdmin" && <>
                            <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                                className="text-center"
                                style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
                            >
                                You need to create another admin to leave this group.
                            </Typography>
                            <Typography
                                id="modal-modal-description"
                                sx={{ mt: 3 }}
                                className="d-flex justify-content-center gap-4"
                            >
                                <button className="child-delete" onClick={() => navigate(`/create-new-group/${btoa(CryptoJS.AES.encrypt(props?.value?.toString(), secretKey).toString())}`, { state: "admin" })}>
                                    Create
                                </button>{" "}
                                <button className="child-cancel" onClick={props.handleClose}>
                                    Cancel
                                </button>
                            </Typography>
                        </>

                    }

                    {
                        props.lastAdmin === "User" &&

                        <>
                            <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                                className="text-center"
                                style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
                            >
                                Are you sure you want to leave this group?
                            </Typography>
                            <Typography
                                id="modal-modal-description"
                                sx={{ mt: 3 }}
                                className="d-flex justify-content-center gap-4"
                            >
                                <button className="child-delete" onClick={LeaveGroup}>
                                    Yes
                                </button>{" "}
                                <button className="child-cancel" onClick={props.handleClose}>
                                    No
                                </button>
                            </Typography>
                        </>
                    }

                    {
                        props.lastAdmin === "Admin" &&
                        <>
                            <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                                className="text-center"
                                style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
                            >
                                Leave Group
                                <div className="joinas_div">
                                    <label className="join_as">Leave As </label >{" "}<label className="select_one">  (select one)</label>
                                </div>
                                <div className="radio_btn_div">
                                    <label> <input type="radio" name="fav_language" value="" className="radio_button" defaultChecked onChange={(e) => setType(e.target.value)} />
                                        <label htmlFor="html" className="individual">Group Member</label></label>
                                    <label className="mx-3">
                                        <input type="radio" name="fav_language" value="admin" className="radio_button" onChange={(e) => setType(e.target.value)} />
                                        <label htmlFor="html" className="subgroup">Admin</label></label>
                                </div>
                            </Typography>
                            <Typography
                                id="modal-modal-description"
                                sx={{ mt: 3 }}
                                className="d-flex justify-content-center gap-4"
                            >
                                <button className="child-delete" onClick={LeaveGroup}>
                                    Yes
                                </button>{" "}
                                <button className="child-cancel" onClick={props.handleClose}>
                                    No
                                </button>
                            </Typography>
                        </>
                    }
                </Box>
            </Modal>
        </>
    );
};

export default LeaveGroupPopup;
