import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { URLS } from "../../constants";
import { makePostRequestForAll } from "../../services/api";
import { useNavigate } from "react-router-dom";
import { toastifyMessage ,style} from "../../utilities/CustomFunctions";
const DeleteRecourse = (props) => {
  let navigate = useNavigate();
  //this function used for delete resource
  const deleteSavedResource = () => {
    let data = {
      id: props.id,
    };
    makePostRequestForAll(URLS.deleteSavedResources, data)
      .then((res) => {
        if (res.code === 200) {
          toastifyMessage(res.message, "success");
          props.handleClose()
          if(props?.resource_mode?.res_groupStatus === "group"){
            navigate(`/group-members/${props?.resource_mode?.group_id}`, { state: 6});
          }else if(props?.resource_mode?.mode === "save"){
            navigate("/save-resources-list");
          }else{
            navigate("/serve-your-resources");
          }
        } else {
          toastifyMessage(res.message, "error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
            style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
          >
            Are you sure you want to delete this resource?
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 3 }}
            className="d-flex justify-content-center gap-4"
          >
            <button className="child-delete" onClick={deleteSavedResource}>
              Yes
            </button>{" "}
            <button className="child-cancel" onClick={props.handleClose}>
              No
            </button>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};
export default DeleteRecourse;
