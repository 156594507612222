import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./Childinfo.css";
import { useState } from "react";
import Select from "react-select";
import { useRef } from "react";
import { imageUrl, makeFormDateRequset, makeGetRequest, makePostRequestForAll, } from "../../services/api";
import { URLS } from "../../constants";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { decodeToken } from "../../utilities/CustomFunctions";
import { makePostRequest } from "../../services/api";
import DeleteChildPopUp from "./DeleteChildPopUp";
import { toastifyMessage } from "../../utilities/CustomFunctions";
import { capitalizeWords } from "../../services/utilities";
import jwt_decode from "jwt-decode";
import TextField from "@mui/material/TextField";
import "react-datepicker/dist/react-datepicker.css";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Head from "../Layout.WebAppMate/head/Head";

const month = [
  { lable: "January", value: "01" },
  { lable: "February", value: "02" },
  { lable: "March", value: "03" },
  { lable: "April", value: "04" },
  { lable: "May", value: "05" },
  { lable: "June", value: "06" },
  { lable: "July", value: "07" },
  { lable: "August", value: "08" },
  { lable: "September", value: "09" },
  { lable: "October", value: "10" },
  { lable: "November", value: "11" },
  { lable: "December", value: "12" }
]

/**
 * create components is used to view child update profile
 *
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 */
const filter = createFilterOptions();
function Childinfo() {
  const parmas = useParams();
  let userData = decodeToken();
  let my_token = localStorage.getItem("auth-token");
  let useractive = localStorage.getItem("useractive");
  let uimage = imageUrl;
  const currentDate = new Date();
  currentDate.setFullYear(currentDate.getFullYear() - 3);
  const headers = { Authorization: "Bearer " + my_token };
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedOption1, setSelectedOption1] = useState([]);
  const [selectedOption2, setSelectedOption2] = useState([]);
  const [selectedOption3, setSelectedOption3] = useState([]);
  const [spin, setSpin] = useState(false)
  const [open, setOpen] = React.useState(false);
  const [error, seterror] = useState(false);
  const [id, setId] = useState(0);
  const nameFocus = useRef();
  const lnameFocus = useRef();
  const genderFocus = useRef();
  const monthFocus = useRef();
  const yearFocus = useRef();
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [grade, setgrade] = useState([]);
  const [gradecondition, setgradecondition] = useState("");
  const [childgender, setchildgender] = useState("");
  const [childid, setchildid] = useState([]);
  const [educationid, seteducationid] = useState([]);
  const [learningid, setlearningid] = useState([]);
  const [iductraialId, setiductraialId] = useState([]);
  const [slearningcid, setslearningcid] = useState([]);
  const [numberOfChild, setNumberOfChild] = useState("");
  const [imgflag, setimgflag] = useState(false);
  const tokenParts = my_token?.split(".");
  const tokenPayload = JSON.parse(atob(tokenParts[1]));
  const [inputlist, setinputlist] = useState([{ name: "", email: "" }]);
  const naviget = useNavigate();
  const location = useLocation();
  const state = location.state;
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [years, setYears] = useState([]);
  const [fileList, setFileList] = useState([
    {
      uid: "-1",
      url: "",
    },
  ]);

  /* image on chnage */
  const onChange = async ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const removeImage = () => {
    if (childid) {
      setimgflag(false)
      makePostRequest(URLS.childImageDelete, null, { "id": childid }, null)
        .then((res) => {
          console.log("res::", res)
          toastifyMessage(res.message, "success")
        }).catch((error) => {
          console.log("error::", error)
        })
    } else {
      setimgflag(false)
    }
  }

  /* this function is use to check image type before crop */
  const beforeCrop = (file) => {
    if (
      file.name.toLowerCase().includes("jpg") ||
      file.name.toLowerCase().includes("png") ||
      file.name.toLowerCase().includes("jpeg")
    ) {
      setimgflag(false);
      return true;
    } else {
      setimgflag(true);
      return false;
    }
  };

  /* this function is use to view all data by user id */
  const handleshowupload = () => {
    setSpin(true)
    let data = {
      id: userData.userAuth.id,
    };
    makePostRequest(URLS.userfetchdata, null, data, null).then((response) => {
      setNumberOfChild(response.data.uesrData.number_of_child);
      if (response.data.childList.length > 0) {
        setSpin(false)
        setchildid(response.data && response.data.childList[id].id);
        setfirstname(response.data.childList[id].firstName);
        setlastname(response.data.childList[id].lastName);
        response?.data?.childList[id]?.userName &&
        response?.data?.childList[id]?.dob_month && setSelectedMonth(response?.data?.childList[id]?.dob_month)
        response?.data?.childList[id]?.dob_year && setSelectedYear(response?.data?.childList[id]?.dob_year)
        response?.data?.childList[id]?.grade_level &&
          setgradecondition(response?.data?.childList[id]?.grade_level);
        response?.data?.childList[id]?.gender &&
          setchildgender(response?.data?.childList[id]?.gender);
        const Eductionbackground =
          response.data.childList[id].childEductionbackground;
        const style = response.data.childList[id].childlearningstyle;
        const speLearning = response?.data?.childList[id]?.childspeciallearning;
        const eLearningData =
          response?.data?.childList[id]?.childInstructionalStyle;
        const user = response?.data.childList[id].tbl_child_admin_requests;
        setFileList([{ url: uimage + response?.data.childList[id].photo }]);
        const a = [];
        const stylearr = [];
        const speArr = [];
        const eLearning = [];
        const userData = [];
        for (const iterator of Eductionbackground) {
          a.push({
            keyword: iterator.tbl_educational_course.name,
            label: iterator.tbl_educational_course.name,
          });
        }
        seteducationid(a);
        for (const iterator of style) {
          stylearr.push({
            keyword: iterator.tbl_learning_style.name,
            label: iterator.tbl_learning_style.name,
          });
        }
        setlearningid(stylearr);
        for (const iterator of speLearning) {
          speArr.push({
            keyword: iterator.tbl_special_learning_course.name,
            label: iterator.tbl_special_learning_course.name,
          });
        }
        setslearningcid(speArr);
        for (const iterator of eLearningData) {
          eLearning.push({
            value: iterator.tbl_instructional_style.id,
            label: iterator.tbl_instructional_style.name,
          });
        }
        setiductraialId(eLearning);
        if (response?.data.childList[id].tbl_child_admin_requests?.length !== 0) {
          for (const iterator of user) {
            userData.push({
              name: iterator?.name,
              email: iterator?.email,
            });
          }
          setinputlist(userData);
        } else {
          setinputlist([{ name: "", email: "" }]);
        }
      }
    });
  };

  /*  create this function is used to upload image */
  function uploadimage(cropimage) {
    const formData = new FormData();
    formData.append("photo", cropimage ? cropimage : "");
    formData.append("id", childid);
    makeFormDateRequset(URLS.userchildprofileupload, formData).then(
      (response) => {
        if (response.code === 200) {
          console.log("response",response)
          setFileList([{ url: uimage + response?.data.photo }]);
          toastifyMessage(response.message, "success");
          setTimeout(() => { }, 2000);
        }
      }
    );
  }

  // start  for select option
  const handlegetgradedata = () => {
    makeGetRequest(URLS.usergetallgradelevel, null, null, null).then(
      (response) => {
        setgrade(response.data);
      }
    );
  };
  const handleeducationbackgrounddata = () => {
    makeGetRequest(URLS.usergetalleducationcourses, null, null, null).then(
      (response) => {
        setSelectedOption(response.data);
      }
    );
  };
  const handleeducationcourse = () => {
    makeGetRequest(URLS.usergetalllearningstyle, null, null, null).then(
      (response) => {
        setSelectedOption1(response.data);
      }
    );
  };
  const handlespeciallearning = () => {
    makeGetRequest(URLS.usergetallspeciallearning, null, null, null).then(
      (response) => {
        let options = [];
        response.data.length > 0 &&
          response.data.map((val, i) => {
            options.push({ keyword: val.name, label: val.name });
          });
        setSelectedOption2(options);
      }
    );
  };

  const handleGetAllInstructionalStyle = () => {
    makeGetRequest(URLS.getAllInstructionalStyle, null, null, null).then(
      (response) => {
        setSelectedOption3(response.data);
      }
    );
  };
  // end for select option
  /* handle inputs for select multiselect */
  const handleadd = () => {
    const values = [...inputlist];
    values.push({
      firstName: "",
      email: "",
    });
    setinputlist(values);
  };
  const handleremove = (index) => {
    const values = [...inputlist];
    values.splice(index, 1);
    setinputlist(values);
  };
  const handleInputChange = (index, event) => {
    const values = [...inputlist];
    const updatedValue = event.target.name;
    values[index][updatedValue] = event.target.value;
    setinputlist(values);
  };

  const handleselectedOption3 = (e) => {
    let iductraial = [];
    for (let i = 0; i < e.length; i++) {
      iductraial.push(e[i]);
    }
    setiductraialId([...iductraial]);
  };

  /* this function is is used to update child profile */
  const childinfoval = () => {
    // const currentDate = new Date().toISOString().split('T')[0];
    if (fileList === "") {
      setimgflag(true);
    } else if (firstname === "") {
      nameFocus.current.focus();
      seterror(true);
    } else if (lastname === "") {
      seterror(true);
      lnameFocus.current.focus();
    }else if(selectedMonth!=="" && selectedYear==""){
      seterror(true);
      yearFocus.current.focus();
    } else if(selectedMonth==="" && selectedYear!==""){
      seterror(true);
      monthFocus.current.focus();
    }else if (childgender === "") {
      seterror(true);
      genderFocus.current.focus();
    } else {
      let course_ids = [];
      let learning_ids = [];
      let slearningc_ids = [];
      let eLearningIds = [];
      if (educationid.length > 0) {
        educationid?.map((val, i) => {
          course_ids.push(val?.keyword?.trim() || val);
        });
      }
      if (learningid.length > 0) {
        learningid?.map((val, i) => {
          learning_ids.push(val?.keyword?.trim() || val);
        });
      }
      if (slearningcid.length > 0) {
        slearningcid?.map((val, i) => {
          slearningc_ids.push(val?.keyword?.trim() || val);
        });
      }
      for (let i = 0; i < iductraialId.length; i++) {
        let obj2 = {};
        obj2["instructional_style_id"] = iductraialId[i].value;
        eLearningIds.push(obj2);
      }
      const payload = {
        id: childid,
        parent_id: tokenPayload.userAuth.id,
        grade_level: gradecondition,
        firstName: capitalizeWords(firstname.trim().replaceAll("_", " ")),
      lastName: capitalizeWords(lastname?.replaceAll("_", " ")),
        dob_month: selectedMonth,
        dob_year: selectedYear,
        gender: childgender,
        profile_completed: "yes",
        childSpecialLearningList: slearningc_ids,
        childEducationBackgroundList: course_ids,
        childLearningStyleList: learning_ids,
        childInstructionalStyleList: [...eLearningIds],
        userList:
          inputlist[0]?.firstName === "" && inputlist[0]?.email === ""
            ? []
            : inputlist,
      };
      setSpin(true)
      makePostRequestForAll(URLS.userchildupdateprofile, payload)
        .then((response) => {
          setSpin(false)
          if (response.code === 400) {
            toastifyMessage(response.message, "warn");
          }
          if (response.code === 200) {
            toastifyMessage("Profile updated successfully", "success");
            setTimeout(() => {
              if (parseInt(id) + 1 < numberOfChild) {
                setfirstname("");
                setlastname("");
                setchildgender("");
                setgradecondition("");
                setSelectedMonth("");
                setSelectedYear("");
                setinputlist([{ name: "", email: "" }]);
                naviget(`/child-info/${parseInt(id) + 1}`);
              } else {
                makeGetRequest(URLS.token, null, { headers }).then((re) => {
                  let my_token = re?.data?.authorization;
                  localStorage.setItem("auth-token", re?.data?.authorization);
                  var decoded = my_token ? jwt_decode(my_token) : "";
                  localStorage.setItem(
                    "fristname",
                    decoded.userAuth.firstName
                  );
                });
                setTimeout(() => {
                  if (state === "paid") {
                    window.location.href = "/subscription";
                  } else if (localStorage.getItem("redirect-to-save")) {
                    localStorage.setItem("redirect-to-save", false);
                    naviget("/save-page");
                  } else {
                    localStorage.setItem("scrollvalue", true)
                    window.location.href = "/";
                  }
                }, 1000);
                localStorage.setItem("values", true);
              }
            }, 2000);
          }
        })
        .catch((e) => {
          toastifyMessage("something went wrong.", "error");
        });
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  /* this function is is used to complate letter user profile */
  const completeleter = () => {
    let course_ids = [];
    let learning_ids = [];
    let slearningc_ids = [];
    let eLearningIds = [];

    if (educationid.length > 0) {
      educationid?.map((val, i) => {
        course_ids.push(val?.keyword?.trim() || val);
      });
    }

    // for (let i = 0; i < learningid.length; i++) {
    //   let obj1 = {};
    //   obj1["learning_style_id"] = learningid[i].value;
    //   learning_ids.push(obj1);
    // }
    if (learningid.length > 0) {
      learningid?.map((val, i) => {
        learning_ids.push(val?.keyword?.trim() || val);
      });
    }
    if (slearningcid.length > 0) {
      slearningcid?.map((val, i) => {
        slearningc_ids.push(val?.keyword?.trim() || val);
      });
    }
    for (let i = 0; i < iductraialId.length; i++) {
      let obj2 = {};
      obj2["instructional_style_id"] = iductraialId[i].value;
      eLearningIds.push(obj2);
    }
    const payload = {
      id: childid,
      parent_id: tokenPayload.userAuth.id,
      grade_level: gradecondition,
      firstName: capitalizeWords(firstname.trim().replaceAll("_", " ")),
      lastName: capitalizeWords(lastname?.replaceAll("_", " ")),
      dob_month: selectedMonth,
      dob_year: selectedYear,
      gender: childgender,
      profile_completed: "No",
      childSpecialLearningList: slearningcid,
      childEducationBackgroundList: course_ids,
      childLearningStyleList: learning_ids,
      childInstructionalStyleList: [...eLearningIds],
      userList:
        inputlist[0].firstName === "" && inputlist[0].email === ""
          ? []
          : inputlist,
    };
    //if both condition fill up or blank then api will call
    if(selectedMonth!=="" && selectedYear==""){
      seterror(true);
      yearFocus.current.focus();
    } else if(selectedMonth==="" && selectedYear!==""){
      seterror(true);
      monthFocus.current.focus();
    }else{
    setSpin(true)
    makePostRequestForAll(URLS.userchildupdateprofile, payload)
      .then((response) => {
        setSpin(false)
        if (response.code === 200) {
          toastifyMessage("Profile complete later.", "success");
          setTimeout(() => {
            if (parseInt(id) + 1 < numberOfChild) {
              naviget(`/child-info/${parseInt(id) + 1}`);
            } else {
              makeGetRequest(URLS.token, null, { headers }).then((re) => {
                let my_token = re?.data?.authorization;
                localStorage.setItem("auth-token", re?.data?.authorization);
                var decoded = my_token ? jwt_decode(my_token) : "";
                localStorage.setItem(
                  "fristname",
                  decoded.userAuth.firstName
                );
              });
              setTimeout(() => {
                if (state === "paid") {
                  naviget("/subscription");
                  window.location.href = "/subscription";
                } else if (localStorage.getItem("redirect-to-save")) {
                  localStorage.setItem("redirect-to-save", false);
                  naviget("/save-page");
                } else {
                  localStorage.setItem("scrollvalue", true)
                  window.location.href = "/";
                }
              }, 1000);
              localStorage.setItem("values", true);
            }
          }, 2000);
        }
      })
      .catch((e) => {
        toastifyMessage("something went wrong.", "error");
      });
    }
  };
//month and year error displaying on run time by this useeffect
  useEffect(()=>{
    if(selectedMonth!=="" && selectedYear===""){
      seterror(true);
      yearFocus.current.focus();
    } else if(selectedMonth==="" && selectedYear!==""){
      seterror(true);
      monthFocus.current.focus();
    }
  }, [selectedMonth, selectedYear])

  /* on load api calling */
  useEffect(() => {
    if (useractive === "") {
      naviget("/sign-in");
    }
    setId(parmas.id);
    handlegetgradedata();
    handleeducationbackgrounddata();
    handleeducationcourse();
    handlespeciallearning();
    handleGetAllInstructionalStyle();
  }, [parmas]);
  useEffect(() => {
    id && handleshowupload();
  }, [id]);

  useEffect(() => {
    // Get the current year
    const currentYear = new Date().getFullYear();
    const yearRange = Array.from({ length: currentYear - 1970 - 3 }, (_, index) => currentYear - 4 - index);
    setYears(yearRange);
    window.scrollTo(0, 0);
    document.body.classList.add("childinfo");
    return () => {
      document.body.classList.remove("childinfo");
    };
  }, []);

  return (
    <>
      {spin && (
        <div className="sharepopupOuter backgroundOpacity1 fixedOuter">
          <FourDotsLoader />
        </div>
      )}
     <Head title="Student Profile"
        content="MatchED Create Student Profile: Empowering Parents and Educators with a Faith-Embracing Matching Platform. Create and manage student profiles while leveraging cutting-edge technology to simplify education choices and create meaningful connections."
        name="Student Profile" ></Head>
      <div className="col-12 col-lg-9 ">
        <div className="child">
          <div className="child-inner">
            {localStorage.getItem("values") === "true" ? (
              <div className="d-flex justify-content-between">
                <h2 className="jackheading text-capitalize-custome">
                  {firstname ? <span className="text-capitalize">{firstname}&#39;s Profile Settings</span> : <span>Profile Settings</span>} </h2>
                <div className="coverdivright my-4">
                  <button className="remove px-4" onClick={() => setOpen(true)}>
                    {" "}
                    Remove Profile
                  </button>
                </div>
              </div>
            ) : (
              <>
                <h1 >{firstname && <span className="text-capitalize">Set Up {firstname}&#39;s Profile</span>}</h1>
                <p>
                  In order to personalize your experience, please provide us with the
                  optional information requested below.
                </p>
                <h6>
                  *Any profile information you share will be kept confidential
                  and will only be used to help customize your MatchED's
                  platform.
                </h6>
                <h2>Complete Student's Profile</h2>
              </>
            )}
            <div className="Profiles">
              <div className="profile-div">
                <div className="input-file1"><div className="upload11">Upload photo</div></div>
                <ImgCrop
                cropperProps={{ restrictPosition: false }}
                  cropShape="round"
                  showGrid
                  showReset
                  minZoom={0.2}
                  zoomSlider
                  rotationSlider
                  aspect={10.5 / 9}
                  height={1200}
                  beforeCrop={(file) => beforeCrop(file)}
                  onModalOk={(cropedimg) => uploadimage(cropedimg)}
                >
                  {fileList ? (
                    <Upload
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      listType="picture-circle"
                      onChange={onChange}
                      onRemove={removeImage}
                      fileList={fileList}
                    >
                      {fileList === "" && (
                        <div className="text">
                          {" "}
                          <PlusOutlined /> upload
                        </div>
                      )}
                    </Upload>
                  ) : (
                    <Upload
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      listType="picture-circle"
                      onChange={onChange}
                    ></Upload>
                  )}
                </ImgCrop>
              </div>
              {imgflag === true ? (
                <span className="error"> Only png, jpg, jpeg are allowed</span>
              ) : null}
            </div>
            <form className="childform" onSubmit={(e) => e.preventDefault()}>
              <div className="row">
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
                  <div className="chilintofirstname">
                    <label htmlFor="firstname" className="form-label">
                      First Name<span className="starr">*</span>
                    </label>
                    <input
                      type="text"
                      // ref={inputRef}
                      className="form-control "
                      id="firstname"
                      onChange={(e) => setfirstname(e.target.value)}
                      value={firstname}
                      ref={nameFocus}
                    />
                    {error && firstname === "" ? (
                      <span className="error-span">
                        Please enter your first name.
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
                  <div className="chilintolastname">
                    <label htmlFor="lastname" className="form-label">
                      Last Name<span className="starr">*</span>
                    </label>
                    <input
                      type="text"
                      // ref={inputRef}
                      className="form-control "
                      id="lastname"
                      onChange={(e) => setlastname(e.target.value)}
                      value={lastname}
                      ref={lnameFocus}
                    />
                    {error && lastname === "" ? (
                      <span className="error-span">
                        Please enter your last name.
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                  <label
                    className="form-label"
                  >
                    Date of Birth: Select a Month
                  </label>
                  <select id="yearSelect" className="form-control" value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)} ref={monthFocus}>
                    <option value="">Select a month</option>
                    {month.map((val) => (
                      <option key={val.value} value={val.value}>{val.lable}</option>
                    ))}
                  </select>
                  {error && selectedMonth=== ""  && selectedYear!=="" ? (
                      <span className="error-span" style={{marginLeft: "13px"}}>
                        Please select month.
                      </span>
                    ) : (
                      ""
                    )}
                </div>

                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                  <label
                    className="form-label"
                  >
                    Date of Birth: Select a Year
                  </label>
                  <select id="yearSelect" className="form-control" value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)} ref={yearFocus}>
                    <option value="">Select a year</option>
                    {years.map((year) => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                  {error && selectedMonth !== ""  && selectedYear === "" ? (
                      <span className="error-span"  style={{marginLeft: "13px"}}>
                        Please select year.
                      </span>
                    ) : (
                      ""
                    )}
                </div>

              </div>
              <div className="row ">
                <div className="mt-3 col-sm-12 col-md-12 col-lg-12">
                  <label
                    htmlFor="gradelevel"
                    className="form-label"
                  >
                    Current Grade Level
                  </label>
                  <select
                    className="form-control"
                    onChange={(e) => setgradecondition(e.target.value)}
                    value={gradecondition}
                  >
                    <option value="">Choose a grade</option>
                    {grade?.map((temp) => (
                      <option value={temp.id}>{temp.name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row">

                <div className="col-lg-12">
                  <div className="">
                    <h4>
                      <span>Student's Gender</span> <span className="starr">*</span> (select one)
                    </h4>
                    <div className="">
                      <div className="form-check form-check-inline mx-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          checked={childgender === "male" ? true : false}
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          value="male"
                          onChange={(e) => setchildgender(e.target.value)}
                          ref={genderFocus}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio1"
                        >
                          Male
                        </label>
                      </div>
                      <div className="form-check form-check-inline mx-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          checked={childgender === "female" ? true : false}
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          value="female"
                          onChange={(e) => setchildgender(e.target.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio2"
                        >
                          Female
                        </label>
                      </div>
                      <div className="form-check form-check-inline mx-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          checked={
                            childgender === "Not Disclosed" ? true : false
                          }
                          name="inlineRadioOptions"
                          id="inlineRadio3"
                          value="Not Disclosed"
                          onChange={(e) => setchildgender(e.target.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio3"
                        >
                          Not Disclosed
                        </label>
                      </div>

                      {error && childgender === "" ? (
                        <span className="error-span">
                          Please enter your student's gender.
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <h3>
                  <span>What is your student's educational background?</span>
                  <span className="select-one"> (select one or multiple) </span>
                </h3>
                {/* <Select
                  onChange={handleselectedOption}
                  options={selectedOption.map((temp) => ({
                    value: temp.id,
                    label: temp.name,
                  }))}
                  isMulti={true}
                  value={educationid}
                /> */}
                <div className="kayword-associatedNew">
                 <Autocomplete
                    multiple
                    value={educationid}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        seteducationid({
                          keyword: newValue,
                        });
                      } else if (newValue && newValue.inputValue) {
                        seteducationid({
                          keyword: newValue.inputValue,
                        });
                      } else {
                        let filteredArray = newValue.filter(item => {
                          if (typeof item === 'string') {
                            return item.trim() !== '';
                          }
                          if (item.hasOwnProperty('inputValue') && typeof item.inputValue === 'string') {
                            return item.inputValue.trim() !== '';
                          }
                          return true;
                        });
                        seteducationid(filteredArray);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      const isExisting = options.some(
                        (option) => inputValue === option.keyword
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          keyword: inputValue,
                        });
                      }
                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    isOptionEqualToValue={(option, value) => option.keyword === value.keyword}
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={selectedOption.map((temp) => ({
                      keyword: temp.name,
                      label: temp.name,
                    }))}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.keyword;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.keyword}</li>
                    )}
                    sx={{ width: 300 }}
                    freeSolo
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField {...params} label="Educational Background" />
                    )}
                  />
                </div>
              </div>
              <div className="row">
                <h3>
                  <span>What learning style best fits your student?</span>
                  <span className="select-one"> (select one or multiple)</span>
                </h3>
                {/* <Select
                  onChange={handleselectedOption1}
                  options={selectedOption1?.map((temp) => ({
                    value: temp.id,
                    label: temp.name,
                  }))}
                  isMulti={true}
                  value={learningid}
                /> */}
                <div className="kayword-associatedNew">
                  <Autocomplete
                    multiple
                    value={learningid}
                    filterSelectedOptions  //removing selected option from dropdown on onchange
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setlearningid({
                          keyword: newValue,
                        });
                      } else if (newValue && newValue.inputValue) {
                        setlearningid({
                          keyword: newValue.inputValue,
                        });
                      } else {
                        let filteredArray = newValue.filter(item => {
                          if (typeof item === 'string') {
                            return item.trim() !== '';
                          }
                          if (item.hasOwnProperty('inputValue') && typeof item.inputValue === 'string') {
                            return item.inputValue.trim() !== '';
                          }
                          return true;
                        });
                        setlearningid(filteredArray);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some(
                        (option) => inputValue === option.keyword
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          keyword: inputValue,
                        });
                      }
                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    isOptionEqualToValue={(option, value) => option.keyword === value.keyword}
                    id="free-solo-with-text-demo"
                    options={selectedOption1.map((temp) => ({
                      keyword: temp.name,
                      label: temp.name,
                    }))}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.keyword;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.keyword}</li>
                    )}
                    sx={{ width: 300 }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField {...params} label="learning style" />
                    )}
                  />
                </div>
              </div>
              <div className="row">
                <h3>
                  <span>
                    What instructional style is generally used to support your
                    student?
                  </span>
                  <span className="select-one"> (select one or multiple)</span>
                </h3>
                <Select
                  onChange={handleselectedOption3}
                  options={selectedOption3.map((temp) => ({
                    value: temp.id,
                    label: temp.name,
                  }))}
                  isMulti={true}
                  value={iductraialId}
                // closeMenuOnSelect={false}
                />
              </div>
              <div className="learning ">
                <div className="">
                  <div className="">
                    <h3>
                      <span>
                        Does {firstname} have any special learning
                        considerations?
                      </span>
                      <span className="select-one"> (select one or multiple)</span>
                    </h3>
                    {/* <div className="select-input">
                      <Select
                        onChange={handleselectedOption2}
                        options={selectedOption2.map((temp) => ({
                          value: temp.id,
                          label: temp.name,
                        }))}
                        isMulti={true}
                        value={slearningcid}
                      />
                    </div> */}
                    <div className="kayword-associatedNew">
                      <Autocomplete
                        multiple
                        value={slearningcid}
                        onChange={(event, newValue) => {
                          if (typeof newValue === "string") {
                            setslearningcid({
                              keyword: newValue,
                            });
                          } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setslearningcid({
                              keyword: newValue.inputValue,
                            });
                          } else {
                            let filteredArray = newValue.filter(item => {
                              if (typeof item === 'string') {
                                return item.trim() !== '';
                              }
                              if (item.hasOwnProperty('inputValue') && typeof item.inputValue === 'string') {
                                return item.inputValue.trim() !== '';
                              }
                              return true;
                            });
                            setslearningcid(filteredArray);
                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;
                          // Suggest the creation of a new value
                          const isExisting = options.some(
                            (option) => inputValue === option.keyword
                          );
                          if (inputValue !== "" && !isExisting) {
                            filtered.push({
                              inputValue,
                              keyword: inputValue,
                            });
                          }
                          return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="free-solo-with-text-demo"
                        options={selectedOption2}
                        isOptionEqualToValue={(option, value) => option.keyword === value.keyword}  //removing selected option from dropdown on onchange
                        getOptionLabel={(option) => {
                          // Value selected with enter, right from the input
                          if (typeof option === "string") {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          // Regular option
                          return option.keyword;
                        }}
                        renderOption={(props, option) => (
                          <li {...props}>{option.keyword}</li>
                        )}
                        filterSelectedOptions  //removing selected option from dropdown on onchange
                        sx={{ width: 300 }}
                        freeSolo
                        renderInput={(params) => (
                          <TextField {...params} label="Special Learning" />
                        )}
                      />
                    </div>
                  </div>
                  <div className="">
                    <h3>
                      <span>
                        Please list any additional parents, teachers, coaches or other educational providers you would like to
                        grant access to your student's MatchED learning account.
                      </span>
                    </h3>
                  </div>
                  {inputlist.map((val, index) => {
                    return (
                      <div className="row">
                        <div className="col-lg-5">
                          <label htmlFor="firstname" className="form-label1">
                            Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="firstname"
                            name="name"
                            onChange={(event) =>
                              handleInputChange(index, event)
                            }
                            value={val?.name}
                          />
                        </div>
                        <div className="col-lg-5">
                          <label htmlFor="Email" className="form-label1 ">
                            Email
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="Email"
                            name="email"
                            onChange={(event) =>
                              handleInputChange(index, event)
                            }
                            value={val.email}
                          />
                        </div>
                        <div className="col-lg-2">
                          <div className="add">
                            <label htmlFor="Email" className="form-label1 w-100 hidden-xs">

                            </label>
                            {inputlist?.length !== -1 && (
                              <button
                                className="add-remove"
                                onClick={() => handleremove(index)}
                              >
                                X
                              </button>
                            )}

                          </div> </div>
                        <div className="float-start">
                          {inputlist?.length - 1 == index && (

                            <button className="add-add" onClick={handleadd}>
                              Add more +
                            </button>
                          )}
                        </div></div>
                    );
                  })}
                </div>
              </div>
            </form>
            <div className="save-btn1">
              <button className="s-button1" onClick={completeleter}>
                {" "}
                Complete Later{" "}
              </button>
              <button className="s-button" onClick={childinfoval}>
                {" "}
                Save{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* for delete the child */}
      <DeleteChildPopUp
        open={open}
        handleClose={() => setOpen(false)}
        id={childid}
      />
    </>
  );
}
export default Childinfo;
