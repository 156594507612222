import React from "react";
import './yourgroups4.css';
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MemberList from "./MemberList";
import { decodeToken, getTimeDifference } from "../../utilities/CustomFunctions";
import SubgroupList from "./SubgroupList";
import Strengthicon from "../../assets/Images/Strengthicon.png";
import Mindicon from "../../assets/Images/Mindicon.png";
import Hearticon from "../../assets/Images/Hearticon.png";
import Sounicon from "../../assets/Images/Sounicon.png"
import CryptoJS from 'crypto-js';
import ResourceList from "./ResourceList"
import { makePostRequest } from "../../services/api";
import { URLS } from "../../constants";
import ResourceSale from "./ResourceSale"
import StripePopup from "./StripePopup";
import { toSeoUrl } from "../../services/common.services";
import Head from "../Layout.WebAppMate/head/Head";
const secretKey = process.env.REACT_APP_DATA_ENCRYPT_DESCRYP_KEY;

const Yourgroups4 = () => {
   const userId = decodeToken().userAuth.id;
   const location = useLocation();
   const [stripe, setStripe] = useState(false);
   const state = location.state;
   let useractive = localStorage.getItem("useractive");
   const navigate = useNavigate();
   const param = useParams();
   const [tabChnage, setTabChnage] = useState(state ? state : 1);
   const [groupDetail, setGroupDetail] = useState([]);
   const [adminId, setAdminId] = useState([]);
   const [joinTime, setJoinTime] = useState("");
   const [stripeData, setStripeData] = useState([])

   useEffect(() => {
      if (useractive === "") {
         navigate("/sign-in");
      }
      getAccounts()
      document.body.classList.add("yourgroup");
      return () => {
         document.body.classList.remove("yourgroup");
      };
   }, []);

   //
   useEffect(() => {
      setJoinTime(getTimeDifference(groupDetail?.created_at))
      const arr = []
      groupDetail?.group_admin_users?.map((val) => {
         return arr.push(val.user_id)
      })
      setAdminId(arr)
   }, [groupDetail]);

   // this is giving all the details about group
   useEffect(() => {
      if (param.id) {
         makePostRequest(URLS.groupDetail, null, { "group_id": param.id }, null)
            .then((res) => {
               setGroupDetail(res?.data?.groupDetails)
            }).catch((error) => {
               console.log("error:::", error)
            })
      }
   }, [param.id])
   // this is used for getting existing stripe account ids
   const getAccounts = () => {
      makePostRequest(URLS.getStripeAccount, null, {}, null)
         .then((res) => {
            const arr = [];
            res?.data?.map((val, index) => {
               return arr.push({ value: val?.id, label: val?.email })
            })
            setStripeData(arr)

         }).catch((error) => {
            console.log("error::", error)
         })
   }
   return (<>
     <Head title="Group Detail"
         content="Build and support your community by starting a group to share resources, start group conversations, organize events, and provide updates through the bulletin board." 
         name="Group-Members" ></Head>
       {
         (param.id && stripe) && <StripePopup datar={() => setStripe(!stripe)} user={false} groupId={param.id} data={stripeData} />
      }
      <div className="col-lg-10 col-md-12 col-sm-12">
         <div className="your-main-groups">
            <div className="your-grp row">
               <div className="col-lg-8">
                  <div className="the-secret">
                     <h1 className="text-capitalize">{groupDetail?.group_name}</h1>
                     <p>Created {joinTime} </p>
                  </div>
                  <div className="text-curriculum11 groupmembers">
                     {Array.isArray(groupDetail?.tbl_group_learning_pillars) &&
                        groupDetail?.tbl_group_learning_pillars?.map((item, i) => {
                           switch (item?.tbl_learning_pillar_master?.name) {
                              case "Mind":
                                 return (
                                    <span key={i} style={{ color: "#F19357" }}>
                                       <img className="img-fluid" src={Mindicon} alt="mind" loading="lazy" /> Mind
                                    </span>
                                 );
                              case "Heart":
                                 return (
                                    <span key={i} style={{ color: "#265472" }}>
                                       <img className="img-fluid" src={Hearticon} alt="heart" loading="lazy" />
                                       Heart{" "}
                                    </span>
                                 );
                              case "Strength":
                                 return (
                                    <span key={i} style={{ color: "#AAC0CE" }}>
                                       <img className="img-fluid" src={Strengthicon} alt="strength" loading="lazy" />
                                       Strength{" "}
                                    </span>
                                 );
                              case "Soul":
                                 return (
                                    <span key={i} style={{ color: "#F0B285" }}>
                                       <img className="img-fluid" src={Sounicon} alt="soul" loading="lazy" /> Soul{" "}
                                    </span>
                                 );
                           }
                        })}
                     <h2 className="createaddmembers">{groupDetail?.no_of_user} Members </h2>
                  </div>

                  <p className="my-3 creataddpare" dangerouslySetInnerHTML={{ __html: groupDetail?.about_group }}></p>
               </div>
               <div className="col-lg-4">
                  {
                     param.id !== undefined && <div className="profileStripSetings"><button
                        className="strip-activation my-2"
                        stripe="true"
                        activation="true"
                        onClick={() => setStripe(true)}
                     >
                        Stripe Activation
                     </button></div>
                  }
                  <div className="slider-page float-end">
                     <img src={groupDetail?.image} alt="group image" className="mt-1" loading="lazy" />
                  </div>
               </div>
            </div>
            <ul className="postTextlist">
               <li onClick={() => navigate(`/groupjoin/${groupDetail.id}/${toSeoUrl(groupDetail.group_name)}`)} style={{ cursor: "pointer" }}>Posts</li>
               {(tabChnage === 1 || tabChnage === 2 || tabChnage === 5 || tabChnage === 4) ? <li className="button" onClick={() => setTabChnage(1)}>Members</li> : <li onClick={() => setTabChnage(1)} style={{ cursor: "pointer" }}>Members</li>}
               {tabChnage === 3 ? <li className="button" onClick={() => setTabChnage(3)}>Connected Groups</li> : <li onClick={() => setTabChnage(3)} style={{ cursor: "pointer" }}>Connected Groups</li>}
               {adminId.includes(userId) && <>{tabChnage === 6 ? <li className="button" onClick={() => setTabChnage(6)}>Resources</li> : <li onClick={() => setTabChnage(6)} style={{ cursor: "pointer" }}>Resources</li>} </>}
               {adminId.includes(userId) && <>{tabChnage === 7 ? <li className="button" onClick={() => setTabChnage(7)}>Sales History</li> : <li onClick={() => setTabChnage(7)} style={{ cursor: "pointer" }}>Sales History</li>} </>}
               {
                  adminId.includes(userId) && <li onClick={() => { navigate(`/create-new-group/${btoa(CryptoJS.AES.encrypt(param?.id.toString(), secretKey).toString())}`, { state: "" }); window.scrollTo({ top: 100, behavior: 'smooth' }) }} style={{ cursor: "pointer" }}>Settings</li>
               }

{/* bug no. 639 commented because of out of scope if needed simply uncomment this
               {
                  adminId.includes(userId) && <li onClick={() => { navigate(`/create-schedule`, { state: "" }); }} style={{ cursor: "pointer" }}>Manage Schedule</li>
               }
               {
                  adminId.includes(userId) && <li onClick={() => { navigate(`/session-history`, { state: "" }); }} style={{ cursor: "pointer" }}>Upcoming Appointments</li>
               } */}
            </ul>
            {
               (tabChnage === 1 || tabChnage === 2 || tabChnage === 5 || tabChnage === 4) && <MemberList id={param.id} admin={adminId.includes(userId)} tab={tabChnage} />
            }
            {
               tabChnage === 3 && <SubgroupList id={param.id} admin={adminId.includes(userId)}/>
            }
            {
               tabChnage === 6 && <ResourceList id={param.id} />
            }
            {
               tabChnage === 7 && <ResourceSale id={param.id} />
            }
         </div>
      </div>

   </>);

}
export default Yourgroups4;