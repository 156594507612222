import React, { useRef, useState } from "react";
import { decodeToken, toastifyMessage } from "../../utilities/CustomFunctions";
import { makePostRequest } from "../../services/api";
import { URLS } from "../../constants";

/**
 * create components is used for report resource
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 */
function EnrollPopup(props) {
    const userData = decodeToken().userAuth
    const [data, setData] = useState({
        "resource_id": props?.id || "",
        "email": userData?.email || "",
        "user_name": userData?.firstName || ""
    })
    const userfocus = useRef();
    const emailfocus = useRef();
    const [error, setError] = useState({});
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    // Function to handle form submission.
   const handleSave = (e) => {
        e.preventDefault();
        if (validateForm()) {
            makePostRequest(URLS.Enroll, null, data, null)
                .then((res) => {
               toastifyMessage(res?.message,"success")
               props.close()
                }).catch((error) => {
                    console.log("error:::", error)
                })
            }
    }
    // Function to perform form validation.
    const validateForm = () => {
        const errors = {};
        if (data.user_name === "") {
            errors.user_name = "Please enter customer name";
        }
        if (data.email === "") {
            errors.email = "Please enter email";
        }
        else if (!emailRegex.test(data.email)) {
            errors.email = "Please enter a valid email address";
        }
        setError(errors);
        if (data.user_name === "") {
            userfocus.current.focus();
        } else if (data.email === "") {
            emailfocus.current.focus();
        }
        else if (data.email !== "" && !emailRegex.test(data.email)) {
         emailfocus.current.focus();
        }
         return Object.keys(errors).length === 0;
    }
    return (
        <>
            <div className="sharepopupOuter">
                <div className="pop-up-container">
                    <button
                        className="close-btn"
                        onClick={() => props.close()}
                    >
                        <i className="fa fa-close closecs"></i>
                    </button>
                    <h3>Enroll</h3>
                    <div className="post-scroll-div square scrollbar-dusty-grass square thin    ">
                        <div className="chilintofirstname">
                            <label htmlFor="firstname" className="form-label">
                            Customer Name<span className="starr">*</span>
                            </label>
                            <input
                                type="text"
                                ref={userfocus}
                                className="form-control "
                                id="firstname"
                                name="user_name"
                                onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                                value={data.user_name}
                            />
                        </div>
                        <span className="error mt-4">
                            {(error && data.user_name.length === 0) ? error.user_name : ""}
                        </span>
                        <div className="chilintofirstname ">
                            <label htmlFor="firstname" className="form-label mt-2">
                                Email<span className="starr">*</span>
                            </label>
                            <input
                                type="text"
                                ref={emailfocus}
                                className="form-control "
                                id="email"
                                name="email"
                                onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                                value={data.email}
                            />
                        </div>
                        <span className="error mt-4">
                            {(error && (data.email.length !== "" &&  !emailRegex.test(data.email))) ? error.email : ""}
                        </span>
                        <div className="save-cancel-btn">
                            <div className="popup-btn-div">
                                <button onClick={handleSave}>save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default EnrollPopup;
