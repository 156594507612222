/**
 * Server Payment History  page to show all purchase resources history
 * @author Nidhi Mishra <nidhi.mishra@webappmate.com>
 * created on: 25-05-2023
 */
import { useState, useEffect, useCallback } from "react";
import "../BookaSession.WebAppMate/BookaSession.css";
import { makePostRequest, imageUrl, makePostRequestForAll } from "../../services/api";
import { URLS } from "../../constants";
import { decodeToken, toastifyMessage, forceDownload, style, getResourceImage } from "../../utilities/CustomFunctions";
import moment from "moment";
import Saveresourcespopup from "../Search.WebAppMate/Saveresourcespopup";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import SingleChat from "../Chat.WebAppMate/SingleChat";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import DatePicker from "react-datepicker";
import { Box, Modal, Typography } from "@mui/material";
import { Multiselect } from "multiselect-react-dropdown";
import { useSelector, useDispatch } from 'react-redux';
import { getOpenGlobalChat } from '../../store/reducers/userReducer';
import Head from "../Layout.WebAppMate/head/Head";
function ServerPaymentHistory() {
  const userId = decodeToken().userAuth.id;
  let uimage = imageUrl;
  const dispatch = useDispatch()
  let userStore = useSelector((state) => state.user);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let useractive = localStorage.getItem("useractive");
  let navigate = useNavigate();
  let { childid } = useParams();
  const [tabIndex, setTabIndex] = useState(0);
  const [tab2Data, setTab2Data] = useState([])
  const [loading, setLoading] = useState(false);
  const [purchaseHistoryData, setPurchaseHistoryData] = useState([]);
  const [groupPurchaseHistoryData, setGroupPurchaseHistoryData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(0);
  const [hasMoreMentoring, setHasMoreMentoring] = useState(0);
  const [childData, setChildData] = useState([]);
  const [childId, setChildId] = useState("")
  const [orderBy, setOrderBy] = useState("DESCDATE");
  const [savePopupStatus, setSavePopupStatus] = useState({
    visible: false,
    selectedResource: null,
  });
  const [open, setOpen] = useState(false)
  const [userType, setUserType] = useState("user")
  const [selectedResource, setSelectedResource] = useState("")
  const [resourceList, setResourceList] = useState([])
  const [modalData, setModalData] = useState([])
  const [mentoringTabPageNumber, setMentoringTabPageNumber] = useState(1);
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [isMounted, setIsMounted] = useState(false);


  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    if (queryParams.get('go')) {
      setTabIndex(1)
    }
    // eslint-disable-next-line
  }, [location.search])
  const orderByOptions = [
    { value: "DESCDATE", name: "Date (Newest to Oldest)" }, //deascending order
    { value: "ASCDATE", name: "Date (Oldest to Newest)" }, //ascending order
    { value: "ASCTITLE", name: "Title (A to Z)" },
    { value: "DESCTITLE", name: "Title (Z to A)" }
  ];
  const UserOptions = [
    { value: "user", name: "User" },
    { value: "mentor", name: "Vendor" }

  ];
  const handleOrderSelection = (selectedList) => {
    selectedList?.forEach((val, i) => {
      setOrderBy(val.value);
    });
  };
  //api call to get all purchase resources history list
  const getPurchaseHistory = () => {
    setLoading(true);
    let data = {
      child_id: childId === "all" ? "" : childId,
      sortby: orderBy,
      page_number: currentPage,
      end_date: (endDate && moment(endDate).format("YYYY-MM-DD")) || "",
      start_date: (startDate && moment(startDate).format("YYYY-MM-DD")) || ""
    };
    console.log("data::-----", data)
    makePostRequest(URLS.userResourcePurchaseHistory, null, data, null)
      .then((res) => {
        console.log("purchasehistory:------", res.data)
        if (res.code === 200) {
          setLoading(false);
          if (currentPage === 1) {
            setPurchaseHistoryData(res?.data?.data);
            setHasMore(res?.data?.remaining_count);
          } else {
            setPurchaseHistoryData([
              ...purchaseHistoryData,
              ...res?.data?.data,
            ]);
            setHasMore(res?.data?.remaining_count);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // api call to get all group purchase resource history list
  const getGroupPurchaseHistory = () => {
    setLoading(true);
    let data = {
      child_id: childId,
      sortby: orderBy,
      page_number: currentPage,
      end_date: (endDate && moment(endDate).format("YYYY-MM-DD")) || "",
      start_date: (startDate && moment(startDate).format("YYYY-MM-DD")) || ""
    };
    console.log("data", data)
    makePostRequest(URLS.groupUserResourcePurchase, null, data, null)

      .then((res) => {
        console.log("response group", res)
        if (res.code === 200) {
          setLoading(false);
          if (currentPage === 1) {
            setGroupPurchaseHistoryData(res?.data?.data);
            setHasMore(res?.data?.remaining_count);
          } else {
            setGroupPurchaseHistoryData([
              ...groupPurchaseHistoryData,
              ...res?.data?.data,
            ]);
            setHasMore(res?.data?.remaining_count);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // thise function use  for get resource list
  const gerResourceList = useCallback(() => {
    setLoading(true);
    let data = {
      type: userType,
      page_number: 1,
    };
    makePostRequest(URLS.getResourceMentoring, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          setSelectedResource('')
          setLoading(false);
          let arr = [];
          if (res?.data?.data.length > 0) {
            arr = [{ name: 'All', value: '' }];
          }
          res?.data?.data.length > 0 &&
            res?.data.data.forEach((val) => {
              arr.push({ name: val?.tbl_user_resource?.resource_title, value: val?.resource_id });
            });
          setResourceList(arr);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, [userType]);

  useEffect(() => {
    gerResourceList();
  }, [gerResourceList]);
  // this function is use for get purchse report
  const getMentoringTabData = () => {
    setLoading(true);
    let data = {
      "resource_id": selectedResource,
      "sortby": orderBy,
      "page_number": mentoringTabPageNumber,
      "type": userType,
      "end_date": (endDate && moment(endDate).format("YYYY-MM-DD")) || "",
      "start_date": (startDate && moment(startDate).format("YYYY-MM-DD")) || ""
    };
    makePostRequest(URLS.getMentoringPurchase, null, data, null)
      .then((res) => {
        console.log("mentoring session", res.data)
        if (res.code === 200) {
          if (mentoringTabPageNumber === 1) {
            setTab2Data(res?.data?.data);
            setHasMoreMentoring(res?.data?.remaining_count);
          } else {
            setTab2Data([
              ...tab2Data,
              ...res?.data?.data,
            ]);
            setHasMoreMentoring(res?.data?.remaining_count);
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }
  useEffect(() => {
    if (useractive === "") {
      navigate("/sign-in");
    }
    setTimeout(() => {
      getUserChilds();
    }, 500);
    // eslint-disable-next-line
  }, [childid]);

  // This effect runs when 'selectedResource', 'userType', or 'orderBy' change.
  useEffect(() => {
    if (userType !== "" && orderBy) {
      getMentoringTabData();
    }
    // eslint-disable-next-line
  }, [selectedResource, userType, orderBy]);

  // This effect runs when 'startDate', 'endDate', or 'childId' change.
  useEffect(() => {
    // Calls 'getGroupPurchaseHistory' if 'tabIndex' is 2, otherwise 'getPurchaseHistory' if 'tabIndex' is 0.
    if (isMounted && (startDate || endDate || childId || tabIndex || orderBy)) {
      if (tabIndex === 2 && currentPage === 1) {
        getGroupPurchaseHistory();
      } else if (tabIndex === 0 && currentPage === 1) {
        getPurchaseHistory();
      } else {
        setCurrentPage(1)
      }
    }
    // eslint-disable-next-line
  }, [isMounted, startDate, endDate, tabIndex, orderBy, childId]);

  // This effect runs when 'currentPage' changes.
  useEffect(() => {
    if (isMounted) {
      tabIndex === 2 && getGroupPurchaseHistory();
      tabIndex === 0 && getPurchaseHistory();
    }
    // eslint-disable-next-line
  }, [currentPage, isMounted]);

  //Mentoring session api calling on tabchange
  useEffect(()=>{
    if (tabIndex === 1){
      getMentoringTabData();
    }
    // eslint-disable-next-line
  },[tabIndex])
  // This effect runs when 'mentoringTabPageNumber', 'startDate', or 'endDate' change.
  useEffect(() => {
    // Calls 'getMentoringTabData'.
    tabIndex === 1 && getMentoringTabData();
    // eslint-disable-next-line
  }, [mentoringTabPageNumber, startDate, endDate]);

  //api call to get  childs data
  const getUserChilds = () => {
    let data = {
      resource_id: "",
    };
    makePostRequest(URLS.getChildList, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          let arr = [{ name: "All", value: "all" }]
          res?.data?.length > 0 && res?.data.forEach((val) => {
            if (val.childOwner === 1 || val.isAdmin === 1) {
              return arr.push({ name: val.firstName + " " + val.lastName, value: val.id })
            }
          })
          setChildData(arr);
          if (childid) {
            const result = res?.data?.find(v => v.id === parseInt(childid));
            if (result) {
              const { id } = result;
              setTimeout(() => {
                setChildId(id)
              }, 500);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // tis function use for download receipt
  const downloadReceipt = (invoicePdf) => {
    if (invoicePdf) {
      let pdfFileName = invoicePdf.split("/");
      forceDownload(uimage + invoicePdf, pdfFileName[3]);
    } else {
      toastifyMessage("No invoice available for download", "error");
    }
  };

  const handleLoadMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };
  const handleLoadMore2 = () => {
    setMentoringTabPageNumber((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    document.body.classList.add("servepaymenthistory");
    return () => {
      document.body.classList.remove("servepaymenthistory");
    };
  }, []);

  //redirect to resource  detail page
  const redirectToDetail = (resourceid) => {
    navigate("/full-description/" + resourceid);
  };

  // thise function is use for add user to chat
  const AddUser = (id, userData ) => {
    dispatch(getOpenGlobalChat({ ...userStore?.globalOpenChat, chatOpen: false }))
    makePostRequestForAll(URLS.addChatUser, { chat_with: id })
      .then((res) => {
        if (res.code === 200) {
          dispatch(getOpenGlobalChat({ ...userStore?.globalOpenChat, chatOpen: true, userMapId: res?.data?.id, userDetail: userData, senderId: id, group: 0, pageName : "serverPaymenthistory", chat_by_block : res?.data?.block }))
        }

      })
      .catch((e) => {
        console.log("error:::::", e)
        // toastifyMessage("something went wrong.", "error");
      });
  };
  // useEffect(() => {
  //   // This effect runs whenever location (URL) changes so chat window not open again when you come back on this page again
  //   dispatch(getOpenGlobalChat(
  //     {chatOpen : false, userDetail : "", userMapId: "", senderId: "", group: "", pageName : "" })) // Reset or clear the userstore data here
  // }, [location.pathname]);
  return (
    <>
      <Head title="Payment History"
        content="MatchED Payment History: Empowering Parents and Educators with a Faith-Embracing Matching Platform. Track your transactions and utilize cutting-edge technology to simplify education choices and create meaningful connections."
        name="Payment History" ></Head> 
              {loading && (
        <div className="sharepopupOuter backgroundOpacity1">
          <FourDotsLoader />
        </div>
      )}
      <div className="col-lg-10">
        <div>
          {
            userStore?.globalOpenChat?.chatOpen && userStore?.globalOpenChat?.pageName === "serverPaymenthistory" &&
             <SingleChat statuspage={"serverPaymenthistory"} groupName={userStore?.globalOpenChat?.userDetail} chatClose={() =>    dispatch(getOpenGlobalChat({
              chatOpen: false, userDetail: "", userMapId: "", senderId: "", group: "", pageName : ""
               }))} userMapId={userStore?.globalOpenChat?.userMapId} senderId={userStore?.globalOpenChat?.senderId} group={userStore?.globalOpenChat?.group} getAllUser={() => { }} />
          }
        </div>
        <div className="tobarbackcontee">
          <div className="gobackcontainer">
            <h5 className="col-6">
              <Link to="/serve-dashboard" className="link-style">
                <span>
                  <i className="fa fa-angle-left" aria-hidden="true"></i>
                </span>{" "}
                Go Back
              </Link>
            </h5>
          </div>
        </div>
        <div className="bookmoniterdiv borderbottomss">
          <div className="row">
            <div className="col-lg-12 menroginturorsdxs">
              <div className="select-exp-div float-end">
                <DatePicker
                  className="border-0 w-100 searchDate"
                  id="date"
                  selected={endDate}
                  onChange={(date) => { setEndDate(date); setMentoringTabPageNumber(1) }}
                  placeholderText="End Date"
                  dateFormat="MM-dd-yyyy"
                />
              </div>
              <div className="select-exp-div float-end">
                <DatePicker
                  className="border-0 w-100 searchDate"
                  id=""
                  selected={startDate}
                  onChange={(date) => { setStartDate(date); setMentoringTabPageNumber(1) }}
                  placeholderText="Start Date"
                  dateFormat="MM-dd-yyyy"
                />
              </div>
              {(tabIndex === 0 || tabIndex === 2) && <div className="resourcetypess allchildSelect">
                <div className="gobackcontainer">
                  <Multiselect className="form-select w-100 float-start saveResourceSelectss"
                    options={childData}
                    onSelect={(e) => {
                      e.forEach((val) => {
                        setChildId(val.value)
                      })
                    }}
                    displayValue="name"
                    placeholder="Select Student"
                    showArrow={true}
                    showCheckbox
                    selectedValues={childId ? [childData.find(item => item.value === childId)] : []}
                  />
                </div>
              </div>}
              {tabIndex === 1 && <>
                {resourceList.length > 1 ?
                  <div className="resourcetypess">
                    <Multiselect className="form-select w-100 float-start saveResourceSelectss"
                      options={resourceList}
                      onSelect={(e) => {
                        e?.forEach((val, i) => {
                          setSelectedResource(val.value);
                        });
                      }}
                      displayValue="name"
                      placeholder="Select Resource"
                      showCheckbox
                      showArrow={true}
                      selectedValues={selectedResource ? [resourceList.find(item => item.value === selectedResource)] : [{ name: 'All', value: '' }]}
                    />
                  </div> :
                  <div className="resourcetypess">
                    <Multiselect className="form-select w-100 float-start saveResourceSelectss"
                      options={resourceList}
                      displayValue="name"
                      placeholder="Select Resource"
                      showArrow={true}
                    />
                  </div>
                }
                <div className="resourcetypess">
                  <Multiselect className="form-select w-100 float-start saveResourceSelectss"
                    options={UserOptions}
                    onSelect={(e) => {
                      e?.forEach((val, i) => {
                        setUserType(val.value);
                      });
                    }}
                    displayValue="name"
                    placeholder="User"
                    showArrow={true}
                    showCheckbox
                    selectedValues={userType ? [UserOptions.find(item => item.value === userType)] : []}
                  />
                </div></>}
              <div className="resourcetypess">
                <Multiselect className="form-select w-100 float-start saveResourceSelectss"
                  options={orderByOptions}
                  onSelect={handleOrderSelection}
                  // singleSelect
                  displayValue="name"
                  placeholder="Order by"
                  showArrow={true}
                  showCheckbox
                  selectedValues={orderBy ? [orderByOptions.find(item => item.value === orderBy)] : []}
                />
              </div>
            </div>
          </div>
          <Tabs selectedIndex={tabIndex} onSelect={(index) => { setTabIndex(index); setCurrentPage(1) }}>
            <TabList>
              <Tab>
                <h3 className="pb-0">Resource Purchase</h3>
              </Tab>
              <Tab>
                <h3 className="pb-0">Booking Session</h3>
              </Tab>
              <Tab>
                <h3 className="pb-0">Group Resource Purchase</h3>
              </Tab>
            </TabList>
            <TabPanel>
              <div className="bookmoniterdivbox">
                <ul>
                  {purchaseHistoryData && purchaseHistoryData?.length > 0 ? (
                    purchaseHistoryData.map((val, i) => {
                      return (
                        <li key={i + 1}>
                          <div className="sprash-name">
                            <div className="sprash-name-lt cursor-pointer" onClick={() => redirectToDetail(val?.resource_id)}>
                              <div className={`img-divsr${val?.tbl_user_resource?.resource_type}`}>
                                {getResourceImage(val?.tbl_user_resource?.resource_type)}
                              </div>
                            </div>
                            <div className="sprash-name-rts cursor-pointer" onClick={() => redirectToDetail(val?.resource_id)}>
                              <h3>{val?.tbl_user_resource?.resource_title} </h3>
                            </div>
                          </div>
                          <div className="sprash-name-rt">
                            <div className="sprash-name-box">
                              <h4> Funding Available</h4>
                              <p> {val?.tbl_user_resource?.public_funding} </p>
                            </div>
                            <div className="sprash-name-box">
                              <h4> Purchase Date</h4>
                              <p>
                                {" "}
                                {moment(val?.created_at).format("MM-DD-YYYY")}{" "}
                              </p>
                            </div>
                            <div className="sprash-name-box">
                              <h4> Amount</h4>
                              <p> ${Number(val?.total_amount).toFixed(2)}</p>
                            </div>
                            <div className="sprash-name-box">
                              <button
                                className="invoicebtn"
                                onClick={() => {
                                  downloadReceipt(
                                    val?.tbl_payment_history?.invoice_pdf_link
                                  );
                                }}
                              >
                                Invoice
                              </button>
                              {val?.tbl_user_resource?.is_deleted === "no" ? (
                                <button
                                  className="invoicebtn"
                                  onClick={() =>
                                    setSavePopupStatus({
                                      visible: true,
                                      selectedResource: {
                                        objectID: val.resource_id,
                                        purchase_id: val.id,
                                      },
                                    })
                                  }
                                >
                                  Save
                                </button>
                              ) : (
                                <button className="invoicebtn" disabled>
                                  Save
                                </button>
                              )}
                              {
                                val?.tbl_user_resource?.tbl_user?.id !== userId ? <button
                                  className="invoicebtn"
                                  onClick={() => {
                                    AddUser(val?.tbl_user_resource?.tbl_user?.id, { "name": val?.tbl_user_resource?.tbl_user?.firstName + " " + val?.tbl_user_resource?.tbl_user?.lastName, "image": uimage + val?.tbl_user_resource?.tbl_user?.photo })
                                  }}
                                >
                                  Chat
                                </button>
                                  : <button
                                    className="invoicebtn"

                                  >
                                    User
                                  </button>}
                            </div>
                          </div>
                        </li>
                      );
                    })
                  )
                    :
                    (<li>Currently no purchase history</li>)
                  }
                </ul>
                {hasMore > 0 && (
                  <div className="text-center w-100">
                    <button
                      className="border-0 btn-md btn"
                      onClick={handleLoadMore}
                    >
                      Load More
                    </button>
                  </div>
                )}
              </div>
            </TabPanel>
            <TabPanel>
              <div className="bookmoniterdivbox">
                <ul>
                  {tab2Data && tab2Data?.length > 0 ? (
                    tab2Data.map((val, i) => {
                      return (
                        <li key={i + 1}>
                          <div className="sprash-name">
                            <div className="sprash-name-lt cursor-pointer" onClick={() => redirectToDetail(val?.tbl_user_resource?.id)}>
                              <div className={`img-divsr${val?.tbl_user_resource?.resource_type}`}>
                                {getResourceImage(val?.tbl_user_resource?.resource_type)}
                              </div>
                            </div>
                            <div className="sprash-name-rts cursor-pointer" onClick={() => redirectToDetail(val?.tbl_user_resource?.id)}>
                              <h3>{val?.tbl_user_resource?.resource_title} </h3>
                            </div>
                          </div>
                          <div className="sprash-name-rt">
                            {userType === "user" ? <div className="sprash-name-box">
                              <h4> Mentor Name</h4>
                              <p className="mentorp"> {val?.sold_by_user?.firstName + " " + val?.sold_by_user?.lastName} </p>
                            </div> : <div className="sprash-name-box">
                              <h4> Customer Name</h4>
                              <p> {val?.purchased_user?.firstName + " " + val?.purchased_user?.lastName} </p>
                            </div>}
                            <div className="sprash-name-box">
                              <h4> Student Name</h4>
                              <p>
                                {" "}
                                {val?.tbl_child?.firstName + " " + val?.tbl_child?.lastName}{" "}
                              </p>
                            </div>
                            {userType === "user" ?
                              <div className="sprash-name-box">
                                <h4> Amount</h4>
                                <p> ${Number(val?.tbl_payment_history?.total_amount).toFixed(2)}</p>
                              </div> :
                              <div className="sprash-name-box">
                                <h4> Amount</h4>
                                <p> ${Number(val?.mentor_amount).toFixed(2)}</p>
                              </div>}
                            <div className="sprash-name-box">
                              <h4> Purchase Date</h4>
                              <p>
                                {" "}
                                {moment(val?.created_at).format("MM-DD-YYYY")}{" "}
                              </p>
                            </div>
                            <div className="sprash-name-box">
                              <button
                                className="invoicebtn"
                                onClick={() => {
                                  userType === "user" ?
                                    downloadReceipt(
                                      val?.tbl_payment_history?.invoice_pdf_link
                                    ) :
                                    downloadReceipt(
                                      val?.mentor_invoice_pdf_link
                                    );
                                }}
                              >
                                Invoice
                              </button>
                              {
                                userType === "user" ? <button
                                  className="invoicebtn"
                                  onClick={() => {
                                    AddUser(val?.sold_by_user?.id, { "name": val?.sold_by_user?.firstName + " " + val?.sold_by_user?.lastName, "image": uimage + val?.sold_by_user?.photo })
                                  }}
                                >
                                  Chat
                                </button>
                                  : <button
                                    className="invoicebtn"
                                    onClick={() => {
                                      AddUser(val?.purchased_user?.id, { "name": val?.purchased_user?.firstName + " " + val?.purchased_user?.lastName, "image": uimage + val?.purchased_user?.photo })
                                    }}
                                  >
                                    Chat
                                  </button>}

                              <button
                                className="invoicebtn"
                                onClick={() => { setModalData(val?.tbl_user_mentoring_session_datetimes); setOpen(true) }}
                              >
                                Slots
                              </button>
                            </div>
                          </div>
                        </li>
                      );
                    })
                  ) : (
                    <li>Currently no purchase history</li>
                  )
                  }
                </ul>
                {hasMoreMentoring > 0 && (
                  <div className="text-center w-100">
                    <button
                      className="border-0 btn-md btn"
                      onClick={handleLoadMore2}
                    >
                      Load More
                    </button>
                  </div>
                )}
              </div>
            </TabPanel>
            <TabPanel>
              <div className="bookmoniterdivbox">
                <ul>
                  {groupPurchaseHistoryData && groupPurchaseHistoryData?.length > 0 ? (
                    groupPurchaseHistoryData.map((val, i) => {
                      return (
                        <li key={i + 1}>
                          <div className="sprash-name">
                            <div className="sprash-name-lt cursor-pointer" onClick={() => redirectToDetail(val?.resource_id)}>
                              <div className={`img-divsr${val?.tbl_user_resource?.resource_type}`}>
                                {getResourceImage(val?.tbl_user_resource?.resource_type)}
                              </div>
                            </div>
                            <div className="sprash-name-rts cursor-pointer" onClick={() => redirectToDetail(val?.resource_id)}>
                              <h3>{val?.tbl_user_resource?.resource_title} </h3>
                              <p>{val?.tbl_group?.group_name}</p>
                            </div>
                          </div>
                          <div className="sprash-name-rt">
                            <div className="sprash-name-box">
                              <h4> Funding Available</h4>
                              <p> {val?.tbl_user_resource?.public_funding} </p>
                            </div>
                            <div className="sprash-name-box">
                              <h4> Purchase Date</h4>
                              <p>
                                {" "}
                                {moment(val?.created_at).format("MM-DD-YYYY")}{" "}
                              </p>
                            </div>
                            <div className="sprash-name-box">
                              <h4> Amount</h4>
                              <p> ${Number(val?.total_amount).toFixed(2)}</p>
                            </div>
                            <div className="sprash-name-box">
                              <button
                                className="invoicebtn"
                                onClick={() => {
                                  downloadReceipt(
                                    val?.tbl_payment_history?.invoice_pdf_link
                                  );
                                }}
                              >
                                Invoice
                              </button>
                              {val?.tbl_user_resource?.is_deleted === "no" ? (
                                <button
                                  className="invoicebtn"
                                  onClick={() =>
                                    setSavePopupStatus({
                                      visible: true,
                                      selectedResource: {
                                        objectID: val.resource_id,
                                        purchase_id: val.id,
                                      },
                                    })
                                  }
                                >
                                  Save
                                </button>
                              ) : (
                                <button className="invoicebtn" disabled>
                                  Save
                                </button>
                              )}
                            </div>
                          </div>
                        </li>
                      );
                    })) : (
                    <li>Currently no purchase history</li>
                  )}
                </ul>
                {hasMore > 0 && (
                  <div className="text-center w-100">
                    <button
                      className="border-0 btn-md btn"
                      onClick={handleLoadMore}
                    >
                      Load More
                    </button>
                  </div>
                )}
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
      {savePopupStatus.selectedResource && savePopupStatus.visible && (
        <Saveresourcespopup
          data={savePopupStatus}
          handleResourceSaveOpen={(data) =>
            setSavePopupStatus({ visible: true, selectedResource: data })
          }
          handleResourceSaveClose={() =>
            setSavePopupStatus({ visible: false, selectedResource: null })
          }
        />
      )}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ padding: "20px" }}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
            style={{ color: "#2D3134", fontSize: "20px", marginTop: "20px", lineHeight: "36px" }}
          >
            <div className="sessiondateTime">
              <table>
                <tr>
                  <th style={{ width: "65%", fontSize: "16px" }}>Session Date Time</th>
                  <th style={{ width: "35%", fontSize: "16px" }}>Payment Status</th>
                </tr>
                <tbody>
                  {userType === "user" ? modalData?.map(v => (<>
                    <tr style={{ fontSize: "16px", fontWeight: "normal" }}>
                      <td><p style={{ lineHeight: "20px", marginBottom: "15px" }}>{moment(v?.user_session_date).format("MM-DD-YYYY")}&nbsp;
                        ({moment(v?.user_session_time, "HH:mm:ss A").format("hh:mm A")}{" - "}{moment(v?.user_session_end_time, "HH:mm:ss A").format("hh:mm A")})</p>
                        {v?.booking_status === "Pending" && <button type="button" className="btn btn-primary"> Upcoming</button>}
                        {v?.booking_status === "Cancelled" && <button type="button" className="btn btn-danger"> Cancelled</button>}
                        {v?.booking_status === "Completed" && <button type="button" className="btn btn-success"> Completed</button>}
                      </td>
                      <td>{v?.status}</td>
                    </tr>
                  </>)) : modalData?.map(v => (<>
                    <tr>
                      <td>{moment(v?.session_date, "YYYY-MM-DD").format("MM-DD-YYYY")}&nbsp;
                        ({moment(v?.session_time, "HH:mm:ss A").format("hh:mm A")}{" - "}{moment(v?.session_end_time, "HH:mm:ss A").format("hh:mm A")})<br />
                        {v?.booking_status === "Pending" && <button type="button" className="btn btn-primary"> Upcoming</button>}
                        {v?.booking_status === "Cancelled" && <button type="button" className="btn btn-danger"> Cancelled</button>}
                        {v?.booking_status === "Completed" && <button type="button" className="btn btn-success"> Completed</button>}
                      </td>
                      <td>{v?.status}</td>
                    </tr>
                  </>))}
                </tbody>
              </table>
            </div>
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 3 }}
            className="d-flex justify-content-center gap-4"
          >
            <button className="child-delete" onClick={() => setOpen(false)}>
              Close
            </button>{" "}
          </Typography>
        </Box>
      </Modal>
    </>
  );
}

export default ServerPaymentHistory;
