import React, { useEffect, useRef } from "react";
import { useState } from "react";
import './Signup.css';
import { Link, useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { makePostRequest } from "../../services/api";
import { makeGetRequest } from "../../services/api";
import { URLS } from "../../constants";
import { useDispatch } from 'react-redux';
import { AiFillEye } from 'react-icons/ai';
import { AiFillEyeInvisible } from 'react-icons/ai';
import { toastifyMessage, Emailvalidation } from "../../utilities/CustomFunctions"
import { commonService } from "../../services/common.services"
import { getSelectFrist } from "../../store/reducers/userReducer";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import Head from "../Layout.WebAppMate/head/Head";
import Select from "react-select";

/*
	 * Signup  function is used to do Sign up  and save user data
	 *
	 * @author Naresh Kumar <naresh.gulati@webappmate.com>
	 * created on: 10-4-2023
	 * */

function Signup() {
	const dispatch = useDispatch()
	const [email, setEmail] = useState('');
	const [pass, setPass] = useState('');
	const [confirmpass, setConfirmpass] = useState('');
	const [passicon, setpassicon] = useState(false)
	const [passicon1, setpassicon1] = useState(false)
	const navigate = useNavigate();
	const [user, setuser] = useState([])
	const [error, setError] = useState({});
	const [selectedPlan, setselectedPlan] = useState("free");
	const [userTypeData, setUserTypeData] = useState([])
	const [phoneno, setPhones] = useState("");
	const focusRef = useRef(null);
	const emailFocus = useRef(null);
	const phoneFocus = useRef(null);
	const passFocus = useRef(null);
	const confirmPassFocus = useRef();
	const [showspin, setshowspin] = useState(false)
	let Phonevalidation = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;

	const handleuser = () => {
		makeGetRequest(URLS.userroleget, null, null)
			.then((res) => {
				const result = res.data?.map(item => ({
					value: item?.id,
					label: item?.role_name
				}));
				setuser(result)
			})
			.catch((error) => {
			})
	}
	//==== function for signup error handling and submit data====
	const SignUp = (e) => {
		e.preventDefault();
		var data = {
			"email": email,
			"phone": phoneno,
			"password": pass,
			"user_type_role_id": userTypeData.map(val => val.value) || [],
			"subscription_type": selectedPlan
		}
		if (validate()) {
			setshowspin(true)
			makePostRequest(URLS.userSignUp, null, data, null)
				.then(res => {
					setshowspin(false)
					console.log(res)
					if (res.code === 201) {
						toastifyMessage("User added successfully.", "success");
						localStorage.setItem("auth-token", res.data.authorization);
						// var decoded = res.data.authorization ? jwt_decode(res.data.authorization) : "";
						dispatch(getSelectFrist(true))
						localStorage.setItem("values", "")
						localStorage.setItem("useractive", email)
						setTimeout(() => {
							navigate('/parent-registration-form', { state: selectedPlan })
						}, 2000)
					}
					else if (res.code === 400) {
						setshowspin(false)
						toastifyMessage(res.message, "error");
					}

				}).catch(error => {
					console.log(error);
				});
		}
	}
	// this function use for validation
	const validate = (e) => {
		const errors = {};
		if (userTypeData.length <= 0) {
			errors.user = "Please choose user type";
		}
		if (email === '') {
			errors.email = "Please enter email address.";
		} else if (!Emailvalidation.test(email)) {
			errors.email = "Please enter a valid email address.";
		}
		if (phoneno === '') {
			errors.phone = "Please enter phone number.";
		} else if (!Phonevalidation.test(phoneno)) {
			errors.phone = "Please enter a valid mobile number.";
		} else if (phoneno.length !== 14) {
			errors.phone = 'Please enter a vaild mobile number';
		}
		if (pass === '') {
			errors.pass = "Please enter your password.";
		} else if (pass.length < 8 || pass.length > 20) {
			errors.pass = 'Please enter a password between 8 and 20 characters long.';
		}
		else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/.test(pass)) {
			errors.pass = 'Please include at least one lowercase letter, one uppercase letter, one number, and one special character.';
		}
		if (confirmpass === '') {
			errors.cpass = "Please confirm password.";
		} else if (pass !== confirmpass) {
			errors.cpass = "Passwords must match.";
		}
		setError(errors);
		if (userTypeData.length <= 0) {
			focusRef.current.focus();
		} else if (email === '') {
			emailFocus.current.focus();
		} else if (!Emailvalidation.test(email)) {
			emailFocus.current.focus();
		} else if (phoneno === '') {
			phoneFocus.current.focus();
		} else if (!Phonevalidation.test(phoneno)) {
			phoneFocus.current.focus();
		} else if (phoneno.length !== 14) {
			phoneFocus.current.focus();
		} else if (pass === '') {
			passFocus.current.focus();
		} else if (pass.length < 8 || pass.length > 20) {
			passFocus.current.focus();
		} else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/.test(pass)) {
			passFocus.current.focus();
		} else if (confirmpass === '') {
			confirmPassFocus.current.focus();
		} else if (pass !== confirmpass) {
			confirmPassFocus.current.focus();
		}
		return Object.keys(errors).length === 0;
	}
	//=== end of function for signup error handling and submit data====
	
	const urlParams = new URLSearchParams(window.location.search);
	const passKey = urlParams.get('passKey');

	useEffect(() => {
		handleuser()
		if (passKey === "pass") {
			document.body.classList.add("signInActive");
			return () => {
				document.body.classList.remove("signInActive");
			};
		}
		document.body.classList.add("sign-up");
		return () => {
			document.body.classList.remove("sign-up");
		};
	}, [passKey]);

	// ==== UI for signup page===
	return (<>
			<Head title="Sign Up"
			content="Unlock Your Student's Potential: Sign Up for MatchED, Your Essential Tool for Personalized Education Resource Recommendations."
			 ></Head>
		<form className="frm" onSubmit={SignUp}>
			<div className="signup">
				<div className="signupouter">
					{showspin &&
						<div className="sharepopupOuter backgroundOpacity1">
							<FourDotsLoader />
						</div>
					}
					<h1>Sign Up</h1>
					<div className={`mul-sel-div ${userTypeData.length > 2 ? "selectParent" : "selectChild"}`}>
						<Select
							value={userTypeData}
							options={user}
							isMulti
							selectMultiple={true}
							onChange={(e) => setUserTypeData(e)}
							ref={focusRef}
							placeholder="Type of User"
							autoComplete="off"
						/>
					</div>
					<span className="error">{error.user}</span>
					<div className="form-group">
						<input type="email" ref={emailFocus}
							className="form-control" id="inputEmail" onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
						
					</div>
					<span className="error">{error.email}</span>
					<div className="form-group">
						<input type="text" className="form-control" id="inputPassword" defaultcountry="US"
							ref={phoneFocus} onChange={(e) => setPhones(commonService.formatPhoneNumebr(e.target.value))} value={phoneno} placeholder="Phone Number" maxlength="14" />
						
					</div>
					<span className="error">{error.phone}</span>
					<div className="form-group">
						<input type={passicon === false ? "password" : "text"} ref={passFocus}
							className="form-control" id="inputEmail" onChange={(e) => setPass(e.target.value)} placeholder="Password" />
					
						<span className="showhide" onClick={() => setpassicon(!passicon)} >
							{passicon === true ?
								<AiFillEye /> : <AiFillEyeInvisible />}
						</span>
					</div>
					<span className="error">{error.pass}</span>
					<div className="form-group">
						<input type={passicon1 === false ? "password" : "text"} ref={confirmPassFocus}
							className="form-control" id="inputPassword" onChange={(e) => setConfirmpass(e.target.value)} placeholder="Confirm Password" />
						
						<span className="showhide" onClick={() => setpassicon1(!passicon1)} >
							{passicon1 === true ?
								<AiFillEye /> : <AiFillEyeInvisible />}
						</span>
					</div>
					<span className="error">{error.cpass}</span>
					<div className="rember"><div className="form-checks">
						<input className="form-check-input" type="checkbox" id="invalidCheck" value="" />
						<label className="form-check-label remember" htmlFor="invalidCheck">I consent to receive SMS updates and notifications from MatchED.</label>
					</div>
					</div>
					<h3 className="text-start mt-2">Subscription  Options</h3>
					<div className="freepaidSwitch">
						<div className='d-flex align-items-center'>
							<p className="mx-3 mt-0">Free</p>
							<label className="toggle-switch me-3">
								<input type="checkbox" checked={selectedPlan !== "free" ? true : false} onChange={(e) => setselectedPlan(selectedPlan === "free" ? "paid" : "free")} />
								<span className="switch" />
							</label>
							<p className='ms-3 mt-0'>Paid</p>
							<Link to="/view-subscription" target="_blank" className="benefits">view benefits</Link>
						</div>
					</div>
					<div className="clearnone"></div>
					<p>By clicking Create an Account, you agree to the <br />
						<span className="c-pointer" onClick={() => window.open("https://www.termsfeed.com/live/bf60c2f1-cf99-4e82-8e3e-d3fb24d5e16e", "_blank")}>User Agreement, </span>
						<span className="c-pointer" onClick={() => window.open("https://www.termsfeed.com/live/0651ae54-2a29-440a-a3a3-2d323cf06075", "_blank")}>Privacy Policy, </span>
						<span className="c-pointer" onClick={() => window.open("https://www.termsfeed.com/live/ab1d0db4-d3bf-4bbe-872a-aa7acee6ae5a", "_blank")}>Disclaimer</span> and
						<span className="c-pointer" onClick={() => window.open("https://www.termsfeed.com/live/e3499200-974d-4306-af41-44ac711c41d6", "_blank")}> Terms and Conditions</span>.
					</p>
					<div className="col-lg-12 text-center">
						<button type="submit" className="btn1" >Create an Account</button>
					</div>
					<div className="bottomText">Already a member? <Link to="/sign-in?passKey=pass">Sign in</Link></div>
				</div >
			</div >
		</form >
	</>);
}
export default Signup;