import React, { useEffect, useState } from 'react'
import { URLS } from '../../constants';
import { imageUrl, makePostRequest } from '../../services/api';
import { forceDownload, userToken, toastifyMessage, style } from '../../utilities/CustomFunctions';
import moment from "moment"
import { useNavigate } from 'react-router-dom';
import { Box, Modal, Typography } from '@mui/material';
import FourDotsLoader from '../Common.WebAppMate/FourDoted';
import Head from '../Layout.WebAppMate/head/Head';
const MySubscription = () => {
  const [listData, setListData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const headers = { Authorization: "Bearer " + userToken() };
  const [open, setOpen] = useState(false)
  const [cancleSubId, setCancleSubId] = useState("")
  const navigate = useNavigate()

  //this function giving list of subscription
  const getData = async () => {
    setIsLoading(true)
    await makePostRequest(URLS.getUserSubscription, null, {}, { headers })
      .then((response) => {
        setListData(response?.data)
        setIsLoading(false)
      }).catch(e => {
        setIsLoading(false)
      })
  }
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [])

  // Toggle to open information panel
  const onInfo = (id) => {
    let data = [...listData]
    let newData = data?.map(v => v?.id === id ? { ...v, isOpen: !v?.isOpen } : v)
    setListData(newData)
  }
  const downloadInvoice = (data) => {
    if (data?.tbl_subscription_payment_histories?.[0]?.tbl_payment_history?.invoice_pdf_link) {
      let pdfFileName = data?.tbl_subscription_payment_histories?.[0]?.tbl_payment_history?.invoice_pdf_link?.split("/");
      forceDownload(imageUrl + data?.tbl_subscription_payment_histories?.[0]?.tbl_payment_history?.invoice_pdf_link, pdfFileName[2]);
    } else {
      toastifyMessage("No invoice available for download", "error");
    }
  }

  useEffect(() => {
    document.body.classList.add("mysubscription");
    return () => {
      document.body.classList.remove("mysubscription");
    };
  }, []);

  const CancleSub = async (id) => {
    const body = {
      id: id,
      subscription_canceled: "yes"
    }
    setIsLoading(true)
    await makePostRequest(URLS.subscriptionCanceled, null, body, { headers })
      .then(() => {
        getData()
        toastifyMessage("Subsription cancel successfully!", "success");
        setIsLoading(false)
        setOpen(false)
      }).catch(e => {
        console.log("response cancle", e)
        toastifyMessage("Something went wrong!", "error");
        setIsLoading(false)
        setOpen(false)
      })
  }
  return (
    <>
 <Head title="My Subscription"
        content="MatchED My Subscription: Explore Vendor 'Serve' Membership for Individuals and Groups. Provide MatchED Resource Accounts Searchable by All MatchED Users."
        name="My Subscription" ></Head>
            <div className="col-lg-10">
        {isLoading ? <div className="sharepopupOuter backgroundOpacity1">
          <FourDotsLoader />
        </div> :
          <div className="bookmoniterdiv">
            <div className='d-flex justify-content-between align-items-center' style={{ borderBottom: "1px solid #D9D9D9" }}><h2>My Subscription</h2>
              <button
                className="btn btn-md float-end addresource"
                onClick={() => navigate("/subscription")}
              >
                Upgrade Subscription{" "}
              </button></div>
            <div className="bookmoniterdivbox profileupcomings">
              <ul>
                {listData?.map(data => {
                  return <> <li>
                    <div className="sprash-name">
                      <div className="sprash-name-rts text-capitalize">
                        <h3>{data?.tbl_subscription_plan?.title?.replaceAll("_", " ")}</h3>
                        <p>{data?.tbl_subscription_plan?.payment_frequency_type} <span className='text-black'>
                          {data?.status === "active" &&
                            data?.subscription_canceled === "no" ? "- (Activated)" :
                            <>{data?.status === "pending" &&
                              data?.subscription_canceled === "no" ? "- (Upcoming)" : <>
                              {data?.tbl_subscription_plan?.payment_frequency_type !== "free" && data?.status === "active" &&
                                data?.subscription_canceled === "yes" ? "- (Cancelled) " : ""}</>}</>
                          }
                        </span></p>
                      </div>
                    </div>
                    <div className="sprash-name-rt">
                      <div className="sprash-name-box">
                        <h4> Validity</h4>
                        <p>{data?.tbl_subscription_plan?.payment_frequency_type !== "free" ? moment(data?.subscription_date)?.format("MM-DD-YYYY") + " - " + moment(data?.valid_upto)?.format("MM-DD-YYYY") : "Lifetime"}</p>
                      </div> <div className="sprash-name-box">
                        <h4> Price</h4>
                        <p>{data?.tbl_subscription_plan?.price === 0 ? "Free" : "$" + data?.tbl_subscription_plan?.price?.toFixed(2) + (data?.tbl_subscription_plan?.payment_frequency_type === "monthly" ? "/mo" : "/yr")} </p>
                      </div>
                      <div className="sprash-name-box">
                        <h4> Space used / allocated (MB)
                        </h4>
                        {data?.tbl_subscription_plan?.title.includes("MatchED Community") ?
                          <>
                            <p>{(+data?.space_used / 1024).toFixed(2)}/{(+data?.space_allocated / 1024).toFixed(2)} </p>
                            {data?.search_total_count && <p><span style={{ fontWeight: "700" }}>Searches:</span> {data?.total_count}/{data?.search_total_count}  {data?.search_total_count === "unlimited" ?
                              "" : <span>({((data?.total_count / data?.search_total_count) * 100).toFixed(0)}%)</span>}</p>}</> : "Feature not available in this plan"}
                      </div>
                      <div className="sprash-name-box">
                        {data?.tbl_subscription_plan?.price !== 0 && data?.status === "active" && <button className="invoicebtn" onClick={() => onInfo(data?.id)}>View</button>}
                        {data?.tbl_subscription_plan?.price !== 0 && data?.status === "active" &&
                          data?.subscription_canceled === "no" && <button className="invoicebtn" onClick={() => { setOpen(true); setCancleSubId(data?.id) }}>Cancel</button>}
                      </div>
                    </div>
                  </li>
                    {data?.isOpen && <>
                      <table>
                        <tr>
                          <th>#</th>
                          <th>Payment Date</th>
                          <th>Amount</th>
                          <th>Payment For</th>
                          <th>Invoice Link</th>
                        </tr>
                        <tr>
                          <td>{data?.current_plan_id}</td>
                          <td>{moment(data?.subscription_date)?.format("MM-DD-YYYY")}</td>
                          <td>${data?.tbl_subscription_plan?.price}{data?.tbl_subscription_plan?.payment_frequency_type === "monthly" ? "/mo" : "/yr"}</td>
                          <td>{data?.tbl_subscription_payment_histories?.[0]?.subscription_for}</td>
                          <td><div style={{ cursor: "pointer" }} onClick={() => downloadInvoice(data)}><i className="fa fa-download" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Download"></i></div></td>
                        </tr>
                      </table>
                      <hr />
                    </>}
                  </>
                })}
              </ul>
            </div>
          </div>}
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
            style={{ color: "#2D3134", fontSize: "20px", lineHeight: "36px" }}
          >
            Are you sure you want to cancel your subscription? Keep in mind that if you do cancel your current plan, automatic deductions from your card will cease, and your subscription will remain active until its expiry date.
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 3 }}
            className="d-flex justify-content-center gap-4"
          >
            <button className="child-delete" type="button" onClick={() => CancleSub(cancleSubId)}>
              Yes
            </button>{" "}
            <button className="child-delete" onClick={() => setOpen(false)}>
              No
            </button>{" "}
          </Typography>
        </Box>
      </Modal>
    </>
  )
}
export default MySubscription;