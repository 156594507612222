import axios from "axios";
import { URLS } from "../constants/urls";
import { makePostRequest, makePostRequestcancle } from "./api";
const CHATGPT_TOKEN = process.env.REACT_APP_CHATGPT_TOKEN;

const SearchService = {

  async makeDynamicQuery(filter, params, keyword, faith, character, token, keywordOr, Zip_code) {
    const { locationFilters } = params;
    let queryParams = {};
    if (locationFilters?.lat && locationFilters?.lng) {
      queryParams.aroundLatLng = `${locationFilters.lat}, ${locationFilters.lng}`;
      queryParams.aroundRadius = locationFilters.radius;
    }
    const filters = {
      ...filter,
    };
    // console.log(filters)
    const dynamicQuery = Object.entries(filters)
      .map(([key, value]) => {
        if (Array.isArray(value) && value.length) {
          const values = value.map(element => `${key}:"${element}"`).join(" OR ");
          return `(${values})`;
        } else if (key === 'start_date') {
          return `(${key}>=${value})`;
        } else if (key === 'end_date') {
          return `(${key}<=${value})`;
        } else {
          return `${key}:"${value}"`;
        }
      })
      .join(" AND ");
    console.log("dynamicQuery:--And", dynamicQuery)
    const splitData = dynamicQuery.split("AND").filter(criteria => !criteria.includes("faith_affiliation"))
    // console.log("splitData", splitData)
    let secondQuery = ""
    for (let i = 0; i < splitData.length; i++) {
      // if (splitData[i]?.includes("faith_affiliation") && splitData[i + 1]?.includes("users_role")) {
      //   // secondQuery += splitData[i] + "OR"
      // } else
      if ((splitData[i]?.includes("grade") || splitData[i]?.includes("content_type")) && (splitData[i + 1]?.includes("timeframe") || splitData[i + 1]?.includes("instructional_style") || splitData[i + 1]?.includes("dual_enrollment_resource") || splitData[i + 1]?.includes("accredited_resource"))) {
        secondQuery += splitData[i] + "AND"
      } else if ((splitData[i]?.includes("timeframe") || splitData[i]?.includes("instructional_style") || splitData[i]?.includes("dual_enrollment_resource") || splitData[i]?.includes("accredited_resource")) && (splitData[i + 1]?.includes("instructional_style") || splitData[i + 1]?.includes("dual_enrollment_resource") || splitData[i + 1]?.includes("accredited_resource"))) {
        secondQuery += splitData[i] + "OR"
      } else if (splitData[i]?.includes("location_type") && splitData[i + 1]?.includes("customer_type")) {
        secondQuery += splitData[i] + "OR"
      } else if (splitData[i]?.includes("start_date")) {
        secondQuery += `(start_date>=${filters?.start_date}) ${i === splitData.length - 1 ? "" : "OR"} `
      } else if (splitData[i]?.includes("end_date")) {
        secondQuery += `(end_date>=${filters?.end_date}) ${i === splitData.length - 1 ? "" : "AND"}`
        // secondQuery += `(end_date<=${filters?.end_date}) ${i === splitData.length - 1 ? "" : "AND"}`
      } else if (i === splitData.length - 1) {
        secondQuery += splitData[i]
      } else {
        secondQuery += splitData[i] + "AND"
      }
    }
    console.log("OR query::", secondQuery)
    const data = {
      "zipcode":  filter?.location_type?.includes("Online") && !filter?.location_type?.includes("In Person") ? "" : Zip_code ,
      "filters": dynamicQuery,
      "keyboard": keyword.keywords,
      "or_keyboard": keywordOr,
      "sorting": faith,
      "filter_or": secondQuery,
      "character_child": character,
      "aroundLatLng": filter?.location_type?.includes("Online") && !filter?.location_type?.includes("In Person") ? "" : ((locationFilters?.lat && locationFilters?.lng) ? `${locationFilters.lat}, ${locationFilters.lng}` : "") ,
      "aroundRadius": filter?.location_type?.includes("Online") && !filter?.location_type?.includes("In Person") ? "" : ((locationFilters?.lat && locationFilters?.lng) ? locationFilters.radius : "" ),
    }
    let result = []
    console.log("data", data)
    await makePostRequestcancle(URLS.searchAlgolia, null, data, null, token)
      .then((res) => {
        console.log("res:::", res)
        if (res.code === 200) {
          result = res.data
        }
      }).catch((error) => {
        console.log("error::", error)
      })
    return result
  },

  async searchChatGPT(query) {
    // var id;
    // const start = performance.now();
    const q = [
      query,
    ]
    const apiCalls = q.map(prompt => {
      const body = {
        // model: "gpt-3.5-turbo-instruct",
        model: "gpt-4-1106-preview",
        prompt: prompt,
        temperature: 1,
        max_tokens: 3000,
        n: 1
      };
      // const payload = {
      //   "chatgpt_request": prompt,
      // }
      // makePostRequest(URLS.chatGptHistory, null, payload, null)
      //   .then((res) => {
      //     id = res?.data?.id
      //   }).catch((error) => {
      //     console.log("error:::", error)
      //   })
      return axios.post('https://api.openai.com/v1/completions', body, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': CHATGPT_TOKEN
        }
      });
    });
    try {

      const responses = await Promise.all(apiCalls);
      // const tokens = responses.map(response => response.data.usage)
      // const payload = {
      //   "id": id,
      //   "prompt_tokens": tokens[0]?.prompt_tokens,
      //   "completion_tokens": tokens[0]?.completion_tokens,
      //   "total_tokens": tokens[0]?.total_tokens,
      //   "chatgpt_respose": responses
      // }
      // makePostRequest(URLS.chatGptHistory, null, payload, null)
      //   .then((res) => {
      //   }).catch((error) => {
      //     console.log("error:::", error)
      //   })
      const result = responses.map(response => response.data.choices);
      const arr = result[0].map(val => val.text)
      return arr;

    } catch (error) {
      console.error('Error:', error);
    }
  },

  async searchChatGPT2(query, cancelToken) {
    console.log("query", query)
    let ChatGptData
    try {
      var id;
      const body = {
        "chatgpt_request": query,
      }
      makePostRequest(URLS.chatGptHistory, null, body, null)
        .then((res) => {
          id = res?.data?.id
        }).catch((error) => {
          console.log("error:::", error)
        })
      const payload = {
        // "model": "gpt-4-1106-preview",
        // "model": "gpt-4-turbo",
        "model": "gpt-4o",
        "temperature": 1,
        "messages": [
          {
            "role": "system",
            "content": query
          }
        ]
      }
      const response = await axios.post('https://api.openai.com/v1/chat/completions', payload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': CHATGPT_TOKEN
        },
        cancelToken: cancelToken
      })
      console.log("response", response)
      const data = {
        "id": id,
        ...response.data?.usage,
        "chatgpt_respose": [response],
        "request_type": "web"

      }
      makePostRequest(URLS.chatGptHistory, null, data, null)
        .then((res) => {
        }).catch((error) => {
          console.log("error:::", error)
        })
      const arr = response?.data.choices[0]?.message?.content;
      let result = arr.substring(arr.indexOf('['), arr.indexOf(']') + 1);
      const jsonStartIndex = arr.indexOf('```json') + 7;
      const jsonEndIndex = arr.lastIndexOf('```');
      const jsonString = arr.substring(jsonStartIndex, jsonEndIndex);
      const parsedData = JSON.parse(result) || JSON.parse(jsonString);
      ChatGptData = parsedData;
    } catch (error) {
      console.log("error", error)
      if (axios.isCancel(error)) {
        ChatGptData = false
      }

    }

    return ChatGptData;
  }

};
export default SearchService;
