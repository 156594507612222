import React, { Component } from "react";
import './footer.css';
import { useNavigate } from "react-router-dom";

const Footer = () => {
  let activeuser = localStorage.getItem('useractive');
  const naviget = useNavigate();
  return (
  <div className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-9">
            <div className="row">
              <div className="col-sm-6 col-md-3 col-lg-3 col-6 padleft100">
                <h6>Our Company</h6>
                <ul>
                  <li><a href="https://www.matched-hmss.com/our-mission" target="_blank" >Overview</a></li>
                  <li><a href="https://www.matched-hmss.com/our-team" target="_blank"> Our Team</a></li>
                  <li><a href="https://www.matched-hmss.com/why-now" target="_blank"> Why Now?</a></li>
                </ul>
              </div>
              <div className="col-sm-6 col-md-3 col-lg-3 col-6">
                <h6> &nbsp; </h6>
                <ul>
                  <li><a href="https://www.matched-hmss.com/" target="_blank"> Become an Investor</a></li>
                  <li><a href="https://matchedcommunity.zendesk.com/hc/en-us/requests/new" target="_blank"> Contact Us</a></li>

                </ul>
              </div>
              <div className="col-sm-6 col-md-3 col-lg-3 col-6">
                <h6>Community</h6>
                <ul>
                  <li><a href="#" referrerPolicy="no-referrer" onClick={() => !activeuser ? naviget("/sign-up") : naviget("/search-page")}> Join Our Community</a></li>
                  <li><a href="#" referrerPolicy="no-referrer" onClick={() => !activeuser ? naviget("/sign-up") : naviget("/purchase-pages-friend")}> Refer a Friend</a></li>
                </ul>
              </div>
              <div className="col-sm-6 col-md-3 col-lg-3 col-6">
                <h6>Mentoring</h6>
                <ul>
                  <li><a href="#" referrerPolicy="no-referrer" onClick={() => !activeuser ? naviget("/sign-up") : naviget("/serve-dashboard")}> Become a Mentor</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 bottomLine">
          </div>
        </div>
        <div className="copyright">
          <div className="copyright-text">
            <span>MatchED {(new Date().getFullYear())}</span>
          </div>
          <ul>
            <li><a href="https://matchedcommunity.zendesk.com/hc/en-us/requests/new" target="_blank"  referrerPolicy="no-referrer"> Help </a></li>
            <li><a className="c-pointer" referrerPolicy="no-referrer" onClick={() => window.open("https://www.termsfeed.com/live/0651ae54-2a29-440a-a3a3-2d323cf06075", "_blank")}> Privacy </a></li>
            <li><a className="c-pointer" referrerPolicy="no-referrer" onClick={() => window.open("https://www.termsfeed.com/live/e3499200-974d-4306-af41-44ac711c41d6", "_blank")}> Terms </a></li>
            <li><a className="c-pointer" referrerPolicy="no-referrer" onClick={() => window.open("https://www.termsfeed.com/live/bf60c2f1-cf99-4e82-8e3e-d3fb24d5e16e", "_blank")}> User Agreement </a></li>
            <li><a className="c-pointer" referrerPolicy="no-referrer" onClick={() => window.open("https://www.termsfeed.com/live/ab1d0db4-d3bf-4bbe-872a-aa7acee6ae5a", "_blank")}> Disclaimer </a></li>
         </ul>
         </div>
       </div>
    </div>
   );
}
export default Footer;