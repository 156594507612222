import "./saveresourcespopup.css";
import "./reportpopup.css";
import { useNavigate } from "react-router-dom";

/**
 * create components is used for save resource
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 */

function BookaMentorpopup(props) {
  const naviget = useNavigate()
  return <>
    <div className="sharepopupOuter">
      <div className="pop-up-container">
        <span
          className="close-btn"
          onClick={() => props.handleResourceSaveClose()}
        >
          <i className="fa fa-close closecs"></i>
        </span>
        <h3> Book a Session </h3>
        <div className="bookmoniterdivbox">
          <ul>
            {props?.data?.selectedResource?.data?.map((val) => {
              return <>
                {
                  (val?.price?.toFixed(2) <= 0 && props?.data?.flag) && < li >
                    <div className="sprash-name-rt" style={{ width: "100%" }}>
                      <div className="sprash-name-box">
                        <h4> Schedule Type
                        </h4>
                        <p> {val?.schedule_type === "1" ? "Group Session" : "Individual Session"} </p>
                      </div>
                      <div className="sprash-name-box">
                        <h4> Time Slot
                        </h4>
                        <p> {val?.duration} minutes </p>
                      </div>
                      <div className="sprash-name-box">
                        <button className="invoicebtn" onClick={() => { naviget(`/booka-session-manualpicking/${val?.id}/${props?.data?.flag ? "yes" : "no"}`) }}>Availability</button>
                      </div>
                    </div>
                  </li >
                }
                {(val?.price?.toFixed(2) > 0 && props?.data?.flag ===false) && < li >
                  <div className="sprash-name-rt" style={{ width: "100%" }}>
                    <div className="sprash-name-box">
                      <h4> Schedule Type
                      </h4>
                      <p> {val?.schedule_type === "1" ? "Group Session" : "Individual Session"} </p>
                    </div>
                    <div className="sprash-name-box">
                      <h4> Time Slot
                      </h4>
                      <p> {val?.duration} minutes </p>
                    </div>
                    <div className="sprash-name-box">
                      <h4> Amount
                      </h4>
                      <p> ${val?.price?.toFixed(2)} </p>
                    </div>
                    <div className="sprash-name-box">
                      <button className="invoicebtn" onClick={() => { naviget(`/booka-session-manualpicking/${val?.id}/${props?.data?.flag ? "yes" : "no"}`) }}>Availability</button>
                    </div>
                  </div>
                </li >}
              </>
            })
            }
          </ul>
        </div>
      </div>
    </div >

  </>
}
export default BookaMentorpopup;
