import React, { useEffect, useRef, useState } from "react";
import "./yourgroups3.css";
import { URLS } from "../../constants";
import { decodeToken, getTime, groupMessagesByDay, handleFileDownload, toastifyMessage } from "../../utilities/CustomFunctions";
import {
    imageUrl,
    makeFormDateRequset,
    makePostRequestForAll,
} from "../../services/api";
import { getFileIcon } from "../Chat.WebAppMate/Service";
import EmojiPicker from "emoji-picker-react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import DeleteIcon from "@mui/icons-material/Delete";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import LeaveGroupPopup from "./LeaveGroupPopup";
import io from 'socket.io-client';
import CampaignIcon from '@mui/icons-material/Campaign';
import Announce from "./Announce";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import TextareaAutosize from "react-textarea-autosize";
const host = process.env.REACT_APP_CONECT_URL;
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "background.paper",
    border: "0px solid #000",
    borderRadius: "16px",
    boxShadow: 24,
    p: 6,
};

/**
 * create components is used for chat wihh other user
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 */
const GroupChat = (props) => {
    const userId = decodeToken().userAuth.id;
    let getimage = imageUrl;
    const userDetail = decodeToken()?.userAuth
    const [sendMessage, setSendMessage] = useState("")
    const [userChat, setUserChat] = useState([]);
    const chatContainerRef = useRef(null);
    const [chatdocname, setChatdocname] = useState("");
    const [chatDoc, setChatDoc] = useState("");
    const [openEmoji, setOpenEmoji] = useState(false);
    const [page, setPage] = useState(1);
    const messagesPerPage = 10;
    const [leavePopup, setLeavePopup] = useState(false)
    const [announcement, setAnnouncement] = useState(false)
    const [messageId, setMessageId] = useState("");
    const [image1, setImage1] = useState("");
    const [isVisible, setVisible] = useState(false);
    const UserId = decodeToken()?.userAuth?.id;
    const hiddenFileInput = useRef(null);
    const [groupId, setGroupId] = useState("");
    const [open, setOpen] = React.useState(false);
    const [status, setStatus] = useState("");
    const [socket, setSocket] = useState(null);
    const [value, setValue] = useState(false);
    const [adminId, setAdminId] = useState([]);
    const [lastAdmin, setLastAdmin] = useState("");
    const [allChat, setAllChat] = useState([]);
    const [chatRemaining, setChatRemaining] = useState(1);
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const containsText = /[a-zA-Z]/;
    const containsNumber = /\d/;
    const messagesEndRef = useRef(null)
    const [spin, setSpin] = useState(false)
    const scrollToBottom = () => {
        if (page == 1) {
            if (chatContainerRef.current) {
                chatContainerRef.current.scrollTo(
                    0,
                    chatContainerRef.current.scrollHeight
                );
            }
        }
    };

    useEffect(() => {
        scrollToBottom();
        if (userChat) {
            const groupedMessages = groupMessagesByDay(userChat);
            setAllChat(groupedMessages)
        }
    }, [userChat]);

    const handleScroll = () => {
        const { scrollTop } = chatContainerRef.current;
        if (scrollTop === 0 && chatRemaining === 1) {
            setPage(page + 1);
        }
    };

    async function remove() {
        hiddenFileInput.current.value = null;
        setImage1(null);
        setVisible(false);
    }

    // this function is use for upload image
    const handleImages = (e) => {
        let document = [
            "pdf",
            "png",
            "PNG",
            "jpg",
            "jpeg",
            "gif",
        ];
        const checkfile = e.target.files;
        if (checkfile[0]) {
            const fileSize = e.target.files[0].size / (1024 * 1024);
            if (fileSize > 2) {
                toastifyMessage("File size exceeded, Please upload file up to 2 mb", "error")
            } else if (document.includes(checkfile[0].name.split(".").pop())) {
                setChatDoc(e.target.files[0]);
                let imgname = e.target.files[0].name;
                let imgType = imgname.split(".");
                setChatdocname(e.target.files[0].name);
                setImage1({
                    imgurl: URL.createObjectURL(e.target.files[0]),
                    imgtype: imgType.pop(),
                });
                const formData = new FormData();
                formData.append("File", image1);
                setVisible(true);
                setVisible(true);
            } else {
                toastifyMessage("you can upload only png,jpg,jpeg,doc and pdf", "error")
            }
        }
    };

    // socket connection-----
    useEffect(() => {
        const arr = []
        props?.groupDetail?.group_admin_users?.map((val) => {
            return arr.push(val.user_id)
        })
        setAdminId(arr)
        // const socketConnection = io.connect('http://localhost:7000/');
        const socketConnection = io.connect(host);
        setSocket(socketConnection);
        return () => {
            if (socketConnection) {
                socketConnection.disconnect();
            }
        };

    }, []);
    // join room---
    useEffect(() => {
        if (socket) {
            socket.emit('join', { name: userDetail?.firstName, room: props?.groupDetail?.id }, (error) => {
                if (error) {
                    console.log('Error joining room:', error);
                } else {
                    console.log('Joined room:', props?.groupDetail?.group_name);
                }
            });
        } else {
            console.log();
        }

    }, [socket]);

    // get messages in scoket
    useEffect(() => {
        if (socket) {
            socket.on('message', (message) => {
                console.log("message", message)
                if (!message.chat_doc) {
                    setUserChat((prevUserChat) => [...prevUserChat, message]);
                } else {
                    setTimeout(() => {
                        setSpin(true)
                        GetChatMessage()
                    }, 700)
                }
            });
        }
    }, [socket]);

    // this function is use for message send
    const AddMessage = (e) => {
        e.preventDefault();
        if (image1 != "") {
            setVisible(false);
        }
        setOpenEmoji(false);
        try {
            if (sendMessage.trim() === "" && !chatDoc) {
                alert("You can't send an empty message.");
                return;
            }
            const formData = new FormData();
            const timestamp = Date.now().toString(36);
            const randomChars = Math.random().toString(36).substring(2, 8);
            const commonMessageData = {
                send_by: userDetail?.id,
                group_id: props?.groupDetail?.id,
                message: sendMessage ? sendMessage : "",
                massageUniqueId: timestamp + randomChars,
            };
            const Groupdata = {
                ...commonMessageData,
            };
            const data = {
                ...commonMessageData,
                chat_doc: chatDoc,
                fileExtension: chatDoc ? chatDoc.name.split(".").pop() : "",
                send_by_user_photo: getimage + userDetail?.photo,
                message_date: new Date().toISOString(),
                send_by_name: userDetail?.firstName + " " + userDetail?.lastName
            };

            if (socket && sendMessage || chatDoc) {
                socket.emit('sendMessage', data);
            }
            setChatDoc("");
            setSendMessage("");
            makePostRequestForAll(URLS.addGroupChatMsg, Groupdata)
                .then((res) => {
                    if (res.code === 200 && chatDoc) {
                        formData.append("message_unique_number", res?.data?.message_unique_number);
                        formData.append("chat_doc", chatDoc);
                        makeFormDateRequset(URLS.addGroupChatImage, formData)
                            .then((res) => {
                                setValue(!value)
                            })
                            .catch((error) => {
                                console.error("Error in sending image:", error);
                            });
                    }
                })
                .catch((error) => {
                    console.error("Error in sending message:", error);
                });

        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    // this function is use for get chat message
    const GetChatMessage = () => {
        const data = {
            user_id: UserId,
            group_id: props?.groupDetail?.id,
            page_number: page,
            item_per_page: messagesPerPage
        };
        makePostRequestForAll(URLS.getGroupChatMsg, data).then((res) => {
            console.log("res", res)
            if (page === 1) {
                setSpin(false)
                setUserChat(res?.data?.chatMessages?.reverse());
                setChatRemaining(res?.data?.remaining_count)
            } else {
                setSpin(false)
                setUserChat((prevChat) => [...res?.data?.chatMessages?.reverse(), ...prevChat]);
                setChatRemaining(res?.data?.remaining_count)
            }
        });
    }

    useEffect(() => {
        GetChatMessage()
    }, [page])

    // this function is use for delete chat
    const deleteSingleChat = (id) => {
        if (status === "deleteAllChat") {
            const data = {
                user_id: userId,
                users_map_id: "",
                group_id: props?.groupDetail?.id,
            };
            makePostRequestForAll(URLS.deleteBulkChat, data)
                .then((res) => {
                    setLeavePopup(false)
                    if (res.code == 200) {
                        toastifyMessage("Message has been deleted", "success")
                        GetChatMessage()
                    }
                })
                .catch((error) => {
                    toastifyMessage("something went wrong.", "error")
                });
        } else {
            const data = {
                user_id: userId,
                message_id: id,
                users_map_id: "",
                group_id: props?.groupDetail?.id,
            };
            makePostRequestForAll(URLS.deleteSingleChat, data)
                .then((res) => {
                    toastifyMessage("Message has been deleted", "success");
                    setLeavePopup(false)
                    GetChatMessage();
                })
                .catch((error) => {
                    console.log("error:::", error)
                });
        }
    }


    const scrollToBottomdown = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        scrollToBottomdown()
    }, [userChat]);
    return (
        <div className="col-lg-8 mb-3">
            {spin && (
                <div className="sharepopupOuter backgroundOpacity1">
                    <FourDotsLoader />
                </div>
            )}
            <div className="chat-box">
                <div className="chate-inner-box">
                    <div className="chat-ima-div d-flex gap-4">
                        <div>
                            <img className="img-fluid" src={props?.groupDetail?.image} alt="group image" loading="lazy" />
                            <div className="image-diiv"></div>
                        </div>
                        <h1 className="py-2" style={{ padding: "0 20px 0 0" }}>{props?.groupDetail?.group_name}</h1>
                    </div>
                    <div className="thre-point">
                        {/* <h6>4</h6> */}
                        <span className="three-ellipis "><i className="fa fa-ellipsis-h"></i>
                            <div className="dotspop">
                                <a referrerpolicy="no-referrer" className="border-bottom" onClick={() => { setStatus("deleteAllChat"); setLeavePopup(true); }}>Delete Chat</a>


                                {(adminId.includes(userId) && adminId.length == 1) ?
                                    <>
                                        <a referrerpolicy="no-referrer" onClick={() => { setOpen(true); setGroupId(props?.groupDetail?.id); setLastAdmin("lastAdmin") }}>Leave Group</a>
                                    </>
                                    :
                                    <>
                                        {
                                            adminId.includes(userId) ? <a referrerpolicy="no-referrer" onClick={() => { setOpen(true); setGroupId(props?.groupDetail?.id); setLastAdmin("Admin") }}>Leave Group</a>
                                                : <a referrerpolicy="no-referrer" onClick={() => { setOpen(true); setGroupId(props?.groupDetail?.id); setLastAdmin("User") }}>Leave Group</a>
                                        }
                                    </>
                                }
                            </div>
                        </span>
                    </div>
                </div>
                <div className="chating-person">
                    <div className="chating-all">
                        <ul
                            ref={chatContainerRef}
                            style={{
                                overflowY: "scroll",
                                height: "400px",
                                overflowX: "hidden",
                            }}
                            onScroll={handleScroll}
                        >
                            {
                                Object.keys(allChat)?.map((date) => {
                                    return (
                                        <>
                                            <h1 className="date-separator">{date}</h1>
                                            {allChat &&
                                                allChat[date]?.map((val) => {
                                                    return (
                                                        <>
                                                            {UserId == val?.send_by ? (
                                                                <li className="replies">
                                                                    <img
                                                                        className="img-fluid"
                                                                        src={val?.send_by_user_photo}
                                                                        alt="user-image"
                                                                    />
                                                                    <p>
                                                                        {val.fileExtension === "png" ||
                                                                            val.fileExtension === "jpg" ||
                                                                            val.fileExtension === "jpeg" ||
                                                                            val.fileExtension === "PNG" ? (
                                                                            <>
                                                                                {val.chat_doc ? (
                                                                                    <div className="doc_div_r">
                                                                                        <div className="sender-name">
                                                                                            <span>You</span>
                                                                                        </div>
                                                                                        <img
                                                                                            src={val.chat_doc}
                                                                                            onClick={() =>
                                                                                                handleFileDownload(
                                                                                                    val.chat_doc,
                                                                                                    "MatchEd_doc"
                                                                                                )
                                                                                            }
                                                                                            className="pdf-image"
                                                                                            style={{
                                                                                                width: "100px",
                                                                                                height: "100px",
                                                                                                margin: "0px",
                                                                                            }}
                                                                                        />

                                                                                        <div>
                                                                                            {
                                                                                                !val.message && <span className="message-time">
                                                                                                    {getTime(val?.message_date, "chat")}
                                                                                                </span>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {val.chat_doc && (
                                                                                    <div
                                                                                        className="doc_div_r"
                                                                                        onClick={() =>
                                                                                            handleFileDownload(
                                                                                                val.chat_doc,
                                                                                                "MatchEd_doc"
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <div className="sender-name">
                                                                                            <span>You</span>
                                                                                        </div>
                                                                                        {" "}
                                                                                        {val.fileExtension
                                                                                            ? getFileIcon(val.fileExtension)
                                                                                            : ""}
                                                                                        <div>
                                                                                            {
                                                                                                !val.message && <span className="message-time">
                                                                                                    {getTime(val?.message_date, "chat")}
                                                                                                </span>
                                                                                            }
                                                                                        </div>

                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                        {val.message ? (
                                                                            <>
                                                                                {urlRegex.test(val.message) ? (
                                                                                    <>
                                                                                        {
                                                                                            !val.chat_doc && <div className="sender-name">
                                                                                                <span>You</span>
                                                                                            </div>
                                                                                        }
                                                                                        <span>
                                                                                            <a
                                                                                                href={val.message}
                                                                                                target="_blank"
                                                                                                rel="noopener noreferrer"
                                                                                                className="text-white"
                                                                                            >
                                                                                                {val.message}
                                                                                            </a>
                                                                                        </span>
                                                                                        <span className="message-time-r">
                                                                                            {" "}
                                                                                            {getTime(val?.message_date, "chat")}
                                                                                        </span>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {
                                                                                            !val.chat_doc && <div className="sender-name">
                                                                                                <span>You</span>
                                                                                            </div>
                                                                                        }

                                                                                        {containsText.test(val.message) ||
                                                                                            containsNumber.test(
                                                                                                val.message
                                                                                            ) ? (
                                                                                            <span className="rpliesMessage" dangerouslySetInnerHTML={{ __html: val?.message?.replace(/\n/g, '<br>') }}></span>
                                                                                        ) : (
                                                                                            <span className="rpliesMessage"
                                                                                                style={{ fontSize: "27px" }}
                                                                                                dangerouslySetInnerHTML={{ __html: val?.message?.replace(/\n/g, '<br>') }}
                                                                                            >
                                                                                            </span>
                                                                                        )}
                                                                                        <span className="message-time-r">
                                                                                            {" "}
                                                                                            {getTime(val?.message_date, "chat")}
                                                                                        </span>
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </p>
                                                                    <strong className="delete-massage">
                                                                        <DeleteIcon
                                                                            onClick={() => {
                                                                                setLeavePopup(true);
                                                                                setMessageId(val.massageUniqueId);
                                                                                setStatus("");
                                                                            }}
                                                                        />
                                                                        {
                                                                            adminId.includes(val.send_by) && <CampaignIcon
                                                                                onClick={() => {
                                                                                    setAnnouncement(true);
                                                                                    setMessageId(val.massageUniqueId);
                                                                                }}
                                                                            />
                                                                        }

                                                                    </strong>
                                                                </li>
                                                            ) : (
                                                                <li className="sent">
                                                                    <img
                                                                        className="img-fluid "
                                                                        src={val?.send_by_user_photo}
                                                                        alt="user-image"
                                                                    />
                                                                    <p>
                                                                        {val.fileExtension === "png" ||
                                                                            val.fileExtension === "jpg" ||
                                                                            val.fileExtension === "jpeg" ||
                                                                            val.fileExtension === "PNG" ? (
                                                                            <>
                                                                                {val.chat_doc ? (
                                                                                    <div className="doc_div">
                                                                                        <div className="sender-name">
                                                                                            <span>{val.send_by_name}</span>
                                                                                        </div>
                                                                                        <img
                                                                                            src={val.chat_doc}
                                                                                            onClick={() =>
                                                                                                handleFileDownload(
                                                                                                    val.chat_doc,
                                                                                                    "MatchEd_doc"
                                                                                                )
                                                                                            }
                                                                                            className="pdf-image"
                                                                                            style={{
                                                                                                width: "100px",
                                                                                                height: "100px",
                                                                                            }}
                                                                                        />
                                                                                        <div>
                                                                                            {
                                                                                                !val.message && <span className="message-time">
                                                                                                    {getTime(val?.message_date, "chat")}
                                                                                                </span>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {val.chat_doc && (
                                                                                    <div
                                                                                        className="doc_div"
                                                                                        onClick={() =>
                                                                                            handleFileDownload(
                                                                                                val.chat_doc,
                                                                                                "MatchEd_doc"
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <div className="sender-name">
                                                                                            <span>{val.send_by_name}</span>
                                                                                        </div>
                                                                                        <div style={{ fontSize: "30px" }}>
                                                                                            {val.fileExtension
                                                                                                ? getFileIcon(val.fileExtension)
                                                                                                : ""}
                                                                                        </div>
                                                                                        <div>
                                                                                            {
                                                                                                !val.message && <span className="message-time">
                                                                                                    {getTime(val?.message_date, "chat")}
                                                                                                </span>
                                                                                            }

                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                        {val.message ? (
                                                                            <>
                                                                                {urlRegex.test(val.message) ? (
                                                                                    <>
                                                                                        {
                                                                                            !val.chat_doc && <div className="sender-name">
                                                                                                <span>{val.send_by_name}</span>
                                                                                            </div>
                                                                                        }
                                                                                        <span>
                                                                                            <a
                                                                                                href={val.message}
                                                                                                target="_blank"
                                                                                                rel="noopener noreferrer"
                                                                                            >
                                                                                                {val.message}
                                                                                            </a>
                                                                                        </span>
                                                                                        <span className="message-time">
                                                                                            {" "}
                                                                                            {getTime(val?.message_date, "chat")}
                                                                                        </span>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {
                                                                                            !val.chat_doc && <div className="sender-name">
                                                                                                <span>{val.send_by_name}</span>
                                                                                            </div>
                                                                                        }
                                                                                        {containsText.test(
                                                                                            val?.message
                                                                                        ) ||
                                                                                            containsNumber.test(
                                                                                                val?.message
                                                                                            ) ? (
                                                                                            <span dangerouslySetInnerHTML={{ __html: val?.message?.replace(/\n/g, '<br>') }}></span>
                                                                                        ) : (
                                                                                            <span
                                                                                                style={{ fontSize: "23px" }}
                                                                                                dangerouslySetInnerHTML={{ __html: val?.message?.replace(/\n/g, '<br>') }}
                                                                                            ></span>
                                                                                        )}
                                                                                        <span className="message-time">
                                                                                            {" "}
                                                                                            {getTime(val?.message_date, "chat")}
                                                                                        </span>
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </p>
                                                                    <div className="delete-massage-outer">
                                                                        {
                                                                            props?.adminId?.includes(userId) &&
                                                                            <strong className="delete-massage">
                                                                                <DeleteIcon
                                                                                    onClick={() => {
                                                                                        setLeavePopup(true);
                                                                                        setMessageId(val.massageUniqueId);
                                                                                        setStatus("");
                                                                                    }}
                                                                                />

                                                                            </strong>
                                                                        }
                                                                    </div>
                                                                </li>
                                                            )}
                                                        </>
                                                    );
                                                })}
                                        </>
                                    )
                                })
                            }
                            <div ref={messagesEndRef} style={{ marginBottom: "50px" }}></div>
                        </ul>
                    </div>
                </div>
                <form onSubmit={AddMessage}>
                    {isVisible && (
                        <div className="chat-messages-upload">
                            <div>
                                {image1 &&
                                    (image1.imgtype === "png" ||
                                        image1.imgtype === "jpg" ||
                                        image1.imgtype === "jpeg" ||
                                        image1.imgtype === "jpeg") ? (
                                    <img
                                        src={image1.imgurl}
                                        width={100}
                                        heigth={200}
                                    />
                                ) : (
                                    <p>
                                        <PictureAsPdfOutlinedIcon /> {chatdocname}
                                    </p>
                                )}
                            </div>
                            <button className="cros_btn" onClick={remove}>
                                X
                            </button>
                        </div>
                    )}
                    <div className="chat-message-input">
                        {((props?.groupDetail?.group_chat_permission == "1"
                            && props?.groupDetail?.no_of_user <= parseInt(props?.groupDetail?.MAX_GROUP_USER_COUNT)) || props?.adminId?.includes(userId)) || (props?.groupDetail?.group_chat_permission == "0" && props?.adminId?.includes(userId)) ? (
                            <>
                                <div className="chat-message-input-pr">
                                    <TextareaAutosize
                                        className="form-control textareaautosize"
                                        type="text"
                                        placeholder="Write Something..."
                                        onChange={(e) => setSendMessage(e.target.value)}
                                        value={sendMessage}
                                        onKeyPress={(e) => {
                                            if (e && e.key === "Enter" && !e.shiftKey) {
                                                AddMessage(e);
                                            }
                                        }}
                                    />
                                    <div className="chat-button-area areabtnauto">
                                        <div className="attech">
                                            <i
                                                className="fa fa-paperclip attech-icon"
                                                aria-hidden="true"
                                                style={{ cursor: "pointer" }}
                                            ></i>
                                            <input
                                                type="file"
                                                onChange={handleImages}
                                                ref={hiddenFileInput}
                                            />
                                        </div>

                                        {/* <i className="fa fa-camera" aria-hidden="true"></i> */}
                                        <i
                                            className="fa fa-smile-o"
                                            aria-hidden="true"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => setOpenEmoji(!openEmoji)}
                                        ></i>
                                    </div>
                                </div>
                                <div className="chat-messages-input-pr chatmessageautotext" >
                                    <button type="submit">
                                        <i className="fa fa-paper-plane" aria-hidden="true" onClick={remove}></i>
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>
                                <p className="text-center my-3">
                                    {/* Only <b>community admins </b>can send messages */}
                                    Only the <b>Group Administrator(s)</b> can send messages to this Group.  Please send a Chat message to the Group Administrator with questions.  Thank you!
                                </p>
                            </>
                        )}
                    </div>
                </form>
            </div >
            {openEmoji && (
                <div className="chatManuiocns">
                    <EmojiPicker
                        onEmojiClick={(e) => setSendMessage(sendMessage + e.emoji)}
                    />
                </div>
            )}
            <Modal
                open={leavePopup}
                onClose={() => setLeavePopup(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        className="text-center"
                        style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
                    >
                        Are you sure you want to delete this ?
                    </Typography>
                    <Typography
                        id="modal-modal-description"
                        sx={{ mt: 3 }}
                        className="d-flex justify-content-center gap-4"
                    >
                        <button
                            className="child-cancel"
                            onClick={() => deleteSingleChat(messageId)}
                        >
                            Yes
                        </button>
                        <button
                            className="child-delete"
                            onClick={() => setLeavePopup(false)}
                        >
                            No
                        </button>

                    </Typography>
                </Box>
            </Modal>
            {
                open && (
                    <LeaveGroupPopup
                        open={open}
                        handleClose={() => setOpen(false)}
                        value={groupId}
                        lastAdmin={lastAdmin}
                    />
                )
            }
            {
                announcement && <Announce messageId={messageId} handleClose={() => setAnnouncement(false)} announcement={announcement} type="yes" />
            }
        </div>
    );
};

export default GroupChat;
