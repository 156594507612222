import './child_profile_page.css';
import { useNavigate, useParams } from "react-router-dom";
// import Header1 from "../Header1";
import {  imageUrl } from "../../services/api";
import { URLS } from "../../constants";
import Cardcomponent from "./Cardcomponent";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import { decodeToken } from "../../utilities/CustomFunctions";
import { makePostRequest, makeGetRequest } from "../../services/api";
import { Multiselect } from "multiselect-react-dropdown";
import { Link } from 'react-router-dom';
import FourDotsLoader from '../Common.WebAppMate/FourDoted';
import { CategoryOptions } from '../SavedResources.WebAppMate/SaveResourceList';
import { statusData, viewResult } from './Child_profile_page';
import Head from '../Layout.WebAppMate/head/Head';
// /*
//      * Childprofileupdateview1 function is used to request to shared child
//      * to share the resources
//      * @author Naresh Kumar <naresh.gulati@webappmate.com>
//      * created on: 10-5-2023
//      * /
function Childprofileupdateview1() {
  const navigate = useNavigate();
  const parmas = useParams();
  const [resourcelength, setresourcelength] = useState();
  const [id, setId] = useState("");
  let userData = decodeToken();
  let useractive = localStorage.getItem("useractive")
  let uimage = imageUrl;
  const [spin, setSpin] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [childImage, setChildImage] = useState("")
  const [resourceData, setresourcesData] = useState([]);
  const [limit, setlimit] = useState(9)
  const [gradeLevelData, setGradeLevelData] = useState([]);
  const [category, setcategory] = useState("");
  const [status, setstatus] = useState("");
  const [keyword, setkeyword] = useState("");
  const [gradeRange, setGradeRange] = useState([]);
  const [pageshow, setpageshow] = useState(true);
  const [pageNum, setpageNum] = useState(1);
  const [remaining, setRemaining] = useState(true)
  const getGradeLevelData = () => {
    makeGetRequest(URLS.usergetallgradelevel, null, null, null)
      .then((res) => {
        if (res.code === 200) {
          setGradeLevelData(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (useractive === "") {
      navigate("/sign-in")
    }
    document.body.classList.add('childProfileUpdateView')
    return () => {
      document.body.classList.remove('childProfileUpdateView')
    }
  }, [])

  const getchildprofile = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    let data = {
      childId: id,
    };
    makePostRequest(URLS.getonechild, null, data, null)
      .then((response) => {
        console.log(response)
        setFirstName(response.data.firstName)
        setLastName(response.data.lastName)
        setChildImage(uimage + response.data.photo)
      })
  }
  const handleGrade = (selectedList) => {
    const arr = selectedList?.map((val) => val.id) || [];
    setGradeRange(arr);
  };
 const getresources1 = () => {
    setSpin(true)
    let data = {
      "user_id": userData.userAuth.id,
      "child_id": id,
      "storing_type": category,
      "grade": gradeRange,
      "status": status,
      "searchKey": keyword,
      "page_number": pageNum,
      "item_per_page": parseInt(limit)
    }
    makePostRequest(URLS.getchildresource, null, data, null)
      .then((response) => {
        const save = [];
        const dump = [];
        response?.data?.save?.map((val) => {
          return save.push(val.resource_id)
        })
        var arr = [];
        if (pageNum == 1) {
          arr = [...response?.data?.resourceData || []]
        } else {
          arr = [...resourceData, ...response?.data?.resourceData || []]
        }
        for (var i = 0; i < arr.length; i++) {
          if (save.includes(arr[i].resource_id)) {
            const updatedObject = {
              ...arr[i],
              save: "yes"
            };
            dump.push(updatedObject)
          } else {
            const updatedObject = {
              ...arr[i],
              save: "no"
            };
            dump.push(updatedObject)
          }
        }
        if (response.data.view_profile === false) {
          setpageshow(false)
        } else {
          getchildprofile()
          setpageshow(true)
        }
        setresourcesData(dump);
        setresourcelength(response?.data?.resourceData?.length);
        if (response?.data?.childResourcePercentage?.remaining_count == 0) {
          setRemaining(false);
        }else {
          setRemaining(true);
        }
        setSpin(false)
      })
  }

  const getresources = () => {
    setSpin(true)
    let data = {
      "user_id": userData.userAuth.id,
      "child_id": id,
      "storing_type": category,
      "grade": gradeRange,
      "status": status,
      "searchKey": keyword,
      "page_number": 1,
      "item_per_page": parseInt(limit)
    }
    makePostRequest(URLS.getchildresource, null, data, null)
      .then((response) => {
        if (response.data.view_profile === false) {
          setpageshow(false)
        } else {
          getchildprofile()
          setpageshow(true)
        }
        const save = [];
        const dump = [];
        response?.data?.save?.map((val) => {
          return save.push(val.resource_id)
        })
         for (var i = 0; i < response?.data?.resourceData?.length; i++) {
          if (save.includes(response?.data?.resourceData[i]?.resource_id)) {
            const updatedObject = {
              ...response?.data?.resourceData[i],
              save: "yes"
            };
            dump.push(updatedObject)
          } else {
            const updatedObject = {
              ...response?.data?.resourceData[i],
              save: "no"
            };
            dump.push(updatedObject)
          }
        }
        setresourcesData(dump);
        setresourcelength(response?.data?.resourceData?.length);
        if (response?.data?.childResourcePercentage?.remaining_count === 0) {
          setRemaining(false);
        }else {
          setRemaining(true);
        }
        setSpin(false)
      })
  }
// useEffects calls
  useEffect(() => {
    setId(parmas.id);
  }, [parmas]);

  useEffect(() => {
    useractive === "" && navigate("/sign-in");
    id && getresources1();
}, [id, pageNum]);

  useEffect(() => {
    getGradeLevelData();
  }, []);

useEffect(() => {
    if (id) {
      getresources1()
    }
  }, [limit])

  return (<>
  <Head title="Update Student Profile"
        content="MatchED Home: Empowering Parents and Educators with a Faith-Embracing Matching Platform. Harnessing Cutting-Edge Technology to Simplify Education Choices and Create Meaningful Connections."
        name="Update Student Profile" ></Head>
    {spin &&
      <div className="sharepopupOuter backgroundOpacity1">
        <FourDotsLoader />
      </div>
    }
    {pageshow ?
      <div className="col-lg-10">
        <div className="image-div-center">
          <img src={childImage} alt="childImage" />
          <h3 className='text-capitalize'>{firstName}{' '}{lastName}</h3>
        </div>
         <div className="searchres-div11">
          <div className="search-exp-sec-div1">
            <h3>Refine search results</h3>
            <div className="saveResourceListgradeRange">
              <div className="form-select">
                <Multiselect
                  options={CategoryOptions}
                  onSelect={(e) => {
                    e?.forEach((val, i) => {
                      setcategory(val.value);
                    });
                  }}
                  displayValue="name"
                  placeholder="Category"
                  showArrow
                  showCheckbox
                  selectedValues={category ? [CategoryOptions.find(item => item.value === category)] : []}
                /></div>
            </div>
            <div className="childprofilegradeRange">
              <div className="form-select">
                <Multiselect
                  options={gradeLevelData}
                  onSelect={handleGrade}
                  onRemove={handleGrade}
                  showCheckbox
                  displayValue="name"
                  placeholder="Grade Range"
                  showArrow={true}
                />
              </div> </div>
            <div className="saveResourceListgradeRange">
              <div className="form-select">
                <Multiselect
                  options={statusData}
                  onSelect={(e) => {
                    e?.map((val, i) =>setstatus(val.value));
                  }}
                  displayValue="name"
                  placeholder="Status"
                  showArrow={true}
                  showCheckbox
                  selectedValues={status ? [statusData.find(item => item.value === status)] : []}
                /></div></div>
            <div className="input-btn-con">
              <input type="text" placeholder="Keyword Search" onChange={(e) => setkeyword(e.target.value)} />
             </div>
            <button onClick={getresources}><i className="fa fa-search iconsearch" /></button>
          </div>
        </div>
        <div className="saveResourceListgradeRange">
          <div className="form-select">
            <Multiselect
              options={viewResult}
              onSelect={(e) => {
                e?.map((val, i) =>setlimit(val.value));
              }}
              displayValue="name"
              showArrow={true}
              showCheckbox
              selectedValues={limit ? [viewResult.find(item => item.value == limit)] : []}
            /></div></div>
        <div className="card-container-handle profileCardContainer">
          <div className="row">
            {resourceData.length > 0 ? resourceData.map((temp) =>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <Cardcomponent
                  res_saved_id={temp.id}
                  resource_id={temp.tbl_user_resource.id}
                  res_title={temp.tbl_user_resource.resource_title}
                  res_desc={temp.tbl_user_resource.description}
                  res_fund={temp.tbl_user_resource.public_funding}
                  res_type={temp.tbl_user_resource.resource_type}
                  res_piller={temp.tbl_user_resource.tbl_pillar_resource_mappings}
                  res_rating={temp.tbl_user_resource.rating}
                  res_review={temp.tbl_user_resource.total_reviews}
                  res_viewable={temp.viewable_by_my_friends}
                  res_len={resourcelength}
                  childId={id} getresources={getresources1}
                  removeflag="false"
                  shared_by={temp.shared_by_user}
                  save={temp.save}
                  resource_thumbnail_image={temp.tbl_user_resource.resource_thumbnail_image}
                  imageLink={temp.tbl_user_resource.imageLink}
                />
               </div>
            )
              : <div className='text-center'>No Data Found</div>}

          </div>
        </div>
        <div className="show-more my-3" onclick={window.scrollTo({ bottom: 0, behavior: 'smooth' })}>
          {remaining && <p className="text-center" onClick={() => setpageNum(pageNum + 1)}>Show more</p>}
        </div>
      </div>
      : <div className="col-lg-10 text-center">
        <Link to="/search-page">
          <button className="child-cancel1">You do not have premission to view this profile
            <small>Click to continue...</small>
          </button>
        </Link>
      </div>}
  </>)
}
export default Childprofileupdateview1;